function showTopLearnersAllTime() {
    var courseId = null;
    if ($("#courseid").length > 0) {
        courseId = $("#courseid").val();
    }
    var url = $('#pagehash').val();
    loadUrlToId(url, 'mode=top10all&module=learnpoints-toplist&courseId=' + courseId, '#learnpoints-left');
}

function showTopLearnersThisMonth() {
    var courseId = null;
    if ($("#courseid").length > 0) {
        courseId = $("#courseid").val();
    }
    var url = $('#pagehash').val();
    loadUrlToId(url, 'mode=top10month&module=learnpoints-toplist&courseId=' + courseId, '#learnpoints-left');
}

function showTopLearnersFriendsAllTime() {
    var url = $('#pagehash').val();
    loadUrlToId(url, 'mode=top10friendsall&module=learnpoints-toplist', '#learnpoints-right');
}

function showTopLearnersFriendsThisMonth() {
    var url = $('#pagehash').val();
    loadUrlToId(url, 'mode=top10friendsmonth&module=learnpoints-toplist', '#learnpoints-right');
}

