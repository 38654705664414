/* PRIVATE MESSAGE MODAL */

// open private message modal button

function openPrivateMessageModal() {
    $("#private-message-modal").modal();

    // create nicescroll for modals only when they are opened or the scrollbar will be misplaced (because modal dimensions etc. are not set yet!?)
    setTimeout(function () {
        if (window.jQuery && $.fn.niceScroll !== undefined && $("#private-message-modal .user-column").length > 0) {
            $("#private-message-modal .user-column").niceScroll(niceScrollConfig);
        }
    }, 0);

    // $("#private-message-modal .user-column").getNiceScroll().show();
    // $("#private-message-modal .user-column").getNiceScroll().resize();
    setTimeout(function () {
        $("#private-message-modal").find("textarea").focus();
    }, 0);
}

function loadThreads(clickFirstThread) {
    $.ajax({
        type: "POST",
        url: "messages",
        data: "module=messages_thread_list&getThreads=1",
        success: function (data) {
            $("#private-message-modal .js-user-list").html(data);
            if (clickFirstThread) {
                if ($(".js-messaged-user").length > 0) {
                    $(".js-messaged-user").first().click();
//					$(".js-send-private-message-btn").show();
//					$(".js-private-message-text").show();
                } else {
                    // $("#private-message-modal .message-column #mailToUserName").html("?");
                    $("#message-modal-title").html(i18n.gettext("Private messages"));
                }
            } else {
//				$(".js-send-private-message-btn").show();
//				$(".js-private-message-text").show();
            }
        }
    });
}

function privateMessageModal(el, e) {
    // check if link inside usermenu was clicked. If it was, call close usermenu function here and stop click bubbling, so pageScroll doesn't get reenabled!
    if (el != null && $(el).closest(".user-menu").length) {
        e.stopPropagation();
        closeUserMenu();
    }

    // refresh list of threads
    var clickFirstThread;
    var userName;
    if ($(".profile-user-container .username").length > 0) {
        userName = $(".profile-user-container .username").html();
        // $("#private-message-modal .message-column #mailToUserName").html(userName);
        $("#message-modal-title").html(i18n.gettext('Message to') + ' ' + userName);

        clickFirstThread = false;
    } else {
        // $("#private-message-modal .message-column #mailToUserName").html("?");
        $("#message-modal-title").html(i18n.gettext("Private messages"));
        $(".js-send-private-message-btn").hide();
        $(".js-private-message-text").hide();
        clickFirstThread = true;
    }
    if ($('#userId').length) {
        $('#messageToUserId').val($('#userId').val());
    }

    if (!mqMinWidth768.matches) {
        clickFirstThread = false;
    }

    loadThreads(clickFirstThread);

    var requestAddon = "";

    if ($(".profile-user-container #userId").length > 0) {
        otherUserId = $(".profile-user-container #userId").val();
        requestAddon = "&getMessagesWithUser=" + otherUserId;
        showOrHideSendMessageButton(otherUserId);
    }

    $.ajax({
        type: "POST",
        url: "messages",
        data: "module=messages" + requestAddon,
        success: function (data) {
            $("#private-message-modal .message-column .messages-container").html(data);
        }
    });


    // set name of user to write message to.
    // if no profile or own profile in focus, select latest message thread.
    // if not thread yet, show blank. (?)

    openPrivateMessageModal();
}


function backToUserList() {
    $("#private-message-modal .message-column").hide();
    $("#private-message-modal .user-column").show();
}


function messagedUserClick(el) {
    if (!mqMinWidth768.matches) {
        $("#private-message-modal .user-column").hide();
    }
    $("#private-message-modal .message-column").show();
    var userId = $(el).data('userid');
    $('#messageToUserId').val(userId);
    var userName = $(el).find(".username").html();
    $("#private-message-modal .message-column #mailToUserName").html(userName);
    showOrHideSendMessageButton(userId);
    $.ajax({
        type: "POST",
        url: "messages",
        data: "module=messages" + "&getMessagesWithUser=" + userId,
        success: function (data) {
            $("#private-message-modal .message-column .messages-container").html(data);
            $("#message-modal-title").html(i18n.gettext("Message to") + " " + userName);
            $("#deleteMessagesWithUserButton").data('otheruserid', userId);
            // $(".js-send-private-message-btn").show();
//            $(".js-private-message-text").show();
        }
    });
}


function showOrHideSendMessageButton(userId) {
    $.ajax({
        type: "POST",
        url: "profile",
        data: "isBuddy=" + userId,
        success: function (data) {
            if (data != 1) {
                $(".js-send-private-message-btn").hide();
                $(".js-private-message-text").hide();
                $("#messagesHint").html(i18n.gettext("You cannot send messages to this user. You have to become friends here first."));
            } else {
                $(".js-send-private-message-btn").show();
                $(".js-private-message-text").show();
                $("#messagesHint").html('');
            }
        }
    });
}


function sendPrivateMessage() {
    var userId = $('#messageToUserId').val();
    var message = encodeURIComponent($('.js-private-message-text').val());

    debug('Sending a private message.');
    flashModal(i18n.gettext('Sending message...'));
    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "notifications",
        data: "sendMessage=1&message=" + message + "&to=" + userId + "&nonce=" + nonce,
        success: function (data) {
            $('#private-message-modal').find("textarea").val('').css('height', 'auto');
            flashModal(data);
        }
    });
}


$(document).ready(function () {
    $('#private-message-modal').on($.modal.CLOSE, function (event, modal) {
        if ($("#private-message-modal .user-column").css('display') == 'none') {
            $("#private-message-modal .user-column").css('display', 'block');
            $("#private-message-modal .message-column").css('display', 'none');
        }
    });
});


function deleteMessagesWithUser(elem) {
    var otherUserId = $(elem).data('otheruserid');
    var r = confirm(i18n.gettext("Really delete ALL messages with this user?"));
    if (r === true) {
        $.ajax({
            type: "POST",
            url: "messages",
            data: "module=messages&deleteMessagesWithUser=" + otherUserId,
            success: function (data) {
                shortInfo(data);
                $.modal.close();
                privateMessageModal();
            }
        });
    }
}

function deleteMessage(elem) {
    var messageId = $(elem).data('messageid');
    var otherUserId = $(elem).data('otheruserid');
    var otherUserName = $(elem).data('otherusername');
    var r = confirm(i18n.gettext("Really delete this message?"));
    if (r === true) {
        $.ajax({
            type: "POST",
            url: "messages",
            data: "module=messages&deleteMessage=" + messageId,
            success: function (data) {
                shortInfo(data);
                $.ajax({
                    type: "POST",
                    url: "messages",
                    data: "module=messages" + "&getMessagesWithUser=" + otherUserId,
                    success: function (data) {
                        $("#private-message-modal .message-column .messages-container").html(data);
                        $("#message-modal-title").html(i18n.gettext("Message to") + " " + otherUserName);
                        $("#deleteMessagesWithUserButton").data('otheruserid', otherUserId);
                    }
                });
            }
        });
    }
}