var colorNew;
var colorWrong;
var colorOne;
var colorTwo;
var colorThree;
var colorFour;
var colorFive;

setColors();


/*
 * // create a line chart for weeks function
 * createWeeksChart(canvasObj) { var ctx =
 * canvasObj.get(0).getContext("2d"); var weeks = new Array(52);
 *
 * var data = { labels : [ // MAKE DYNAMIC!?
 * months[canvasObj.data('months')[0]-1],
 * months[canvasObj.data('months')[1]-1],
 * months[canvasObj.data('months')[2]-1],
 * months[canvasObj.data('months')[3]-1],
 * months[canvasObj.data('months')[4]-1],
 * months[canvasObj.data('months')[5]-1],
 * months[canvasObj.data('months')[6]-1],
 * months[canvasObj.data('months')[7]-1],
 * months[canvasObj.data('months')[8]-1],
 * months[canvasObj.data('months')[9]-1],
 * months[canvasObj.data('months')[10]-1],
 * months[canvasObj.data('months')[11]-1], ], datasets : [ {
 * fillColor : "#9CD5C7", strokeColor : "#9CD5C7", pointColor :
 * "#9CD5C7", pointStrokeColor : "#ddd",
 *
 * data : [65,59,90,81,105,91,77,80,62,96,83,75] }, { fillColor :
 * "#EBA3A3", strokeColor : "#EBA3A3", pointColor : "#EBA3A3",
 * pointStrokeColor : "ddd", data :
 * [28,25,40,30,39,27,20,24,15,32,23,20] } ] }
 *
 * new Chart(ctx).Line(data,{ scaleFontFamily: "'Arial',sans-serif",
 * scaleShowGridLines: false, scaleGridLineColor: "#ddd",
 * pointDotStrokeWidth: 0, datasetStroke: false, datasetStrokeWidth:
 * 0, animation: true, animationEasing: "easeOutQuart",
 * animationSteps: "100" });
 *  }
 */



/* MONTHS BAR CHART BACKUP */
// create a line chart for months
// function createMonthsChart(canvasObj) {
// var ctx = canvasObj.get(0).getContext("2d");
// var months =
// ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"];
// var data = {
// labels : [
// months[canvasObj.data('months')[0]-1],
// months[canvasObj.data('months')[1]-1],
// months[canvasObj.data('months')[2]-1],
// months[canvasObj.data('months')[3]-1],
// months[canvasObj.data('months')[4]-1],
// months[canvasObj.data('months')[5]-1],
// months[canvasObj.data('months')[6]-1],
// months[canvasObj.data('months')[7]-1],
// months[canvasObj.data('months')[8]-1],
// months[canvasObj.data('months')[9]-1],
// months[canvasObj.data('months')[10]-1],
// months[canvasObj.data('months')[11]-1],
// ],
// datasets : [
// {
// fillColor : colorFive,
// data : [65,59,90,81,105,91,77,80,62,96,83,75]
// },
// {
// fillColor : colorWrong,
// data : [28,25,40,30,39,27,20,24,15,32,23,20]
// }
// ]
// }
// new Chart(ctx).Bar(data,{
// scaleFontFamily: "'Arial',sans-serif",
// scaleShowGridLines: false,
// scaleGridLineColor: "#ddd",
// barShowStroke: false,
// barStrokeWidth: 0,
// animation: true,
// animationEasing: "easeOutQuart",
// animationSteps: "100",
// barValueSpacing: 20,
// });
// }



// CARDS STATUS DEVELOPMENT CHART BUTTONS
function cardsStatusDevelopmentChartTimeframeBtnClick(el) {
    var boxId = $("#boxId").val();
    $('#cards-status-development').fadeTo(50, 0.7);
    var startFrom = $(el).data("startfrom");
    var show = $("#show").val();
    $.ajax({
        type: "POST",
        url: "statistik",
        data: "selectedBox=" + boxId + "&module=cards-status-development&startFrom=" + startFrom + "&show=" + show,
        success: function (data) {
            $('#cards-status-development').html(data);
            $('#cards-status-development').fadeTo(50, 1.0);
            createCardsStatusDevelopmentChart($("#cards-status-development-chart").find("canvas"));
        }
    });
}


function cardsetRankingTimeframeBtnClick(month, year) {
    $("#monthly-top-boxes").fadeTo(50, 0.7);
    var show = $("#show").val();
    $.ajax({
        type: "POST",
        url: "statistik",
        data: "module=monthly-top-boxes&year=" + year + "&month=" + month + "&show=" + show,
        success: function (data) {
            $("#monthly-top-boxes").html(data);
            $("#monthly-top-boxes").fadeTo(50, 1.0);
        }
    });
}

// EFFORT CHART BUTTONS
function effortChartTimeframeBtnClick(el) {
    var boxId = $("#boxId").val();
    var show = $("#show").val();

    $('#effort-chart').fadeTo(50, 0.7);
    var url = "statistics";

    var timeframe;
    if ($(el).is("[data-timeframe]")) {
        timeframe = $(el).data("timeframe");
    } else {
        timeframe = $(".learn-effort-container .timeframe-btns .active").data("timeframe");
    }

    var startfrom = "";
    if ($(el).is("[data-startfrom]")) {
        startfrom = $(el).data("startfrom");
        debug("Element has attribute data-startfrom and its value is: " + startfrom);
    } else {
        debug("Element does not have the attribute data-startfrom.;")
    }

    $.ajax({
        type: "POST",
        url: url,
        data: "selectedBox=" + boxId + "&module=effort-chart&mode=" + timeframe + "&startFrom=" + startfrom + "&show=" + show,
        success: function (data) {
            $('#effort-chart').html(data);
            $('#effort-chart').fadeTo(50, 1.0);
            $("#startFromLowerBtn").data("startfrom", $('#effort-chart #startFromLower').val());
            $("#startFromUpperBtn").data("startfrom", $('#effort-chart #startFromUpper').val());
            var today = $('#effort-chart #today').val();
            if ($('#effort-chart #startFromUpper').val() > today || $('#effort-chart #startFromUpper').val() == "") {
                $("#startFromUpperBtn").hide();
            } else {
                $("#startFromUpperBtn").show();
            }
            createEffortChart($("#effort-chart").find("canvas"));
        }
    });
}



// KNOWN CARDS CHART BUTTONS
function knownCardsChartTimeframeBtnClick(el) {
    var boxId = $("#boxId").val();
    var show = $("#show").val();
    var startFrom = $(el).data("startfrom");
    $('#known-cards-chart').fadeTo(50, 0.7);
    $.ajax({
        type: "POST",
        url: "statistik",
        data: "selectedBox=" + boxId + "&module=known-cards-chart&startFrom=" + startFrom + "&show=" + show,
        success: function (data) {
            $('#known-cards-chart').html(data);
            $('#known-cards-chart').fadeTo(50, 1.0);
            createKnownCardsChart($("#known-cards-chart").find("canvas"));
        }
    });
}



// CARD SET SELECT BOX CHANGE
function statisticChangeCardset(el) {
    var selectedBox = $(el).val();
    $('#statistik-content-container').fadeTo(400, 0.3);
    var url = getUrlHash();
    $.ajax({
        type: "POST",
        url: url,
        data: "selectedBox=" + selectedBox + "&module=statistik_content",
        success: function (data) {
            $('#statistik-content-container').html(data);
            $('#statistik-content-container').fadeTo(400, 1.0);
            redrawStatisticGraphs();
            $(".bar-chart .bar").each(function () {
                $(this).animate({width: $(this).data('width') + '%'}, 1700, 'easeOutQuad');
            });
        }
    });
}



function fadeInDonutText(canvasObj) {
    canvasObj.parent().find(".overall-progress").fadeIn();
    canvasObj.parent().find(".overall-progress-subline").fadeIn();
}



// create a donut chart: pass in canvas jquery object and seven values (cards
// amounts)
function createDonutChart(canvasObj) {

//    try {

    debug(canvasObj);

    setColors();

    var ctx = canvasObj.get(0).getContext("2d");

    if (canvasObj.hasClass("big")) {
        var chartAnim = true;
        var innerCutout = 73;
    } else {
        var chartAnim = false;
        var innerCutout = 68;
    }

    debug('Donut-Daten: ' + canvasObj.data('values'));

    // chart data
    var data = [{
            value: canvasObj.data('values')[6],
            color: colorFive
        }, {
            value: canvasObj.data('values')[5],
            color: colorFour
        }, {
            value: canvasObj.data('values')[4],
            color: colorThree
        }, {
            value: canvasObj.data('values')[3],
            color: colorTwo
        }, {
            value: canvasObj.data('values')[2],
            color: colorOne
        }, {
            value: canvasObj.data('values')[1],
            color: colorWrong
        }, {
            value: canvasObj.data('values')[0],
            color: colorNew
        }

    ];

    // create chart
    new Chart(ctx).Doughnut(data, {
        segmentShowStroke: false,
        animation: chartAnim,
        animationEasing: "easeOutQuart",
        animationSteps: "10",
        percentageInnerCutout: innerCutout,
        onAnimationComplete: function () {
            fadeInDonutText(canvasObj);
        },
        showTooltips: false
    });

    // rotate canvas so start is at the bottom
    var chartCenterX = canvasObj.outerWidth() / 2;
    var chartCenterY = canvasObj.outerHeight() / 2;
    ctx.translate(chartCenterX, chartCenterY);
    ctx.rotate(180 * Math.PI / 180);
    ctx.translate(-chartCenterX, -chartCenterY);

//    } catch (e) {
//        
//        debug("Error while creating charts.");
//        debug(e.message);
//        
//    }

} // end fn createDonutChart

//create a line chart for months
function createMonthsChart(canvasObj) {
    var ctx = canvasObj.get(0).getContext("2d");
    var months = [i18n.gettext("January"), i18n.gettext("February"), i18n.gettext("March"), i18n.gettext("April"), i18n.gettext("May"),
        i18n.gettext("June"), i18n.gettext("July"), i18n.gettext("August"), i18n.gettext("September"), i18n.gettext("October"),
        i18n.gettext("November"), i18n.gettext("December")];
    var colorTurquoise100 = "hsla(165,67%,42%,1)";
    var colorTurquoise25 = "hsla(165,67%,42%,0.25)";
    var colorRed100 = "hsla(0,67%,57%,1)";
    var colorRed25 = "hsla(0,67%,57%,0.25)";
    var correct = canvasObj.data('correct');
    var partially = canvasObj.data('partially');
    var wrong = canvasObj.data('wrong');

    var data = {
        labels: [months[canvasObj.data('months')[0] - 1],
            months[canvasObj.data('months')[1] - 1],
            months[canvasObj.data('months')[2] - 1],
            months[canvasObj.data('months')[3] - 1],
            months[canvasObj.data('months')[4] - 1],
            months[canvasObj.data('months')[5] - 1],
            months[canvasObj.data('months')[6] - 1],
            months[canvasObj.data('months')[7] - 1],
            months[canvasObj.data('months')[8] - 1],
            months[canvasObj.data('months')[9] - 1],
            months[canvasObj.data('months')[10] - 1],
            months[canvasObj.data('months')[11] - 1], ],
        datasets: [
            {
                fillColor: colorTurquoise25, // "#9CD5C7",
                strokeColor: colorTurquoise100, // "#9CD5C7",
                pointColor: colorTurquoise100, // "#9CD5C7",
                pointStrokeColor: colorTurquoise100, // "#ddd",

                data: [correct[0], correct[1], correct[2],
                    correct[3], correct[4], correct[5],
                    correct[6], correct[7], correct[8],
                    correct[9], correct[10], correct[11]]
            },
            {
                fillColor: colorRed25, // "#EBA3A3",
                strokeColor: colorRed100, // "#EBA3A3",
                pointColor: colorRed100, // "#EBA3A3",
                pointStrokeColor: colorRed100, // "ddd",
                data: [wrong[0], wrong[1], wrong[2],
                    wrong[3], wrong[4], wrong[5], wrong[6],
                    wrong[7], wrong[8], wrong[9],
                    wrong[10], wrong[11]]
            }]
    }

    new Chart(ctx).Line(data, {
        scaleFontFamily: "'Arial',sans-serif",
        scaleShowGridLines: false,
        scaleGridLineColor: "#ddd",
        pointDotRadius: 2.5,
        pointDotStrokeWidth: 0,
        datasetStroke: false,
        datasetStrokeWidth: 0,
        animation: true,
        animationEasing: "easeOutQuart",
        animationSteps: "10"
    });

}



// create a line chart for months
function createDaysChart(canvasObj) {
    debug('createDaysChart');
    var ctx = canvasObj.get(0).getContext("2d");
    var days = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
        "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21",
        "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];

    var data = {
        labels: [days[canvasObj.data('days')[0] - 1],
            days[canvasObj.data('days')[1] - 1],
            days[canvasObj.data('days')[2] - 1],
            days[canvasObj.data('days')[3] - 1],
            days[canvasObj.data('days')[4] - 1],
            days[canvasObj.data('days')[5] - 1],
            days[canvasObj.data('days')[6] - 1],
            days[canvasObj.data('days')[7] - 1],
            days[canvasObj.data('days')[8] - 1],
            days[canvasObj.data('days')[9] - 1],
            days[canvasObj.data('days')[10] - 1],
            days[canvasObj.data('days')[11] - 1],
            days[canvasObj.data('days')[12] - 1],
            days[canvasObj.data('days')[13] - 1],
            days[canvasObj.data('days')[14] - 1],
            days[canvasObj.data('days')[15] - 1],
            days[canvasObj.data('days')[16] - 1],
            days[canvasObj.data('days')[17] - 1],
            days[canvasObj.data('days')[18] - 1],
            days[canvasObj.data('days')[19] - 1],
            days[canvasObj.data('days')[20] - 1],
            days[canvasObj.data('days')[21] - 1],
            days[canvasObj.data('days')[22] - 1],
            days[canvasObj.data('days')[23] - 1],
            days[canvasObj.data('days')[24] - 1],
            days[canvasObj.data('days')[25] - 1],
            days[canvasObj.data('days')[26] - 1],
            days[canvasObj.data('days')[27] - 1],
            days[canvasObj.data('days')[28] - 1],
            days[canvasObj.data('days')[29] - 1],
            days[canvasObj.data('days')[30] - 1]],
        datasets: [
            {
                fillColor: colorFive,
                data: [20, 30, 24, 26, 19, 33, 24, 22, 19, 17, 23, 25,
                    19, 30, 28, 26, 33, 24, 12, 16, 24, 25, 27, 24, 16,
                    19, 30, 21, 20, 22, 21]
            },
            {
                fillColor: colorWrong,
                data: [20, 30, 24, 26, 19, 33, 24, 22, 19, 17, 23, 25,
                    19, 30, 28, 26, 33, 24, 12, 16, 24, 25, 27, 24, 16,
                    19, 30, 21, 20, 22, 21]
            }]
    }

    new Chart(ctx).Bar(data, {
        scaleFontFamily: "'Arial',sans-serif",
        scaleShowGridLines: false,
        scaleGridLineColor: "#ddd",
        barShowStroke: false,
        barStrokeWidth: 0,
        animation: true,
        animationEasing: "easeOutQuart",
        animationSteps: "10",
        barValueSpacing: 5,
        barDatasetSpacing: 0,
        responsive: true,
        maintainAspectRatio: true
    });

}



function createCardsStatusDevelopmentChart(canvasObj) {
    var ctx = canvasObj.get(0).getContext("2d");

    var labels = canvasObj.data('labels');
    var cardsNew = canvasObj.data('new');
    var cardsWrong = canvasObj.data('wrong');
    var cardsOne = canvasObj.data('one');
    var cardsTwo = canvasObj.data('two');
    var cardsThree = canvasObj.data('three');
    var cardsFour = canvasObj.data('four');
    var cardsFive = canvasObj.data('five');

    var colorTurquoise100 = "hsla(165,67%,42%,1)";
    var colorTurquoise25 = "hsla(165,67%,42%,0.25)";
    var colorRed100 = "hsla(0,67%,57%,1)";
    var colorRed25 = "hsla(0,67%,57%,0.25)";

    // var colorTurquoise100 = "#00A279";
    // var colorTurquoise25 = "#9DE7D4";
    // var colorRed100 = "#DB4646";
    // var colorRed25 = "#EDB6B6";

    var data = {
        labels: labels,
        datasets: [
            {
                fillColor: colorFive, // "#EBA3A3",
                data: cardsFive
            },
            {
                fillColor: colorFour, // "#EBA3A3",
                data: cardsFour
            },
            {
                fillColor: colorThree, // "#EBA3A3",
                data: cardsThree
            },
            {
                fillColor: colorTwo, // "#EBA3A3",
                data: cardsTwo
            },
            {
                fillColor: colorOne, // "#EBA3A3",
                data: cardsOne
            },
            {
                fillColor: colorWrong, // "#EBA3A3",
                data: cardsWrong
            },
            {
                fillColor: colorNew, // "#9CD5C7",
                data: cardsNew
            }
        ]
    }

    new Chart(ctx).Line(data, {
        // scaleBeginAtZero : true,
        scaleFontFamily: "'Arial',sans-serif",
        scaleShowGridLines: false,
        scaleGridLineColor: "#ddd",
        // scaleOverride: true,
        // scaleSteps: steps,
        // scaleStepWidth: Math.ceil(maxVal / steps),
        // scaleStartValue: 0,
        pointDot: false,
        pointDotRadius: 0,
        // pointDotStrokeWidth: 0,
        datasetStroke: false,
        // datasetStrokeWidth: 2,
        animation: true,
        animationEasing: "easeOutQuart",
        animationSteps: "10",
        responsive: true,
        maintainAspectRatio: false,
        showTooltips: false
    });

}



function createEffortChart(canvasObj) {
    var ctx = canvasObj.get(0).getContext("2d");

    var labels = canvasObj.data('labels');
    var correct = canvasObj.data('correct');
    var partially = canvasObj.data('partially');
    var wrong = canvasObj.data('wrong');
    var maxVal = canvasObj.data('maxval');

    var colorTurquoise100 = "hsla(165,67%,42%,1)";
    var colorTurquoise25 = "hsla(165,67%,42%,0.25)";
    var colorRed100 = "hsla(0,67%,57%,1)";
    var colorRed25 = "hsla(0,67%,57%,0.25)";
    var colorOrange100 = "hsla(30,83%,51%,1)";
    var colorOrange25 = "hsla(30,83%,51%,0.25)";

    // var colorTurquoise100 = "#00A279";
    // var colorTurquoise25 = "#9DE7D4";
    // var colorRed100 = "#DB4646";
    // var colorRed25 = "#EDB6B6";

    var data = {
        labels: labels,
        datasets: [
            {
                fillColor: colorTurquoise25, // "#9CD5C7",
                strokeColor: colorTurquoise100, // "#9CD5C7",
                pointColor: colorTurquoise100, // "#9CD5C7",
                pointStrokeColor: colorTurquoise100, // "#ddd",
                data: correct
            },
            {
                fillColor: colorOrange25, // "#EBA3A3",
                strokeColor: colorOrange100, // "#EBA3A3",
                pointColor: colorOrange100, // "#EBA3A3",
                pointStrokeColor: colorOrange100, // "ddd",
                data: partially
            },
            {
                fillColor: colorRed25, // "#EBA3A3",
                strokeColor: colorRed100, // "#EBA3A3",
                pointColor: colorRed100, // "#EBA3A3",
                pointStrokeColor: colorRed100, // "ddd",
                data: wrong
            }
        ]
    }

    var steps = 5;

    new Chart(ctx).Line(data, {
        scaleFontFamily: "'Arial',sans-serif",
        scaleShowGridLines: false,
        scaleGridLineColor: "#ddd",
        // scaleOverride: true,
        // scaleSteps: steps,
        // scaleStepWidth: Math.ceil(maxVal / steps),
        // scaleStartValue: 0,
        pointDotRadius: 2.5,
        pointDotStrokeWidth: 0,
        datasetStroke: true,
        datasetStrokeWidth: 2,
        animation: true,
        animationEasing: "easeOutQuart",
        animationSteps: "10",
        responsive: true,
        maintainAspectRatio: false
    });

}



function createKnownCardsChart(canvasObj) {
    var ctx = canvasObj.get(0).getContext("2d");

    var labels = canvasObj.data('labels');
    var correct = canvasObj.data('correct');
    var maxVal = canvasObj.data('maxval');

    var colorTurquoise100 = "hsla(165,67%,42%,1)";
    var colorTurquoise25 = "hsla(165,67%,42%,0.25)";

    var data = {
        labels: labels,
        datasets: [
            {
                fillColor: colorTurquoise25, // "#9CD5C7",
                strokeColor: colorTurquoise100, // "#9CD5C7",
                pointColor: colorTurquoise100, // "#9CD5C7",
                pointStrokeColor: colorTurquoise100, // "#ddd",
                data: correct
            }

        ]
    }

    var steps = 5;

    new Chart(ctx).Line(data, {
        scaleFontFamily: "'Arial',sans-serif",
        scaleShowGridLines: false,
        scaleGridLineColor: "#ddd",
        scaleOverride: true,
        scaleSteps: steps,
        scaleStepWidth: Math.ceil(maxVal / steps),
        scaleStartValue: 0,
        pointDotRadius: 2.5,
        pointDotStrokeWidth: 0,
        datasetStroke: false,
        datasetStrokeWidth: 0,
        animation: true,
        animationEasing: "easeOutQuart",
        animationSteps: "10",
        responsive: true,
        maintainAspectRatio: false,
    });

}



function createTripleChart(canvasObj) {
    var ctx = canvasObj.get(0).getContext("2d");

    var labels = canvasObj.data('labels');
    var first = canvasObj.data('first');
    var second = canvasObj.data('second');
    var third = canvasObj.data('third');

    var colorTurquoise100 = "hsla(165,67%,42%,1)";
    var colorTurquoise25 = "hsla(165,67%,42%,0.25)";

    var colorRed100 = "hsla(0,67%,57%,1)";
    var colorRed25 = "hsla(0,67%,57%,0.25)";

    var colorOrange100 = "hsla(32, 100%, 50%, 1)";
    var colorOrange25 = "hsla(32, 100%, 50%, 0.25)";

    var colorBlue100 = "hsla(220, 100%, 50%, 1)";
    var colorBlue25 = "hsla(220, 100%, 50%, 0.25)";

    // var colorTurquoise100 = "#00A279";
    // var colorTurquoise25 = "#9DE7D4";
    // var colorRed100 = "#DB4646";
    // var colorRed25 = "#EDB6B6";

    var data = {
        labels: labels,
        datasets: [
            {
                fillColor: colorTurquoise25, // "#9CD5C7",
                strokeColor: colorTurquoise100, // "#9CD5C7",
                pointColor: colorTurquoise100, // "#9CD5C7",
                pointStrokeColor: colorTurquoise100, // "#ddd",
                data: first
            },
            {
                fillColor: colorOrange25, // "#EBA3A3",
                strokeColor: colorOrange100, // "#EBA3A3",
                pointColor: colorOrange100, // "#EBA3A3",
                pointStrokeColor: colorOrange100, // "ddd",
                data: second
            },
            {
                fillColor: colorBlue25, // "#EBA3A3",
                strokeColor: colorBlue100, // "#EBA3A3",
                pointColor: colorBlue100, // "#EBA3A3",
                pointStrokeColor: colorBlue100, // "ddd",
                data: third
            }
        ]
    }

    var steps = 5;

    new Chart(ctx).Line(data, {
        scaleFontFamily: "'Arial',sans-serif",
        scaleShowGridLines: false,
        scaleGridLineColor: "#ddd",
        // scaleOverride: true,
        // scaleSteps: steps,
        // scaleStepWidth: Math.ceil(maxVal / steps),
        // scaleStartValue: 0,
        pointDotRadius: 2.5,
        pointDotStrokeWidth: 0,
        datasetStroke: true,
        datasetStrokeWidth: 2,
        animation: true,
        animationEasing: "easeOutQuart",
        animationSteps: "10",
        responsive: true,
        maintainAspectRatio: false
    });

}


// function createEffortChart(canvasObj) {
//	if (canvasObj.hasClass("months")) {
//		// months line chart
//		createMonthsChart(canvasObj);
//	} else if (canvasObj.hasClass("weeks")) {
//		// weeks line chart
//		createWeeksChart(canvasObj);
//	} else if (canvasObj.hasClass("days")) {
//		// days bar chart
//		createDaysChart(canvasObj);
//	}
// createChart(canvasObj);
// }



// set variables for stats colors
function setColors() {
    // light theme
    if ($("html").hasClass("light")) {

        // colorNewMod = "#b3b3b3"; // slightly darker for display on body
        // background
        colorNew = "#CCCCCC";
        colorWrong = "#DB4646";
        colorOne = "#E77C3D";
        colorTwo = "#E79E3D";
        colorThree = "#E9C63A";
        colorFour = "#A9B935";
        colorFive = "#23B18E";

        // dark theme
    } else {

        // colorNewMod = "#b3b3b3"; // slightly darker for display on body
        // background
        colorNew = "#4A4A4A";
        colorWrong = "#9F3B3B";
        colorOne = "#AB5E31";
        colorTwo = "#C98F30";
        colorThree = "#E6BF28";
        colorFour = "#8F9B3E";
        colorFive = "#009570";

    }
}

// NOT NEEDED ???
// clear chart first, before you can redraw/update it!?
function clearChart(chart) {
    var chartWidth = chart.outerWidth();
    var chartHeight = chart.outerHeight();
    chart.get(0).getContext("2d").clearRect(0, 0, chartWidth, chartHeight);
}

function redrawStatisticGraphs() {
    clearChart($(".donut-chart.big"))
    setColors();
    createDonutChart($(".donut-chart.big"));
    // temporary fix, if statements because non-premium users don't have these stats right now
    if ($("#cards-status-development-chart").length > 0) {
        createCardsStatusDevelopmentChart($("#cards-status-development-chart").find("canvas"));
    }
    if ($("#effort-chart").length > 0) {
        createEffortChart($("#effort-chart").find("canvas"));
    }
    if ($("#known-cards-chart").length > 0) {
        createKnownCardsChart($("#known-cards-chart").find("canvas"));
    }
}

// CARDSET MODULE STATS (should be in card-set-module.js but will produce js-bug if moved there)
function loadStatistics(cardsetsContainer) {
    debug("Loading statistics.");
    cardsetsContainer = cardsetsContainer || $(".cardsets-container");
    if ($(".cardsets-container").length > 0) {
        drawStatisticsForEachcardsetBlock();
    } else {
        drawDonutChart();
        drawProgressBar();
    }
}

function drawStatisticsForEachcardsetBlock() {
    $(".kartensatz-block").each(function () {
        if ($(this).find(".donut-chart").length > 0 && !$(this).hasClass('donutChartDrawn')) {
            $(this).bind('inview', function (event, isInView, visiblePartX, visiblePartY) {
                if (isInView) {
                    if (getCookie("cardsetListLayout") !== 'compact') {
                        drawDonutChart("#" + $(this).attr('id'));
                    }
                    drawProgressBar("#" + $(this).attr('id'));
                    $(this).unbind('inview');
                }
            });
        }
    }); // end each
}

function unbindDrawStatistics() {
    $(".kartensatz-block").each(function () {
        $(this).unbind('inview');
    });
}


function drawDonutChart(cardSetBlockName) {
    cardSetBlockName = cardSetBlockName || '';
    if (cardSetBlockName != '') {
        // draw donut graph (of visible card set modules only! on home page inactive card set modules are not displayed, that's why if statement is needed)
        if ($(cardSetBlockName).find(".donut-chart").length) {
            createDonutChart($(cardSetBlockName).find(".donut-chart"));
        }
    } else {
        $(".kartensatz-block").each(function (i, obj) {
            if ($(this).find(".donut-chart").length) {
                createDonutChart($(this).find(".donut-chart"));
            }
        });
    }
    $(cardSetBlockName).addClass('donutChartDrawn');
}



function drawProgressBar(cardSetBlockName) {
    cardSetBlockName = cardSetBlockName || '';
    if (cardSetBlockName != '') {
        // set progress bar to correct length
        $(cardSetBlockName).find(".progress-bar").animate({width: $(cardSetBlockName).find(".progress-bar").data('progress') + '%'}, 1000, 'easeOutQuad');
    } else {
        $(".kartensatz-block").each(function (i, obj) {
            // set progress bar to correct length
            $(this).find(".progress-bar").animate({width: $(this).find(".progress-bar").data('progress') + '%'}, 1000, 'easeOutQuad');
        });
    }
    $(cardSetBlockName).addClass("progressBarDrawn");
}


