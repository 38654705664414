function generalPurposeModal(message, title) {
    title = typeof title !== 'undefined' ? title : i18n.gettext('Info');
    var titleString = '<p class="modal-hl">'
                + '<i class="fa fa-info-circle"></i>'
                + '<span>' + title + '</span>'
                + '</p>';
    $("#general-purpose-modal-content").html(titleString + message);
    $("#general-purpose-modal").modal({showClose: false});
}

function expandModalHorizontally() {
    $("#general-purpose-modal").width(800);
    $("#general-purpose-modal .expandModalButton").hide();
    $("#general-purpose-modal .compressModalButton").show();
}

function compressModalHorizontally() {
    $("#general-purpose-modal").width(400);
    $("#general-purpose-modal .expandModalButton").show();
    $("#general-purpose-modal .compressModalButton").hide();
}