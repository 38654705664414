/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

function loadLearningStatusDev(pupilId) {

    showAjaxLoader("#cardStatusDevPlaceholder-" + pupilId);
    var boxId = $("#staple").val();
    
    $.ajax({
        type: "POST",
        url: "profile-" + pupilId,
        data: "getStatusDev=" + 1 + "&selectedBox=" + boxId + "&module=cards-status-development-chart",
        context: this,
        success: function(data){
            $("#cardStatusDevPlaceholder-" + pupilId).html(data);
            createCardsStatusDevelopmentChart($("#cardStatusDevPlaceholder-" + pupilId).find("canvas"));
        },
        error: function() {
            flashModal(i18n.gettext("Error while loading the diagram."));
        }
    });
    
    
}
