function moveToFolderModal(boxId) {
    if (isNaN(boxId)) {
        $("#folder-modal #modalActionButtonText").html(i18n.gettext('Select folder'));
    } else {
        $("#folder-modal #modalActionButtonText").html(i18n.gettext('Move cardset to folder'));
    }
    $("#folder-modal").modal();
    if (!isNaN(boxId)) {
        $("#folder-modal #moveToFolderBoxId").val(boxId);
        var currentFolderId = $("#folderId-" + boxId).val();
    }
    $.getJSON('manage-folders', 'do=getFolders', function (folders) {
        $("#folder-modal #folderSelection").empty();
        $("#folder-modal #folderSelection").append('<option value="0">- ' + i18n.gettext("No folder") + ' -</option>');
        $.each(folders, function (index, folder) {
            var selector = '';
            if (currentFolderId == folder.folderKey) {
                var selector = ' selected="selected"';
            }
            $("#folder-modal #folderSelection").append('<option value="' + folder.folderKey + '" ' + selector + '>' + folder.folderName
                    + '</option>');
        });
        // $("#folder-modal #folderSelection").append('<option value="-1" onclick="loadPageByAjax(\'manage-folders\');">' + i18n.gettext("Manage folders") + "..." + '</option>');
    });
}

function moveToFolderModalAction() {
    closeModal();
    var boxId = $("#folder-modal #moveToFolderBoxId").val();
    var folderId = $("#folder-modal #folderSelection").val();
    if (!isNaN(boxId) && boxId > 0) {
        $.post('manage-folders', 'do=assign&get=json&folderId=' + folderId + '&boxId=' + boxId, function (data) {
            var obj = $.parseJSON(data);
            flashModal(obj.message);
            $("#folderId-" + boxId).val(folderId);
            if ($(".cardsets-container").length) {
                refreshCardsetsContainer();
            } else {
                refreshPage();
            }
        });
    } else {
        var folderName = $("#folder-modal #folderSelection option:selected").text();
        $("#folderId").val(folderId);
        $("#cardsetFolder").html(folderName);
    }
}