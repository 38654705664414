/* SELECT CATEGORY MODAL */
function selectCategoriesModal(el, boxId) {
    $("#select-category-modal").modal();
    // get box categories
    // var boxId = $(el).closest(".card-type").data("box-id");
    // var boxId = $("#boxId").val();
    $("#move-cards-to-category-btn-card").data("target", $(el).closest(".card"));
    showAjaxLoader("#select-category-modal .categories");
    $.getJSON("kartensatz-karten-" + boxId + '?categories', function (data) {
        // reset and build categories HTML
        hideAjaxLoader();
        $("#select-category-modal .categories").html('');
        var categoryString = '';
        $.each(data, function (index, value) {
            // category key = value.id
            // category name = value.name
            categoryString += ' \
					<div class="input-x input-c w100 manageCategory-' + value.id + '"> \
						<span class="custom-checkbox"> \
							<input class="category-selector" type="checkbox" id="category-' + value.id + '-checkbox"> \
							<!-- <span class="box"> \
								<i class="fa fa-check"></i> \
							</span> --> \
						</span> \
						<input type="text" class="w100"  onblur="categoryInputBlur(this)" tabindex="20" id="editCategory-' + value.id + '" value="' + value.name + '"';
            if (!value.removeOrEditAllowed) {
                categoryString += ' disabled=\"disabled\"';
            }
            categoryString += '>';
            if (value.removeOrEditAllowed) {
                categoryString += '<button class="remove-btn" onclick="modalRemoveCategory(this)" id="removeCategory-' + value.id + '"> \
                                <i class="fa fa-minus-square-o"></i> \
                    </button>';
            }
            categoryString += '</div>';
        }); // end each
        $("#select-category-modal .categories").html(categoryString);

        if ($("#cardId").length) { // cardset card
            // get assigned categories and check the according checkboxes
            var cardId = $("#cardId").val();
            $.getJSON('karte-' + cardId + '?assignedCategories', function (data) {
                data.forEach(function (element) {
                    debug(element);
                    $("#select-category-modal #category-" + element + "-checkbox").attr("checked", '');
                });
            });
        } else if ($(el).closest(".card").length) {
            var categories = $(el).closest(".card").data("categories");
            debug("Categories are: " + categories);
            categories.forEach(function (element) {
                debug(element);
                $("#select-category-modal #category-" + element + "-checkbox").attr("checked", '');
            });
        } else if ($("#import-cards-categories").length > 0) {
           var categoriesString = $("#import-cards-categories").val();
           var categories = categoriesString.split(",");
           debug("Categories are: " + categories);
            categories.forEach(function (element) {
                debug(element);
                $("#select-category-modal #category-" + element + "-checkbox").attr("checked", '');
            });
        } else {
            debug("We are not in a card.");
        }

//        else if ($(el).closest(".new-card").length > 0) {
//            // get categories last selected, from cookie and check the according checkboxes
//            $.getJSON("kartensatz-karten-" + boxId + '?cookieCategories', function (data) {
//                data.forEach(function (element) {
//                    debug(element);
//                    $("#category-" + element + "-checkbox").attr("checked", '');
//                });
//            });
//        }

    }); // end $.getJSON
}



function selectCategoriesModalAddCategory(el) {
    $('.modal.current').find('.categories').append(' \
		<div class="input-x input-c w100"> \
			<span class="custom-checkbox"> \
				<input type="checkbox" class="category-selector" id="category-new-checkbox" checked="checked"> \
				<!-- <span class="box"> \
					<i class="fa fa-check"></i> \
				</span> --> \
			</span> \
    		<input type="text" class="w100" tabindex="20" onblur="categoryInputBlur(this)"> \
  			<button class="remove-btn"> \
  				<i class="fa fa-minus-square-o"></i> \
  			</button> \
  		</div> \
	');
    $(el).closest(".modal").find("input:last").focus();
    $(el).closest(".modal").find("input[type=checkbox]:last").prop("checked", true);
}

function selectForcedCategoriesModalAction(el) {
    var categories = [];
    $("#select-category-modal").find(".category-selector").each(function (index) {
        // folgendes ändern im Sinne von:
        // $('input:check')[0].checked == true;
        if ($(this).is(":checked")) {
            var mystr = $(this).attr('id');
            var myarr = mystr.split("-");
            var categoryId = myarr[1];
            categories.push(categoryId);
        }
    });
    
    if (categories.length == 0) {
        $("#select-category-modal #selectAtLeastOneCategory").show();
        $("#select-category-modal .action-btn").effect("shake");
    } else {
        $("#select-category-modal #selectAtLeastOneCategory").hide();
        selectCategoriesModalAction(el);
    }
}

function selectCategoriesModalAction(el) {
    // safety delay, e.g. a user edits a category name and clicks button right after! category name has to be updated first!
    closeModal();
    setTimeout(function () {
        // debug("Check: Are all categories saved?");
        if (!$('#move-cards-to-category-btn-card').hasClass('savingCategory')) {

            // get selected categories
            var thisModal = $(el).closest(".modal");
            var categories = [];
            var categoryNames = [];
            thisModal.find(".category-selector").each(function (index) {
                // debug( index + ": " +  $( this ).next().find('i').css('display')  );

                // folgendes ändern im Sinne von:
                // $('input:check')[0].checked == true;

                if ($(this).is(":checked")) {
                    var mystr = $(this).attr('id');
                    var myarr = mystr.split("-");
                    var categoryId = myarr[1];
                    var categoryName = $('#editCategory-' + categoryId).val();
                    categories.push(categoryId);
                    categoryNames.push(categoryName);
                }
            });


            if ($("#move-cards-to-category-btn-card").length > 0 && $("#import-cards-categories").length === 0) {
                var thisCard = $("#move-cards-to-category-btn-card").data("target");

                // check if modal belongs to new-card or card
                if (thisCard.closest(".new-card").length > 0) {
                    // new-card
                    // setCategoriesCookie(boxId, categories);
                } else if ($('#cardId').length > 0) {
                    // card; save categories!
                    var cardId = $('#cardId').val();
                    $.ajax({
                        type: "POST",
                        url: "karte-" + cardId,
                        data: "moveToCategories=" + categories
                                // success: function(data) { }
                    });
                }

                // write category selection to correct .card jquery.data('categories'), but empty first!
                thisCard.data('categories', []);
                $.each(categories, function () {
                    thisCard.data('categories').push(this);
                });
                // alert(targetAncestor.find(".card").data('categories'));

                // Komma-separierte Liste aktualisieren
                thisCard.find(".card-categories").html(categoryNames.join(", "));

                // show or hide card categories text depending on whether categories are selected or not
                if (thisCard.find(".card-categories").html() == "") {
                    thisCard.find(".card-categories").addClass("hidden");
                } else {
                    thisCard.find(".card-categories").removeClass("hidden");
                }
                equalizeCardTopTextHeight(thisCard);
            }

            if ($("#import-cards-categories").length > 0) {
                debug(categories.join(","));
                $("#import-cards-categories").val(categories.join(","));
                $("#import-cards-categories-names").html(categoryNames.join(", "));
            }

            closeModal();
        }

    }, 500);

}
