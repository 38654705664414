function deleteStatsModal() {
    $("#delete-stats-modal").modal();
}

function deleteStatsModalAction() {
    var boxId = $('#boxId').val();
    flashModal(i18n.gettext('Deleting learning statistics...'));
    $.ajax({
        type: "POST",
        url: "kartensatz-einstellungen-" + boxId,
        data: "deleteStats=1",
        success: function (data) {
            refreshLearnplanInfos(data);
            flashModal(i18n.gettext("Learning statistics deleted."));
        }
    });
}

function resetDelay() {
    var boxId = $('#boxId').val();
    flashModal(i18n.gettext('Resetting delay...'));
    $.ajax({
        type: "POST",
        url: "kartensatz-einstellungen-" + boxId,
        data: "resetDelay=1",
        success: function (data) {
            refreshLearnplanInfos(data);
            flashModal(i18n.gettext("Delay has been set to 0 days."));
        }
    });
}