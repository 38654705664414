function wat() {
    if ($(".cards-wrong-quantity").length > 0 && $(".cards-wrong-quantity").html() > 499 && $(".cards-correct-quantity").length == 0) {
        var imgCode = '<img src="' + $("html").data("approot_images") + 'wat.gif" id="watimg" style="display: none; position: fixed; bottom: 0; left: auto; right: auto; z-index: 65535;">';

        $("#watdiv").html(imgCode);

        $("#watimg").show();
        setTimeout(function () {
            $("#watimg").hide();
            $("#watdiv").html('');
        }, 3600);
    } else if ($(".cards-correct-quantity").length > 0 && $(".cards-correct-quantity").html() > 499 && $(".cards-wrong-quantity").length == 0) {
        var imgCode = '<img src="' + $("html").data("approot_images") + 'clap.gif" id="watimg" style="display: none; position: fixed; bottom: 0; left: auto; right: 0; z-index: 65535;">';

        $("#watdiv").html(imgCode);

        $("#watimg").show();
        setTimeout(function () {
            $("#watimg").hide();
            $("#watdiv").html('');
        }, 3600);
    }
}