function moveCardModal() {
	$("#move-card-modal").modal();
    var sourceBoxId = $("#boxId").val();
    $.ajax({
        type: "GET",
        url: "main",
        data: "getActiveCardboxes=1&subset=all&addAllowed=1",
        success: function (data) {
            var boxes = JSON.parse(data.trim());
            var optionsHtml = '';
            // var selectedString;
            $.each(boxes, function (index, box) {
                // debug("cardBoxNbr: " + box.cardBoxNbr + ", cardBoxName: " + box.cardBoxName);
//                if (sourceBoxId == box.cardBoxNbr) {
//                    selectedString = ' selected="selected"';
//                } else {
//                    selectedString = '';
//                }
                optionsHtml += '<option value="' + box.cardBoxNbr + '">' + box.cardBoxName + '</option>';
            });
            $("#move-card-modal #target-cardset").html(optionsHtml);
            $("#move-card-modal #target-cardset").val(sourceBoxId);
        }
    });
}

function copyCardModal() {
	$("#copy-card-modal").modal();
    var sourceBoxId = $("#boxId").val();
    $.ajax({
        type: "GET",
        url: "main",
        data: "getActiveCardboxes=1&subset=all&addAllowed=1",
        success: function (data) {
            var boxes = JSON.parse(data.trim());
            var optionsHtml = '';
            // var selectedString;
            $.each(boxes, function (index, box) {
                // debug("cardBoxNbr: " + box.cardBoxNbr + ", cardBoxName: " + box.cardBoxName);
//                if (sourceBoxId == box.cardBoxNbr) {
//                    selectedString = ' selected="selected"';
//                } else {
//                    selectedString = '';
//                }
                optionsHtml += '<option value="' + box.cardBoxNbr + '">' + box.cardBoxName + '</option>';
            });
            $("#copy-card-modal #target-cardset").html(optionsHtml);
            $("#copy-card-modal #target-cardset").val(sourceBoxId);
        }
    });
}

function moveCardModalAction(onlyCopy) {
    var cardId = $("#cardId").val();
        
    if (typeof onlyCopy === 'undefined') { onlyCopy = $("#move-card-copy").is(':checked'); }

    var targetBoxId;
    var orderIndex;
    var createCategories;

    // first: check if card can be added in other cardset
    if (onlyCopy) {
        targetBoxId = $("#copy-card-modal #target-cardset").val();
        orderIndex = $("#copy-card-modal #orderIndexModalInput").val();
        createCategories = $("#copy-card-modal #copyCardCreateCategories").is(":checked");
        flashModal(i18n.gettext('Copying...'));
    } else {
        targetBoxId = $("#move-card-modal #target-cardset").val();
        orderIndex = $("#move-card-modal #orderIndexModalInput").val();
        createCategories = $("#move-card-modal #moveCardCreateCategories").is(":checked");
        flashModal(i18n.gettext('Moving...'));
    }

    $.ajax({
        type: "GET",
        url: "kartensatz-karten-" + targetBoxId,
        data: "getCreateCardInfo=1",
        success: function (data) {
//            try {
                var obj = JSON.parse(data.trim());

                $("#numOfCreatedCards").val(parseInt(obj.numOfCreatedCards));
                $("#numOfCardsPerBox").val(parseInt(obj.numOfCardsPerBox));
                $("#proUser").val(obj.proUser);

                if (parseInt(obj.numOfCreatedCards) >= parseInt(obj.numOfCardsPerBox)) {

                    // - Falls ja und FREE-Status, repeticoPromptModal('Mit Repetico PRO unbegrenzt viele Kartens&auml;tze anlegen!');
                    // - Falls ja und PRO-Status, flashModal('Maximale Anzahl erreicht!');
                    if (obj.proUser) {
                        flashModal(i18n.gettext('Maximum number of cards per cardset reached.')
                                + ' '
                                + i18n.gettext('Cards in trash are counted as well.'));
                    } else {
                        repeticoPromptModal(i18n.gettext('Maximum number of cards per cardset for FREE-users reached.')
                                + ' '
                                + i18n.gettext('Cards in trash are counted as well.')
                                + '<br/><br/>'
                                + sprintf(i18n.gettext('Create up to 2000 cards per box with %s!'), sprintf('<span style="font-weight:bold;">%s PRO</span>', $("html").data("apptitle"))));
                    }

                } else {

                    var action = '';
                    if (onlyCopy) {
                        action = 'copyCardToBox';
                    } else {
                        action = 'moveCardToBox';
                    }

                    $.ajax({
                        type: "POST",
                        url: "karte-" + cardId,
                        data: action + "=" + targetBoxId + "&orderIndex=" + orderIndex + "&createCategories=" + createCategories,
                        success: function (data) {
//                            try {
                                var obj = JSON.parse(data.trim());
                                flashModal(obj.message);
                                debug(obj.success);
                                if (obj.success === true) {
                                    loadPageToCenter('karte-' + cardId);
                                }
//                            } catch (e) {
//                                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                                return;
//                            }
                        },
                        error: function () {
                            if (onlyCopy) {
                                flashModal(i18n.gettext('Error while copying'));
                            } else {
                                flashModal(i18n.gettext('Error while moving'));
                            }
                        }
                    });

                }
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }

        }

    });

}

function copyCardModalAction() {
    moveCardModalAction(true);
}