function checkIfChangingUniAllowed(uniId) {
    flashModal(i18n.gettext("One moment, please..."));
    $.get("api/v5/changinguniallowed", function(result) {
        var json = JSON.parse(result);
        if (json.success) {
            $('#select-uni-button').data('uni', uniId);
            $('#select-uni-modal').modal();
        } else {
            flashModal(json.message);
        }
    })
}

function selectUni() {
    var uniId = $("#select-uni-button").data("uni");
    flashModal(i18n.gettext("One moment, please..."), 5000);
    $.ajax({
        type: "POST",
        url: "select-uni",
        data: "uni=" + uniId
    }).done(function (data) {
        var json = JSON.parse(data);
        if (json.success) {
            loadPageByAjax("main");
        } else {
            flashModal(json.message);
        }
    }).fail(function (xhr, status) {

    });
}