function emptyTrashModal() {
    if ($("html").data("prouser") == "1") {
        $("#empty-trash-modal").modal();
    } else {
        freeInfoModal();
        setProPurchaseTrigger('empty-trash');
    }
}

function emptyTrash() {
    var boxId = $("#boxId").val();
    flashModal(i18n.gettext("Emptying trash..."));
    $.ajax({
        type: "POST",
        url: "kartensatz-" + boxId,
        data: "emptyTrash=1",
        success: function (data) {
            var obj = JSON.parse(data.trim());
            flashModal(obj.message);
            loadAllCards();
        }
    });
}