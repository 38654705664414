tinyMCE.baseURL = "scripts/repetico2014/" + $("html").data("appversion") + "/vendor/tinymce";
tinyMCE.suffix = '.min';

function editorConfig(el) {

    // don't show panel if cookie is set and element is a card editor
    if (($.cookie("tinymcePanelVisibility") === "hidden") && (el.closest(".card").length)) {
        var toolbarVar = false;
        var toolbarVar2 = false;
        var toolbarVar3 = false;
    } else {
        var toolbarVar = "undo redo formatselect bullist numlist alignleft aligncenter alignright alignjustify indent outdent";
        var toolbarVar2 = "fontselect fontsizeselect bold italic underline strikethrough forecolor backcolor removeformat";
        if ($("html").data("theme") == "beck-pl") {
            var toolbarVar3 = "jbimages subscript superscript charmap table code legalis shortcuts";
        } else {
            var toolbarVar3 = "jbimages uploadsound youTube subscript superscript charmap latex table link code shortcuts";
        }
        // toolbarVar3 = (el.closest(".card").length) ? toolbarVar3 + " close" : toolbarVar3;
    }

    var tinyMCElanguage = $("html").data("locale").substring(0, 2);
    debug("TinyMCE-language: " + tinyMCElanguage);

    var isUploadingImage = false;

    var editorConf = {
        inline: true,
        // hidden: true,
        browser_spellcheck: true,
        gecko_spellcheck: true,
        relative_urls: false,
        remove_script_host: false,
        fix_list_elements: true,
        convert_fonts_to_spans: true,
        cleanup: true,
        cleanup_on_startup: true,
        verify_html: true,
        forced_root_block: 'div',
        entity_encoding: "numeric",
        paste_auto_cleanup_on_paste: true,
        paste_remove_styles: true,
        paste_remove_styles_if_webkit: true,
        paste_strip_class_attributes: true,
        default_link_target: "_self",
        // table_grid: false,
        paste_data_images: true,
        paste_preprocess: function (plugin, args) {
            // debug(args.content);
//            if (!args.content.toLowerCase().indexOf("<br/>") >= 0 && !args.content.toLowerCase().indexOf("<br />") >= 0 && !args.content.toLowerCase().indexOf("<br>") >= 0) {
//                args.content = args.content.replace(/\n/g, "<br />");
//            }
            if (args.content.toLowerCase().indexOf("<p>") >= 0 && args.content.toLowerCase().indexOf("</p>") >= 0) {
                args.content = args.content.replace("<p>", "<div>");
                args.content = args.content.replace("</p>", "</div>");
            }
            // args.content = args.content.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
        },
        images_upload_handler: function (blobInfo, success, failure) {
            // flashModal(i18n.gettext("Uploading image..."));
            // success('http://images.repetico.de/ajax-loader.gif');
            if ($("html").data("theme") != "beck-pl") {
                debug("Activating images_upload_handler ...");
                //TODO The picture should have an overlay with yellow collor which says "SAVING..." ...
                flashModal(i18n.gettext("Uploading..."), 999999);
                isUploadingImage = true;
                //TODO If the user does not REALLY wait here until the upload is finished, then the picture will possibly break and the user does not realize it until it is too late.
                var xhr, formData;
                xhr = new XMLHttpRequest();
                // xhr.withCredentials = false;
                xhr.open('POST', 'upload?json=1', true);
                xhr.onload = function () {
                    isUploadingImage = false;
                    var json;
                    if (xhr.status != 200) {
                        alert("Status != 200!");
                        failure('HTTP Error: ' + xhr.status);
                        return;
                    }

                    try {
                        json = JSON.parse(xhr.responseText);
                        if (!json || typeof json.location != 'string') {
                            if (!json) {
                                flashModal(i18n.gettext("Error while uploading") + ".");
                            } else {
                                flashModal(i18n.gettext("Error while uploading") + ": " + json.message);
                            }
                            success("http://images.repetico.de/clipart/icon_alert.gif");
                            failure('Invalid JSON: ' + xhr.responseText);
                            return;
                        }
                        debug(json.message);
                        success(json.location);
                        flashModal(json.message);
                    } catch (err) {
                        flashModal(err.message);
                        success("http://images.repetico.de/clipart/icon_alert.gif");
                        failure('Invalid JSON: ' + xhr.responseText);
                        return;
                    }

                };

                formData = new FormData();
                formData.append('userfile', blobInfo.blob(), blobInfo.filename);
                xhr.send(formData);
            }
        },
        media_strict: false,
        menubar: false,
        statusbar: false,
        language: tinyMCElanguage,
        theme: "modern",
        fixed_toolbar_container: "#tinymceToolbarContainer",
        // skin: "repetico",
        charLimit: 65535,
        // content_css: "scripts/repetico2014/vendor/tinymce417/js/tinymce/skins/light/content.min.css, scripts/repetico2014/vendor/tinymce417/js/tinymce/skins/light/skin.min.css",
        fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 24px 36px",
        // "div" should not be allowed as valid element, because otherwise it can lead to strange side effects.
        extended_valid_elements: 'a[href|target=_blank|data-lightbox|data-title|class|style|title],p[*],img[onclick|src|style|width|height|title],span[*]',
        plugins: "image imagetools link textcolor autolink charmap table paste code youTube close jbimages lists advlist legalis",
        imagetools_proxy: 'imagetools',
        imagetools_toolbar: "editimage imageoptions",
        font_formats: "Andale Mono=andale mono,times;" +
                "Arial=arial,helvetica,sans-serif;" +
                "Arial Black=arial black,avant garde;" +
                "Book Antiqua=book antiqua,palatino;" +
                "Comic Sans MS=comic sans ms,sans-serif;" +
                "Courier New=courier new,courier;" +
                "Georgia=georgia,palatino;" +
                "Helvetica=helvetica;" +
                "Impact=impact,chicago;" +
                "Roboto=roboto;" +
                "Symbol=symbol;" +
                "Tahoma=tahoma,arial,helvetica,sans-serif;" +
                "Terminal=terminal,monaco;" +
                "Times New Roman=times new roman,times;" +
                "Trebuchet MS=trebuchet ms,geneva;" +
                "Verdana=verdana,geneva;" +
                "Webdings=webdings;" +
                "Wingdings=wingdings,zapf dingbats",
        // selector: 'textarea',  // change this value according to your HTML
        // file_picker_types: 'file image media',
        file_picker_callback: function (callback, value, meta) {
            if (meta.filetype == 'image' || meta.filetype == 'media') {
                $("#media_file").unbind("change");
                $("#media_file").change(function (event) {
                    onFileChosen(event, callback);
                });
                $("#media_file").click();
            } else {
                flashModal(i18n.gettext("File type not supported."));
            }
        },
        convert_urls: false,
//	    file_browser_callback: function (field_name, url, type, win) {
//	        if (type == 'image') {
//	            $('#media_form input').click();
//            }
//	    },
        // styleselect
        // toolbar1: "undo redo fontselect fontsizeselect bold italic underline",
        // toolbar2: "forecolor backcolor alignleft aligncenter alignright bullist numlist indent outdent link image youTube",
        // toolbar3: "subscript superscript charmap latex table code"
        toolbar1: toolbarVar,
        toolbar2: toolbarVar2,
        toolbar3: toolbarVar3
                // fixed_toolbar_container: '#mytinytoolbar'
    } // end editorConf

    var setupFn = function (ed) {

        // Adding the LaTeX button
        ed.addButton('latex', {
            title: i18n.gettext('LaTeX-formula'),
            image: $("html").data("approot_images") + 'math.png',
            onclick: function () {
                try {
                    // Insert default LaTeX formula and show explaining popup
                    ed.focus();
                    ed.selection.setContent('[latex]\\frac{a}{b}[/latex]');
                    if (typeof $("html").data("hidelatexhelp") == 'undefined' || $("html").data("hidelatexhelp") == '') {
                        var titleString = '<p class="modal-hl">'
                                // + '<i class="fa fa-superscript"></i>'
                                + '<span>' + i18n.gettext('LaTeX-formulas') + '</span>'
                                + '</p>';
                        var helpText = '<ul><li style="margin-left:10px;">' + i18n.gettext('LaTeX-formulas are written inside some special tags: ') + ' <pre>[latex] . . . [/latex]</pre></li>'
                                + '<li style="margin-left:10px;">' + 'Beispiel:' + ' <pre>[latex]\\frac{a}{b}[/latex]</pre> ' + 'wird zu' + ' <br/><br/><img border="0" src="http://www.kroener.org/cgi-bin/mimetex.cgi?\\frac{a}{b}"><br/><br/></li>';
                        // + '<li style="margin-left:10px;">Mehr Informationen über die hier genutzte LaTeX-Implementierung findest Du <a style="font-weight:bold;" target="_blank" href="http://www.forkosh.dreamhost.com/source_mimetexmanual.html#reference">hier</a>.</li></ul>';
                        $("#general-purpose-modal-content").html(titleString + helpText);
                        $("#general-purpose-modal").modal();
                        setUserAttribute(38, 1);
                        $("html").data("hidelatexhelp", 1);
                    }
                } catch (e) {
                    var error = e.message;
                    debug(error);
                    sendError("TinyMCE init-error: addButton(latex): " + error);
                }
            }
        });

        ed.addButton('shortcuts', {
            title: i18n.gettext('Shortcuts'),
            image: $("html").data("approot_images") + 'questionmark.png',
            onclick: function () {
                $("#editor-shortcuts-modal").modal();
            }
        });

                
        function myJbBox() {
            ed.windowManager.open({
                title: i18n.gettext('Upload picture or MP3 file'),
                file : 'imageUploadDialog',
                width : 350,
                height: 135,
                buttons: [{
                    text: 'Close',
                    onclick: 'close'
                }]
            });
        }
        
        ed.addButton('uploadsound', {
            tooltip: i18n.gettext('Upload picture or MP3 file'),
            image : $("html").data("approot_images") + 'volume.png',
            text: '',
            onclick: myJbBox
        });

                
        ed.on('init', function ()
        {
//            setTimeout(function () {
            try {
                var jed = $("#" + ed["id"]); // Jquery EDitor dom element
                if ($("html").data("loadedbyajax") == "false") {
//                        var ival = setInterval(function () {
                    if (jed.hasClass("init-focus")) {
                        jed.focus();
                        // clearInterval(ival);
                    }
//                        }, 100);
                } else {
//                        var ival = setInterval(function () {
                    if (jed.hasClass("init-focus")) {
                        jed.focus();
                        // clearInterval(ival);
                    }
//                        }, 10);
                }

                // show label on init, if editor is empty
                if (jed.html() === '<div>&#160;</div>') {
                    jed.siblings(".js-editor-label").show();
                }

//                    if (jed.text().replaceAll('&#160;','').replaceAll('&#65279;','').trim() == '') {
//                        ed.execCommand("fontSize", false, $("html").data("editorfontsize") + "px");
//                    } else {
//                        debug("Text in editor while initializing " + ed["id"] + " is: " + jed.text().replaceAll('&#160;','').replaceAll('&#65279;','').trim());
//                    }

            } catch (e) {
                debug("Failed to set focus.");
                var error = e.message;
                debug(error);
                sendError("TinyMCE init-error: init: " + error);
            }
//            }, 50);
        });

        // hide label on focus, if editor is empty
        ed.on('focus', function (e) {

            var jed = $("#" + ed["id"]); // Jquery EDitor dom element
            // if (jed.html() === '<div>&#160;</div>') {
            // jed.siblings
            // $(".js-editor-label").hide();
            if ($("#" + ed.id).hasClass("question-text")) {
                $(".question-label.js-editor-label").hide();
            } else if ($("#" + ed.id).hasClass("mc-option-text")) {
                $(".option-label.js-editor-label").hide();
            } else if ($("#" + ed.id).hasClass("answer-text")) {
                $(".answer-label.js-editor-label").hide();
            }
            // }

            // setTimeout needed, because panel not rendered yet! better way? callback/event?
            setTimeout(function () {
                try {
                    if (typeof ed.theme.panel !== 'undefined') {
                        var panelId = ed.theme.panel._id;

                        // add .responsive class to floatpanel for change "left" value for small screens
                        // if ($("html").hasClass("smaller-than-tablet")) {
                        // 	$("#"+panelId).addClass("responsive");
                        // }

                        // position panel correctly so it doesn't overlap card-top-text whose height might vary through multiple lines of categories
                        if (jed.hasClass("question-text") || jed.hasClass("answer-text")) {
                            var cardTopText = jed.closest(".card").find(".card-top-text");
                            var correctedMarginTop = -cardTopText.outerHeight();
                            $("#" + panelId).css("margin-top", correctedMarginTop);
                        }

                    }

                    var editorFontSize = $("html").data("editorfontsize");
                    var editorFontName = $("html").data("editorfontname");
                    var editorFontColor = $("html").data("editorfontcolor");
                    var viewFontSize = $("html").data("viewfontsize");

                    if (editorFontSize !== viewFontSize && jed.data("editorFontSizeSet") != true && jed.text().replaceAll('&#160;', '').replaceAll('&#65279;', '').trim() == '') {
                        debug("Editor font size is: " + editorFontSize + "; viewFontSize is: " + viewFontSize);
                        jed.data("editorFontSizeSet", true);
                        ed.execCommand("fontSize", false, editorFontSize + "px");
                    } else {
                        debug("Text in editor while initializing " + ed["id"] + " is: " + jed.text().replaceAll('&#160;', '').replaceAll('&#65279;', '').trim());
                    }

                    if (editorFontName !== "" && jed.data("editorFontNameSet") != true && jed.text().replaceAll('&#160;', '').replaceAll('&#65279;', '').trim() == '') {
                        ed.execCommand("fontName", false, editorFontName);
                        jed.data("editorFontNameSet", true);
                    }

                    if (editorFontColor !== "" && jed.data("editorFontColorSet") != true && jed.text().replaceAll('&#160;', '').replaceAll('&#65279;', '').trim() == '') {
                        debug("Setting editor font color to: " + editorFontColor);
                        ed.execCommand("foreColor", false, editorFontColor);
                        jed.data("editorFontColorSet", true);
                    }

                } catch (e) {
                    var error = e.message;
                    debug(error);
                    // sendError("TinyMCE init-error: focus: " + error);
                }
            }, 0);

        });

        // show label on blur, if editor is empty
//        ed.on('blur', function (e) {
//            var jed = $("#" + ed["id"]); // Jquery EDitor dom element
//            if (jed.html() === '<div>&#160;</div>')
//                jed.siblings(".js-editor-label").show();
//        });

        var cardEditorInputTimeout;
        function checkIfCardMaxSizeReached(editor) {
            try {
                if (cardEditorInputTimeout) {
                    clearTimeout(cardEditorInputTimeout);
                    cardEditorInputTimeout = null;
                }
                cardEditorInputTimeout = setTimeout(function () {
                    checkCardSize(editor)
                }, 750);
            } catch (e) {
                debug("Editor checkIfCardMaxSizeReached failed.");
            }
        }

        function checkCardSize(editor) {
            var tinymax, tinylen;
            tinymax = editor.settings.charLimit;
            if ($(".mce-edit-focus").hasClass("mc-option-text")) {
                var mChoiceData = [];
                $(".mce-edit-focus").closest(".mc-option-list").find(".choice-container").each(function (i) {
                    var mChoiceAnswerObj = Object();
                    var mcChecked = $(this).find(".mchoice-checkbox").prop('checked');
                    mChoiceAnswerObj.correct = mcChecked ? 1 : 0;
                    var text = $(this).find(".mc-option-text").html().trim();
                    debug("TEXT OF MCHOICE: " + text);
                    mChoiceAnswerObj.mChoiceAnswer = text;
                    mChoiceData.push(mChoiceAnswerObj);
                    // mchoiceData += "&mchoiceCorrect_" + (i + 1) + "=" + correct;
                });
                var jsonData = JSON.stringify(mChoiceData);
                debug(jsonData);
                tinylen = jsonData.length;
            } else {
                tinylen = editor.getContent().length;
            }
            debug("Length of content is: " + tinylen);
            debug("Maximum length would be: " + tinymax);
            if (tinylen > tinymax && !isUploadingImage) {
                debug(i18n.gettext("The maximum length of the content was exceeded!"));
                flashModal('<span style="color: red;">' + i18n.gettext("Warning: The maximum length of the content was exceeded.") + '</span>');
            }

//            var content = getContentOfOpenCardEditors(ed);
//            localStorage.setItem("editorContent", JSON.stringify(content));
        }

        ed.on('Change', function (e) {
            checkIfCardMaxSizeReached(this);
        });

        ed.on('KeyUp', function (e) {
            checkIfCardMaxSizeReached(this);
        });


        /*
         ed.on('KeyDown', function (e) {
         // $("#" + ed.id).prev().find(".js-editor-label").hide();
         
         if ($("#" + ed.id).hasClass("question-text")) {
         $(".question-label.js-editor-label").hide();
         } else if ($("#" + ed.id).hasClass("mc-option-text")) {
         $(".option-label.js-editor-label").hide();
         } else if ($("#" + ed.id).hasClass("answer-text")) {
         $(".answer-label.js-editor-label").hide();
         }
         
         // $(".js-editor-label").hide();
         });
         */

        // For finding ASCII codes for letters, go to https://ascii.cl/

        // The 77 is equivalent to m (or M)!
        ed.shortcuts.add('ctrl+77', 'add indendation', function () {
            ed.execCommand('Indent');
        });

        // The 77 is equivalent to m (or M)!
        ed.shortcuts.add('ctrl+shift+77', 'remove indendation', function () {
            ed.execCommand('Outdent');
        });

        // The 70 is equivalent to b (or B)!
        ed.shortcuts.add('ctrl+shift+66', 'make bold', function () {
            ed.execCommand('Bold');
        });

        // The 70 is equivalent to f (or F)!
        ed.shortcuts.add('ctrl+shift+70', 'make bold', function () {
            ed.execCommand('Bold');
        });

        // The 70 is equivalent to i (or I)!
        ed.shortcuts.add('ctrl+shift+73', 'make italic', function () {
            ed.execCommand('Italic');
        });
        
        ed.shortcuts.add('ctrl+shift+89', 'highlight', function() {
            console.log("### Highlighting");
            ed.execCommand('HiliteColor', false, "yellow");
        })

//        ed.shortcuts.add('shift+alt+39', 'add indendation', function() {
//           ed.execCommand('Indent');
//        });
//        
//        ed.shortcuts.add('shift+alt+37', 'remove indendation', function() {
//           ed.execCommand('Outdent');
//        });

        /**
         * This function enables changing the size of bullet points when changing the size of the content.
         */
        ed.on('ExecCommand', function checkListNodes(evt) {
            let cmd = evt.command;
            if (cmd === 'FontSize' || cmd === 'FontName') {
                let val = evt.value;
                let node = evt.target.selection.getNode();
                let nodeParent = node.parentNode;
                if (node.nodeName === 'SPAN' && nodeParent.nodeName === 'LI') {
                    if (cmd === 'FontSize') {
                        ed.dom.setStyle(nodeParent, 'font-size', val);
                    }
                    if (cmd === 'FontName') {
                        ed.dom.setStyle(nodeParent, 'font-family', val);
                    }
                } else if (node.nodeName === 'UL' || node.nodeName === 'OL') {
                    let li = ed.dom.select('li', node);
                    if (cmd === 'FontSize') {
                        ed.dom.setStyle(li, 'font-size', val);
                    }
                    if (cmd === 'FontName') {
                        ed.dom.setStyle(li, 'font-family', val);
                    }
                }
            }
        });

    } // end setupFn

    var setupFnNoCopy = function (ed) {
        setupFn(ed);
        ed.on('copy', function (e) {
            e.preventDefault();
            generalPurposeModal(i18n.gettext("For bought cardsets, copying is not allowed."));
        }, false);
        ed.on('cut', function (e) {
            e.preventDefault();
            generalPurposeModal(i18n.gettext("For bought cardsets, cutting is not allowed."));
        }, false);
        ed.on('dragstart', function (e) {
            e.preventDefault();
            generalPurposeModal(i18n.gettext("For bought cardsets, copying or cutting is not allowed."));
        }, false);
    };

    var returnObject = $.extend(true, {}, editorConf); // clone object, don't reference it!
    if (el.hasClass('disableCopying')) {
        returnObject["setup"] = setupFnNoCopy;
    } else {
        returnObject["setup"] = setupFn;
    }

    return returnObject;
}

function removeAllTinyMces(removeContent) {
    if (typeof removeContent === 'undefined') {
        removeContent = false;
    }
    // debug("Removing TinyMCEs: " + tinymce.editors.length);

    if (tinymce.editors.length > 0) {
        var numOfEditors = tinymce.editors.length;
        for (var i = numOfEditors - 1; i > -1; i--) {
            // debug("REMOVING TINYMCE " + i);
            var id = tinymce.editors[i].id;
            // debug("REMOVING TINYMCE " + i + " WHICH IS " + id);
            var editor = tinymce.editors[i];
            try {
                tinymce.EditorManager.execCommand('mceRemoveEditor', false, id);
//                if (removeContent) {
                // debug("And now...");
                $("#" + id).tinymce().remove();
                // Alternative:
                // $("#" + id).replaceWith($("#" + id).html());
//                }
//                tinymce.remove(editor);
//                tinymce.destroy(editor);
            } catch (e) {
                // var error = e.message;
                // debug(error);
                // sendError("TinyMCE init-error: removeAllTinyMces: " + error);
            }
        }
        tinymce.editors = [];
        if (typeof (CollectGarbage) == "function") {
            CollectGarbage();
        }
    } else {
        // debug("No TinyMCE to remove.");
    }
}

function removeTinyMcesInside(root) {
    root.find(".input.editable").each(function () {
        if ($(this).tinymce())
            $(this).tinymce().remove(); // remove all POTENTIAL tinymces, also ones from before ajax page load!
    });
    // root.find(".mce-content-body").tinymce().remove(); // remove all properly on this page initialized tinymces
}

// hide tinymce panel button
tinymce.PluginManager.add('close', function (editor, url) {
    editor.addButton('close', {
        text: '',
        icon: false,
        classes: 'close-panel-btn',
        tooltip: 'Hide toolbar',
        onclick: function () {
            try {
                editor.theme.panel.hide();
                setCookie("tinymcePanelVisibility", "hidden", 9999);
                $(".show-panel-btn").removeClass("hidden");
                reinitializeAllTinyMces();
            } catch (e) {
                debug("Closing menu bar of TinyMCE failed.");
                var error = e.message;
                debug(error);
                sendError("TinyMCE init-error: Close menu bar: " + error);
            }
        }
    });
});

function showTinymcePanel() {
    setCookie("tinymcePanelVisibility", "visible", 9999);
    $(".show-panel-btn").addClass("hidden");
    reinitializeAllTinyMces();
}

function reinitializeAllTinyMces() {
    debug("Reinit all TinyMCEs.");
    var currentTinyMces = $(".mce-content-body");
    removeAllTinyMces();
    currentTinyMces.each(function () {
        debug($(this));
        $(this).tinymce(editorConfig($(this)));
    });
}

function onFileChosen(event, callback) {
    //Detach any current submit handlers

    if ($("html").data("theme") != "beck-pl") {

        $("#media_form").unbind("submit");
        $("#media_form").submit(function (e) {

            e.stopPropagation(); // Stop stuff happening
            e.preventDefault(); // Totally stop stuff happening

            var numOfFiles = 0;

            //Prepare file in form for transmission via ajax call
            var formData = new FormData();
            $.each(event.target.files, function (i, file) {
                formData.append('media_file', file);
                numOfFiles++;
            });

            if (numOfFiles > 0) {
                //The url that will handle the file upload
                var url = "upload";

                flashModal(i18n.gettext("Upload started..."));

                //Do ajax call
                $.ajax({
                    type: "POST",
                    url: url,
                    data: formData,
                    cache: false,
                    contentType: false,
                    processData: false,
                    success: function (data)
                    {
                        //You can process the response however you want, but I chose to return a json string
                        var response = JSON.parse(data);

                        if (response.succeeded == true)
                        {
                            //This is the important part. This callback will tell TinyMCE the path of the uploaded image
                            debug("The upload was successful, and the path to the image is: " + response.newPath);
                            callback(response.newPath);
                        } else {
                            // alert("The image upload was not successful. Reason:" + response.status);
                            flashModal(response.message);
                        }
                    }
                });
            } else {
                flashModal(i18n.gettext("Error while selecting the file."));
                sendError("Fehler bei der Auswahl der Datei. Gefundene Dateien: " + numOfFiles + ", Event: " + JSON.stringify(event));
            }

        });
        $("#media_form").submit();

    }

}