/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

function embedCardboxModal() {
    
    $("#general-purpose-modal").modal();
    
    var htmlString = "<p class=\"modal-hl\"> \
        <i class=\"fa fa-code\"></i> \
        <span>" + i18n.gettext("Embed") + "</span> \
    </p> \
    <p class=\"intro-text\"> \
        " + i18n.gettext("Use the following HTML code to embed the cardset into other websites.") + "\
        " + i18n.gettext("The dimensions can be adapted arbitrarily.") + " \
    </p>\n\
    <pre>&lt;iframe src=\"" + $("html").data("approot") + "cardset" + "-" + $("#boxId").val() + "\" height=\"400\" width=\"400\" style=\"padding:4px;background-color:#007356;\"&gt;&lt;/iframe&gt;</pre>";
    
    $("#general-purpose-modal-content").html(htmlString);
    
    
}
