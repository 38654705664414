/* DELETE CARD MODAL */

function deleteCard(boxId,cardId) {
    flashModal(i18n.gettext('Deleting card...'));
    $.get( "cardset-cards-" + boxId + '?delete=1&cardId=' + cardId, function(data) {
        loadPageByAjax("cardset-cards-" + boxId);
        flashModal(data);
    });
}

function unDeleteCard() {
    var boxId = $('#boxId').val();
    var cardId = $('#cardId').val();
    flashModal(i18n.gettext('Restoring card...'));
    $.get( "kartensatz-karten-" + boxId + '?undelete=1&cardId=' + cardId, function(data) {
        flashModal(data);
        loadPageToCenter("karte-" + cardId);
    });
}

// open modal btn
function deleteCardModal() {
    $("#delete-card-modal").modal();
}

function deleteCardModalAction() {
    // BESSER MIT AJAX MACHEN, NICHT NEU LADEN, AUF SEITE BLEIBEN UND MODAL ZEIGEN!
    // NACH DEM LÖSCHEN SOLLTE MAN ZUR NÄCHSTEN KARTE GELANGEN!?
    var boxId = $('#boxId').val();
    var cardId = $('#cardId').val();
    deleteCard(boxId,cardId);
}

