/* SHOPPING CART BAR */

$(document).ready(function () { // doc ready, because needs var from nicescroll.js file
	scbNiceScrollInit();
});

function scbNiceScrollInit() {
	// scrolling like Facebook activity bar
	// debug('init shopping cart bar nicescroll');
	if (window.jQuery && $.fn.niceScroll !== undefined && $(".js-shopping-cart-bar").length) {
		$(".js-shopping-cart-bar-content").niceScroll(niceScrollConfig);
	}
}

function showShoppingCartBar() {
	$(".js-shopping-cart-bar").show();
}

function hideShoppingCartBar() {
	$(".js-shopping-cart-bar").hide();
	$(".js-show-shopping-cart-bar-btn").show();
}
