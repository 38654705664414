/* BUY REPETICO PRO BUTTONS */
function upgradeBtnClick(el) {
    var url = getUrlHash();
    var buyAsCouponCode = false;

    if (url == 'propresent') {
        buyAsCouponCode = true;
    }

    var product = $(el).data("product");
    if (product == "undefined" || product == "") {
        product = "pro";
    }

    var months = $(el).data("months");

    $.ajax({
        type: "POST",
        url: "shoppingcart",
        data: "addItem=" + product + "&itemId=" + months + "&itemNumber=" + 1 + "&itemMonths=" + months,
        success: function (data) {
            if ($("html").data("loggedin") == "1") {
                loadPageByAjax('checkout?buyAsCouponCode=' + buyAsCouponCode);
            } else {
                window.location.href = 'register_or_login';
            }
        }
    });

    /*
    $.ajax({
        type: "POST",
        url: 'checkout',
        data: 'upgrade=' + product + '&months=' + $(el).data("months") + "&buyAsCouponCode=" + buyAsCouponCode,
        success: function (data) {
            if ($("html").data("loggedin") == "1") {
                loadPageByAjax('checkout?buyAsCouponCode=' + buyAsCouponCode);
            } else {
                window.location.href = 'register_or_login';
            }
        }
    });
    */
}

function startTrialRepeticoCourses() {
    $.ajax({
        type: "POST",
        url: "upgrade-courses",
        data: "startTrial=1",
        success: function (data) {
            var response = JSON.parse(data);
            flashModal(response.message);
            if (response.success) {
                loadPageByAjax("courses");
            }
        }
    });
}