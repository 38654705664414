function billUploadFileChange(el) {
    var error = false;

    for (var i = 0; i < el.files.length; i++) {
        // get item
        // file = this.files.item(i);
        //or
        file = el.files[i];

        // var file = this.files[0];

        name = file.name;
        size = file.size;
        type = file.type;

        if (file.name.length < 1) {
            flashModal(i18n.gettext("No filename provided."));
            error = true;
        }
        else if (file.size > 10000000) {
            flashModal(i18n.gettext("The file is too large."));
            error = true;
        }

    }

    if (!error) {

        var fd = new FormData(document.getElementById("uploadBillsForm"));

        $.ajax({
            url: 'alpmann-apps?do=upload', //server script to process data
            type: 'POST',
            xhr: function () {  // custom xhr

                myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) { // if upload property exists
                    // myXhr.upload.addEventListener('progress', progressHandlingFunction, false); // progressbar
                }
                return myXhr;
            },
            //Ajax events
            success: completeHandler = function (data) {

                /*
                 * workaround for chrome browser // delete the fakepath
                 */
                if (navigator.userAgent.indexOf('Chrome')) {
                    var catchFile = $(":file").val().replace(/C:\\fakepath\\/i, '');
                }
                else {
                    var catchFile = $(":file").val();
                }

                // load module

                $('#uploadedFiles').append(data);
                $('#finishBlock').show();
                window.setTimeout(function() {
                    $('html, body').animate({scrollTop: $(document).height()}, 'slow');
                },0);

            },
            error: errorHandler = function () {
                flashModal(i18n.gettext("Error while processing the upload."));
            },
            data: fd,
            cache: false,
            contentType: false,
            processData: false
        }, 'json');

    }

}



function convertAlpmannStandalone() {
    debug("Submit checked boxes");
    var ids = [];
    $('input.bought-apps:checked').each(function(){
        var id = $(this).data('id');
        ids.push(id);
    });
    $.ajax({
        type: "POST",
        url: "alpmann-apps?do=saveBoxIds",
        data: "ids=" + ids,
        success: function(data){
            if (data == 1) {
            $('.static-content').html('<br/><br/>'
                + '<p class="module-hl">'
                + '<i class="fa fa-flag"></i>'
                + i18n.gettext("Thank you! Your input is being checked.")
                + '</p>'
                + 'Bei Richtigkeit Ihrer Angaben werden die Kartens&auml;tze in Ihren Account hier bei Repetico geladen.'
                + sprintf('Sobald dies geschehen ist, werden Sie die %sRepetico-App%s zum mobilen Lernen Ihrer Kartens&auml;tze nutzen k&ouml;nnen.'),'<a href="' + i18n.gettext("flashcard-app") + ')" class="bright-link click">','</a>');
            } else {
                flashModal(i18n.gettext('Error while saving the form data'));
            }
        },
        error: function() {
            flashModal(i18n.gettext('Error while submitting the form data'));
        }
    });
}



function convertAlpmannAppToBox(el) {
    var userId = $(el).data('userid');
    var boxId = $(el).data('boxid');
    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "alpmann-apps-admin?do=copyCardbox",
        data: "userId=" + userId + "&boxId=" + boxId + "&nonce=" + nonce,
        success: function(data){
            flashModal(i18n.gettext('Finished.'));
            loadPageToCenter('alpmann-apps-admin');
        },
        error: function() {
            flashModal(i18n.gettext('Error.'));
        }
    });
}



// REMOVE THIS & UPDATE ELEMENTS IN DB WITH onclick="getBillOfUser(this)"
$(document).on('click','.getBillOfUser', function () {
    var userId = $(this).data('userid');
    var fileName = $(this).data('filename');
    $.ajax({
        type: "POST",
        url: "alpmann-apps-admin?do=getBill",
        data: "userId=" + userId + "&fileName=" + fileName,
        dataType: "binary",
        processData: false,
        success: function(data){

        },
        error: function() {
            flashModal('Fehler.');
        }
    });
});

function getBillOfUser(el) {
    var userId = $(el).data('userid');
    var fileName = $(el).data('filename');
    $.ajax({
        type: "POST",
        url: "alpmann-apps-admin?do=getBill",
        data: "userId=" + userId + "&fileName=" + fileName,
        dataType: "binary",
        processData: false,
        success: function(data){

        },
        error: function() {
            flashModal('Fehler.');
        }
    });
}
