function searchPageFormInputKeypress(e) {
    if (e.keyCode == 13) {
        searchPageFormSearch();
    }
}

function searchPageFormSearch() {
    var searchtype = $('#searchPageForm input[name="searchtype"]:checked').val();
    
    $("#card-container .card-modules").html('');
    $(".user-display-container").html('');
    $("#cardSetSearchResult").html('');
    $("#searchResultCourses").html('');
    $("#searchResultDiscussions").html('');
    
    var text = encodeURIComponent($("#searchPageForm .search-input").val());
    
    debug("Searchtype is: " + searchtype);
    debug("Text is: " + text);
    
    if (text != '') {
        if (searchtype == 'people') {
            searchPersons(0, 21);
            $("#searchResultPersons").show();
            showAjaxLoader(".user-display-container");
        } else {
            $("#searchResultPersons").hide();
        }
        if (searchtype == 'cards') {
            searchCards(0, 10);
            $("#searchResultCards").show();
            showAjaxLoader("#card-container .card-modules");
        } else {
            $("#searchResultCards").hide();
        }
        if (searchtype == 'cardsets') {
            loadPageByAjax('shop?searchTerm=' + text);
        } else {
            $("#searchResultCardSets").hide();
        }
        if (searchtype == 'courses') {
            searchCourses(0, 20);
            $("#searchResultCourses").show();
            showAjaxLoader("#searchResultCourses");
        } else {
            $("#searchResultCourses").hide();
        }
        if (searchtype == 'discussions') {
            searchDiscussions(0, 10);
            $("#searchResultDiscussions").show();
            showAjaxLoader("#searchResultDiscussions");
        } else {
            $("#searchResultDiscussions").hide();
        }
    } else {
        $("#searchResultPersons").hide();
        $("#searchResultCards").hide();
        $("#searchResultCardSets").hide();
        $("#searchResultCourses").hide();
        $("#searchResultDiscussions").hide();
    }
    
    // window.history.pushState(null, null, "search?text=" + text + "&searchtype=" + searchtype);
}

function searchCards() {
    var text = encodeURIComponent($("#searchPageForm .search-input").val());
    var cardsSearchOffset = parseInt($("#cardsSearchOffset").val());
    $.ajax({
        type: "POST",
        url: 'search',
        data: 'text=' + text + '&searchtype=cards&module=search&offset=' + cardsSearchOffset
    }).done(function (data) {
        $("#searchresults").html(data);
//        cardsSearchOffset = cardsSearchOffset + 10;
//        $("#cardsSearchOffset").val(cardsSearchOffset);
//        if (data.trim() != '') {
//            searchCards(text);
//        } else {
//            $("#cardsSearchOffset").val(0);
//        }
    });

}

function searchPersons(offset, limit) {
    var text = encodeURIComponent($("#searchPageForm .search-input").val());
    $.ajax({
        type: "POST",
        url: 'search',
        data: 'text=' + text + '&searchtype=people&module=search&offset=' + offset + '&limit=' + limit
    }).done(function (data) {
        $("#searchresults").html(data);
    });
}

function searchBoxes(offset, limit) {
    var text = encodeURIComponent($("#searchPageForm .search-input").val());
    var tagId = $("#searchPageForm #tagId").val();
    if (!isNaN(parseFloat(tagId)) && isFinite(tagId)) {
        debug("Tag ID is numeric: " + tagId);
        searchBoxesByTag(offset, limit);
    } else {
        $.ajax({
            type: "POST",
            url: 'search',
            data: 'text=' + text + '&searchtype=cardsets&module=search&offset=' + offset + '&limit=' + limit
        }).done(function (data) {
            $("#searchresults").html(data);
        });
    }
}

function searchCourses(offset, limit) {
    var text = encodeURIComponent($("#searchPageForm .search-input").val());
    $.ajax({
        type: "POST",
        url: 'search',
        data: 'text=' + text + '&searchtype=courses&module=search&offset=' + offset + '&limit=' + limit
    }).done(function (data) {
        $("#searchresults").html(data);
    });
}

function searchDiscussions(offset, limit) {
    var text = encodeURIComponent($("#searchPageForm .search-input").val());
    $.ajax({
        type: "POST",
        url: 'search',
        data: 'text=' + text + '&searchtype=discussions&module=search&offset=' + offset + '&limit=' + limit
    }).done(function (data) {
        $("#searchresults").html(data);
    });
}

function searchBoxesByTag(offset, limit) {
    var tagId = $("#searchPageForm #tagId").val();
    $.ajax({
        type: "POST",
        url: 'search',
        data: 'tagId=' + tagId + '&findBoxes=1' + '&offset=' + offset + '&limit=' + limit
    }).done(function (data) {
        $("#searchresults").html(data);
    });
}

function friendSearch() {
    var text = encodeURIComponent($('.js-search-friends-input').val());
    loadPageByAjax('search?text=' + text + '&searchtype=people');
}

function findCourse() {
    var text = encodeURIComponent($('#findCourse').val());
    loadPageByAjax('search?text=' + text + '&searchtype=courses');
}

function findCourseKeyPress(e) {
    if (e.keyCode == 13) {
        findCourse();
    }
}