function loadLegalisDoc(el) {

    var parprefix = $(el).data('parprefix');
    var artref = $(el).data('artref');
    var parref = $(el).data('parref');
    var pktref = $(el).data('pktref');
    var abrev = $(el).data('abrev');

    var urlBase = 'legalis';
    if (parref == '' && pktref == '') {
        var urlData = 'parprefix=' + parprefix + '&abrev=' + abrev + '&artref=' + artref;
    } else {
        var urlData = 'parprefix=' + parprefix + '&abrev=' + abrev + '&artref=' + artref + '&parref=' + parref + '&pktref=' + pktref;
    }

    // $("body").css("cursor", "progress");

    $("#general-purpose-modal").modal();
    
    $("#general-purpose-modal .expandModalButton").show();
    
    var header = '<div><img style="width: 268px; height: auto;" src="https://d2wg98g6yh9seo.cloudfront.net/static/beck-pl/legalis.png"></div>';
    $("#general-purpose-modal-content").html(header);
    showAjaxLoader("#general-purpose-modal-content");

    $.ajax({
        type: "GET",
        url: urlBase,
        data: urlData
    }).done(function (data) {
        debug("Legalis output: " + data);
        
        $("#general-purpose-modal-content").html(header + data);
        
        $("#legalis-content").css("height", "350px");

        setTimeout(function () {
            var scrollTo = $("#legalis-content").data("scrollto");
            if (scrollTo != '') {
                var scrollTo = 'mip' + scrollTo;
                debug("Scroll to: " + scrollTo);
                //scroll to an element like $("a[name=mip41469934]")
                // $("a[name=" + scrollTo + "]").scrollintoview();
                var target = $("a[name=" + scrollTo + "]");
                var scrollValue = target.offset().top - $("#legalis-content").offset().top;
                debug("scrollValue: " + scrollValue);
                $("#legalis-content").animate({scrollTop: scrollValue}, "slow");
            } else {
                $("#legalis-content").animate({scrollTop: 0}, "slow");
            }
        }, 10);

    }).fail(function (xhr, status) {
        alert(i18n.gettext("Error while accessing Legalis."));
    }).complete(function (xhr, status) {
        // $("body").css("cursor", "default");
    });

    return false;
}

