$(document).ready(function() {

  	createMailtoLinks();

}); // end document ready

function createMailtoLinks() {
	$(".mailto").each(function() {
		var mailtoLink = $(this).data("name")+"@"+$(this).data("host")+"."+$(this).data("tld");
		$(this).attr("href","mailto:"+mailtoLink);
		$(this).html(mailtoLink);
	});
}