function folderBtnClick(el) {
    var mystr = $(el).attr('id');
    var myarr = mystr.split("-");
    if (myarr.length > 1) {
        var folderId = myarr[1];
        var selectedOrder = $('.js-orderOfCardboxes').val();
        $('.page-container-inner').fadeTo(400, 0.3);
        $.ajax({
            type: "POST",
            url: "kartensaetze",
            data: "module=kartensaetze&folderId=" + folderId + "&orderOfCardboxes=" + selectedOrder,
            success: function (data) {
                $(".page-container-inner").html(data);
                $(".page-container-inner").fadeTo(400, 1.0);

                // if (index===cardsetsContainerLength-1) {
                loadStatistics();
                // loadColearners();
                // }

            } // end success
        }); // end ajax
    } else {
        var selectedOrder = $('.js-orderOfCardboxes').val();
        $('.page-container-inner').fadeTo(400, 0.3);
        $.ajax({
            type: "POST",
            url: "kartensaetze",
            data: "module=kartensaetze&orderOfCardboxes=" + selectedOrder,
            success: function (data) {
                $(".page-container-inner").html(data);
                $(".page-container-inner").fadeTo(400, 1.0);

                // if (index===cardsetsContainerLength-1) {
                loadStatistics();
                // loadColearners();
                // }

            } // end success
        }); // end ajax
    }
}

function updateFolder(el) {
    var todo;
    var doing;
    if ($(el).attr('id') != null) {
        var mystr = $(el).attr('id');
        var myarr = mystr.split("-");
        var folderId = myarr[1];
        doing = "rename";
        todo = "do=rename&key=" + folderId + "&folder=" + encodeURIComponent($(el).val());
    } else {
        doing = "create";
        todo = "do=create" + "&folder=" + encodeURIComponent($(el).val());
    }
    $('#manageFoldersRefreshArea').fadeTo(400, 0.3);
    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "manage-folders",
        data: "module=manage-folders&" + todo + "&nonce=" + nonce,
        success: function (data) {
            if (doing == "create") {
                $('#manageFoldersRefreshArea').html(data);
            } else if (doing == "rename") {
                var obj = JSON.parse(data);
                shortInfo(obj.message);
            }
            $('#manageFoldersRefreshArea').fadeTo(400, 1.0);
        }
    });
}

function assignFolder(el) {
    var folderId = $(el).val();
    var mystr = $(el).attr('id');
    var myarr = mystr.split("-");
    var boxId = myarr[1];
    var todo = "do=assign&boxId=" + boxId + "&folderId=" + folderId;
    $(el).fadeTo(400, 0.3);
    $.ajax({
        type: "POST",
        url: "manage-folders",
        data: "module=cardset-folder-list-column&" + todo,
        success: function (data) {
            $(el).fadeTo(400, 1.0);
        }
    });
}

function addFolderBtnClick(el) {
    $(el).parent().before(' \
		<div class="input-x w100"> \
    		<input type="text" class="w100" tabindex="20" onblur="updateFolder(this)" onkeypress="newFolderKeyPress(event);"> \
			<button class="remove-btn" onclick="removeFolderBtnClick(this)"> \
				<i class="fa fa-minus-square-o"></i> \
			</button> \
		</div> \
	');
    $(".folder-list-column").find("input:last").focus();
}

function removeFolderBtnClick(el) {
    var mystr = $(el).attr('id');
    var todo = '';
    if (typeof mystr !== 'undefined') {
        var myarr = mystr.split("-");
        var folderId = myarr[1];
        todo = "&do=delete" + "&folderId=" + folderId;
    }
    var r = confirm(i18n.gettext("Delete really?"));
    if (r == true) {
        // $('.cardset-folder-list-column').fadeTo(400, 0.3);
        $("#folderBlock-" + folderId).fadeOut();
        $.ajax({
            type: "POST",
            url: "manage-folders",
            data: "module=cardset-folder-list-column" + todo,
            success: function (data) {
                $("#folderBlock-" + folderId).remove();
                // $("#folderBlock-" + folderId).fadeOut();
                // $('.cardset-folder-list-column').html(data);
                // $('.cardset-folder-list-column').fadeTo(400, 1.0);
            }
        });
    }
}

function setCardsetStudyStage(boxId, studyStage) {
    debug("Setting study stage of box " + boxId + " to: " + studyStage);
    $.ajax({
        type: "POST",
        url: "cardsets-by-uni",
        data: "boxId=" + boxId + "&studyStage=" + studyStage,
    }).done(function(data) {
        shortInfo(i18n.gettext("Changed study stage successfully."));
    }).fail(function() {
        shortInfo("Setting area failed.");
    });
}

function setCardsetArea(boxId, area) {
    debug("Setting area of box " + boxId + " to: " + area);
    $.ajax({
        type: "POST",
        url: "cardsets-by-uni",
        data: "boxId=" + boxId + "&area=" + area,
    }).done(function(data) {
        shortInfo(i18n.gettext("Changed area of subject successfully."));
    }).fail(function() {
        shortInfo("Setting area failed.");
    });
}