// KARTENSATZ INFO

// EDITABLE CARD SET TITLE
function editCardsetTitle() {
    $(".page-headline.input").attr("contenteditable", true);
    setTimeout(function () {
        $(".page-headline.input").focus();
    }, 0);
}

function cardsetTitleFocus() {
    $(document).on("keypress.editheadline", function (e) {
        if (e.which == 13) {
            e.preventDefault();
            setTimeout(function () {
                $(".page-headline.input").blur();
            }, 0);
            $(document).off("keypress.editheadline");
        }
    });
    $(".edit-cardset-title-btn").hide();
}

function cardsetTitleBlur() {
    $(document).off("keypress.editheadline");
    $(".edit-cardset-title-btn").show();
    $(".page-headline.input").attr("contenteditable", false);

    var mystr = $(".page-headline").attr('id');
    var myarr = mystr.split("-");
    var boxId = myarr[1];

    var option = 'title';
    var value = encodeURIComponent($(".page-headline.input").html());

    $.ajax({
        type: "POST",
        url: "confsaver",
        data: "cardboxOption=" + option + "&value=" + value + "&boxId=" + boxId,
        success: function (data) {
            shortInfo(i18n.gettext("Renamed cardset."));
        }
    });

}

// EDITABLE CARD SET DESCRIPTION
function editCardsetDescriptionBtnClick() {
    removeAllTinyMces();
    $(".description-text").tinymce(editorConfig($(".description-text")));
    $(".edit-cardset-description-btn").hide();
    $(".save-edit-description-btn").show();
    $(".cancel-edit-description-btn").show();
}

function cardsetDescriptionFocus() {
    $(".edit-cardset-description-btn").hide();
    $(".save-edit-description-btn").show();
    $(".cancel-edit-description-btn").show();
}

function saveCardsetDescriptionBtnClick() {
    $(".save-edit-description-btn").hide();
    $(".cancel-edit-description-btn").hide();
    $(".edit-cardset-description-btn").show();

    var mystr = $(".save-edit-description-btn").attr('id');
    var myarr = mystr.split("-");
    var boxId = myarr[1];

    var valueString;

    var option = 'description';
    var value = encodeURIComponent($('#descriptionEditor-' + boxId).html());
    valueString = "&value=" + value;
    if ($('#mobileDescriptionEditor-' + boxId).length > 0) {
        var mobileDescription = encodeURIComponent($('#mobileDescriptionEditor-' + boxId).html());
        valueString += "&mobileDescription=" + mobileDescription;
    }

    $('#descriptionEditor-' + boxId).fadeTo(400, 0.3);
    if ($('#mobileDescriptionEditor-' + boxId).length > 0) {
        $('#mobileDescriptionEditor-' + boxId).fadeTo(400, 0.3);
    }

    removeAllTinyMces();

    $.ajax({
        type: "POST",
        url: "confsaver",
        data: "cardboxOption=" + option + valueString + "&boxId=" + boxId,
        context: this,
        success: function (data) {
            $('#descriptionEditor-' + boxId).fadeTo(400, 1.0);
            if ($('#mobileDescriptionEditor-' + boxId).length > 0) {
                $('#mobileDescriptionEditor-' + boxId).fadeTo(400, 1.0);
            }
        }
    });

}

function cancelEditDescriptionBtnClick() {
    $(".cancel-edit-description-btn").hide();
    $(".save-edit-description-btn").hide();
    $(".edit-cardset-description-btn").show();
    removeAllTinyMces();
}

//  EDIT CARD SET RIGHTS
function editCardsetRightsBtnClick(el) {
    $(".rights").find(".info-value").hide();
    $(".rights").find(".select-style").show();
    $(el).hide();
    $(".js-hide-cardset-rights-btn").show();
}

function hideCardsetRightsBtnClick(el) {
    $(".rights").find(".info-value").show();
    $(".rights").find(".select-style").hide();
    $(el).hide();
    $(".js-edit-cardset-rights-btn").show();
}

function changeCardboxRights(el) {
    var id = $(el).attr('id');
    if (id != null) {
        var myarr = id.split("-");
        var option = myarr[0];
        var boxId = myarr[1];
        var value = $(el).val();
        $.ajax({
            type: "POST",
            url: "confsaver",
            data: "cardboxOption=" + option + "&value=" + value + "&boxId=" + boxId,
            success: function (message) {
                $("#" + option + "-info-value").html($('#' + id + ' option:selected').html());
                if (message.trim() != "") {
                    var json = JSON.parse(message);
                    shortInfo(json.message);
                    if (json.success == false) {
                        $('#' + id).val(json.object[option]);
                        $("#" + option + "-info-value").html($('#' + id + ' option:selected').html());
                    }
                }
            }
        });
    }
}
