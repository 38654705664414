/* SEARCH MODAL */

function searchModal() {
    $("#search-modal").modal();
    setTimeout(function () {
        $("#search-modal").find(".search-input").focus();
    }, 0);
}

function searchModalInputKeypress(e) {
    if (e.keyCode == 13) {
        searchModalAction();
    }
}

function searchFriendsInputKeypress(e) {
    if (e.keyCode == 13) {
        friendSearch();
    }
}

function searchModalAction() {
    var text = encodeURIComponent($("#search-modal .search-input").val());
    if (text.length < 3) {
        $("#search-modal .searchModalMessage").html(i18n.gettext("Search term too short. Minimum is 3 characters."));
    } else {
        closeModal();
        $("#search-modal .searchModalMessage").html('');
        var searchtype = $('#search-modal input[name="modalsearchtype"]:checked').val();
        if (searchtype == "cardsets") {
            loadPageByAjax('shop?searchTerm=' + text);
        } else {
            loadPageByAjax('search?text=' + text + '&searchtype=' + searchtype);
        }
    }
}
