function moveCardsModal() {
    var sourceBoxId = $("#boxId").val();
    $("#move-cards-modal").modal();
    $.ajax({
        type: "GET",
        url: "main",
        data: "getActiveCardboxes=1&subset=all&addAllowed=1",
        success: function (data) {
            var boxes = JSON.parse(data.trim());
            var optionsHtml = '';
            var selectedString;
            $.each(boxes, function (index, box) {
                // debug("cardBoxNbr: " + box.cardBoxNbr + ", cardBoxName: " + box.cardBoxName);
                if (sourceBoxId == box.cardBoxNbr) {
                    selectedString = ' selected="selected"';
                } else {
                    selectedString = '';
                }
                optionsHtml += '<option value="' + box.cardBoxNbr + '"' + selectedString + '>' + box.cardBoxName + '</option>';
            });
            $("#move-cards-modal #target-cardset").html(optionsHtml);
        }
    });
}

function copyCardsModal() {
    var sourceBoxId = $("#boxId").val();
    $("#copy-cards-modal").modal();
    $.ajax({
        type: "GET",
        url: "main",
        data: "getActiveCardboxes=1&subset=all&addAllowed=1",
        success: function (data) {
            var boxes = JSON.parse(data.trim());
            var optionsHtml = '';
            var selectedString;
            $.each(boxes, function (index, box) {
                // debug("cardBoxNbr: " + box.cardBoxNbr + ", cardBoxName: " + box.cardBoxName);
                if (sourceBoxId == box.cardBoxNbr) {
                    selectedString = ' selected="selected"';
                } else {
                    selectedString = '';
                }
                optionsHtml += '<option value="' + box.cardBoxNbr + '"' + selectedString + '>' + box.cardBoxName + '</option>';
            });
            $("#copy-cards-modal #target-cardset").html(optionsHtml);
        }
    });
}

function copyCardsModalAction() {
    moveCardsModalAction(true);
}

function moveCardsModalAction(onlyCopy) {

    var sourceBoxId = $("#boxId").val();

    if (typeof onlyCopy === 'undefined') {
        onlyCopy = false;
    }

    var targetBoxId;
    var orderIndex;
    var createCategories;

    if (onlyCopy) {
        targetBoxId = $("#copy-cards-modal #target-cardset").val();
        orderIndex = $("#copy-cards-modal #orderIndexModalInput").val();
        createCategories = $("#copy-cards-modal #copyCardsCreateCategories").is(":checked");
        flashModal(i18n.gettext('Copying...'));
    } else {
        targetBoxId = $("#move-cards-modal #target-cardset").val();
        orderIndex = $("#move-cards-modal #orderIndexModalInput").val();
        createCategories = $("#move-cards-modal #moveCardsCreateCategories").is(":checked");
        flashModal(i18n.gettext('Moving...'));
    }

    var items = getSelectedCardIdsAsArray();
    var numOfItems = items.length;

    // first: check if card can be added in other cardset

    if (numOfItems > 0) {

        $.ajax({
            type: "GET",
            url: "kartensatz-karten-" + targetBoxId,
            data: "getCreateCardInfo=1",
            success: function (data) {
//            try {
                var obj = JSON.parse(data.trim());

                $("#numOfCreatedCards").val(parseInt(obj.numOfCreatedCards));
                $("#numOfCardsPerBox").val(parseInt(obj.numOfCardsPerBox));
                $("#proUser").val(obj.proUser);

                // TODO also check if adding of cards is allowed for user.

                if ((parseInt(obj.numOfCreatedCards) + numOfItems) > parseInt(obj.numOfCardsPerBox)) {

                    // - Falls ja und FREE-Status, repeticoPromptModal('Mit Repetico PRO unbegrenzt viele Kartens&auml;tze anlegen!');
                    // - Falls ja und PRO-Status, flashModal('Maximale Anzahl erreicht!');
                    if (obj.proUser) {
                        flashModal(i18n.gettext('Maximum number of cards per cardset reached.')
                                + ' '
                                + i18n.gettext('Cards in trash are counted as well.'));
                    } else {
                        repeticoPromptModal(i18n.gettext('Maximum number of cards per cardset for FREE-users reached.')
                                + ' '
                                + i18n.gettext('Cards in trash are counted as well.')
                                + '<br/><br/>'
                                + sprintf(i18n.gettext('Create up to 2000 cards per box with %s!'), sprintf('<span style="font-weight:bold;">%s PRO</span>', $("html").data("apptitle"))));
                    }

                } else {

                    var action = '';
                    if (onlyCopy) {
                        action = 'copyCardsToBox';
                    } else {
                        action = 'moveCardsToBox';
                    }

                    $.ajax({
                        type: "POST",
                        url: "kartensatz-karten-" + sourceBoxId,
                        data: action + "=" + targetBoxId + "&orderIndex=" + orderIndex + "&createCategories=" + createCategories + "&cards=" + items,
                        success: function (data) {
//                            try {
                            var obj = JSON.parse(data.trim());
                            flashModal(obj.message);
                            if (obj.success === true) {
                                if (targetBoxId != sourceBoxId) {
                                    loadPageByAjax("cardset-cards-" + targetBoxId);
                                } else {
                                    loadAllCards();
                                }
                            }
//                            } catch (e) {
//                                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                                return;
//                            }
                        },
                        error: function () {
                            if (onlyCopy) {
                                flashModal(i18n.gettext('Error while copying'));
                            } else {
                                flashModal(i18n.gettext('Error while moving'));
                            }
                        }
                    });

                }
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }

            }

        });

    }

}

function moveCardsToIndexModal() {
    $("#move-cards-to-index-modal").modal();
}

function moveCardsToIndexModalAction() {
    var sourceBoxId = $("#boxId").val();
    var index = $("#move-cards-to-index-modal #orderIndexModalInput").val();
    var items = getSelectedCardIdsAsArray();

    flashModal(i18n.gettext('Moving to index...'), 200000);

    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + sourceBoxId,
        data: "moveCardsToIndex=" + index + "&cards=" + items,
        success: function (data) {
            flashModal(i18n.gettext('Finished.'));
            loadAllCards();
        }
    });
}