/* RECOMMEND CARDBOX MODAL */
function recommendCardboxModal(el) {
    // load information into modal
    var boxId = $(el).data('id');
    var currency = "";
    if ($("#currency").length > 0) {
        currency = $("#currency").val();
    }
    
    $.ajax({
        type: "POST",
        url: "recommend-cardbox",
        data: "boxId=" + boxId,
        success: function (data) {
//            try {
                var obj = JSON.parse(data.trim());
                if (obj.hasOwnProperty('merchantId') && obj.recDef != null) {
                    var rewardStr = i18n.gettext("You get for each resulting purchase") + " ";
                    if (obj.recDef.proMonths !== 0) {
                        rewardStr += "<strong>" + obj.recDef.proMonths;
                        var months = (obj.recDef.proMonths == 1 ? i18n.gettext("Month") : i18n.gettext("Months"));
                        rewardStr += " " + months + " " + sprintf(i18n.gettext("%s PRO"),'Repetico') + ".</strong>";
                    }
                    if (obj.recDef.rebate > 0) {
                        var rebate = obj.recDef.rebate.toFixed(2);
                        rewardStr += sprintf(i18n.gettext("a <strong>rebate of %s</strong> for purchases of any cardsets of the publisher %s."), rebate + currency, obj.merchantName);
                    }
                    $(".js-recommendation-reward").html(rewardStr);
                }
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        }
    }, 0);

    $("#recommend-cardbox-modal").modal();
    setTimeout(function () {
        $("#recommend-cardbox-modal").find("input:first").focus();
    }, 0);
}

function recommendCardboxModalAddPerson(el) {
    $(el).parent().before(' \
			<div class="input-x w100"> \
	    		<input type="text" class="js-recommend-person w100" tabindex="20"> \
    			<button class="remove-btn" type="button" onclick="removeInputX(this)"> \
    				<i class="fa fa-minus-square-o"></i> \
    			</button> \
    		</div> \
		');
    $(".modal").find(".js-recommend-person:last").focus();
}

function recommendCardboxModalAction(el) {
    // var recipient = $('#recommend-cardbox-modal .recipient').val();
    var recipients = new Array();
    $('#recommend-cardbox-modal .js-recommend-person').each(function () {
        recipients.push($(this).val());
    });
    var sendToAllFriends = $(".js-recommend-all-friends").is(":checked");
    var message = $('#recommend-cardbox-modal .js-recommendation-text').html();
    var id = $(el).data("id");
    flashModal(i18n.gettext('Sending recommendation...'));
    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + id,
        data: "recommendTo=" + recipients + "&message=" + message + "&sendToAllFriends=" + sendToAllFriends,
        success: function (data) {
            setTimeout(function () {
                $('#recommend-cardbox-modal').find(".input-x input").val('');
                $("#recommend-cardbox-modal").find(".input-x:gt(0)").remove();
                $('#recommend-cardbox-modal').find(".js-recommendation-text").val('');
            }, 200);
            flashModal(data);
        }
    });
}
