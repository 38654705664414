$(document).ready(function () {
    if ($(".kartensatz-block .progress-bar").length) {
        // set progress bars to correct length
        $(".kartensatz-block .progress-bar").each(function () {
            $(this).animate({width: $(this).data('progress') + '%'}, 1000, 'easeOutQuad');
        });
    }
});

function closeRecommendationBtnClick(el) {
    $(el).closest(".kartensatz-block").remove();
    closeBanner(8);
}

function closeCardsetLegend() {
    $(".card-set-legend").hide();
    closeBanner(2);
}

function understoodCardsetLegend() {
    $(".card-set-legend").hide();
    closeBanner(2);
}

//function changeOrderOfCardboxes(el) {
//    var selectedOrder = $(el).val();
//
//    var thisCardsetsContainer = $(el).parent().parent().siblings(".cardsets-container");
//    thisCardsetsContainer.fadeTo(400, 0.3);
//    var inactive = thisCardsetsContainer.hasClass("inactive") ? true : '';
//
//    var subset;
//    if (inactive) {
//        subset = "inactive";
//    } else {
//        subset = "active";
//    }
//
//    if ($(".folder-btn.active")) {
//        var mystr = $(".folder-btn.active").attr('id');
//        if (mystr != null && mystr.length > 0) {
//            var myarr = mystr.split("-");
//            if (myarr.length > 1) {
//                var folderId = myarr[1];
//            }
//        }
//    }
//
//    refreshCardsetsContainer(subset, folderId, null, selectedOrder, 0, 20);

//	$.ajax({
//          type: "POST",
//          url: "modul_kartensaetze",
//          data: "module=cardsets-container&folderId=" + folderId + "&orderOfCardboxes=" + selectedOrder + "&subset=" + subset,
//          success: function(data){
//          	thisCardsetsContainer.html(data);
//          	thisCardsetsContainer.fadeTo(400, 1.0);
//            loadStatistics(thisCardsetsContainer);
//          	// loadColearners(thisCardsetsContainer);
//          }
//      });
//}

/*
function loadColearners(cardsetsContainer) {
    debug('load colearners');
    cardsetsContainer = cardsetsContainer || $(".cardsets-container");
    $.ajax({
        type: "POST",
        url: "modul_kartensaetze",
        data: "getAllColearners=1",
        success: function (data) {
//            try {
            var colearners = JSON.parse(data);
//            } catch (e) {
//                // data is no valid json then.
//                sendError('function loadColearners: data is no valid JSON: ' + data, '', 0, 0, '');
//                return;
//            }
            // im cardsetsContainer für jede box, gucken ob im array colearnesOfBox
            //
            $.each(colearners, function (boxId, colearnersOfBox) {

                // setze anzahl mitlerner fuer kartensatz boxId
                var boxLearnerQuantity = cardsetsContainer.find("#learner-quantity-" + boxId);
                if (boxLearnerQuantity.length) {
                    boxLearnerQuantity.html(colearnersOfBox.length);
                    var counter = 0;
                    $.each(colearnersOfBox, function (index, colearner) {
                        if (counter > 2) {
                            return false;
                        }
                        var coLearnerString = '<a href="profile-' + colearner.userId + '" title="' + colearner.userName + '">';
                        coLearnerString += '<div class="userpic small" style="background-image:url(\'' + colearner.avatarUrl + '\');"></div>';
                        coLearnerString += '</a> ';
                        boxLearnerQuantity.after(coLearnerString);
                        counter++;
                    });
                } // end if length
            });
        }
    });
}
*/

function compactCardsetsView() {
    setCookie('cardsetListLayout', 'compact', 365);
    $(".compact-cardsets-btn").addClass("hidden");
    $(".expand-cardsets-btn").removeClass("hidden");
    $(".kartensatz-block").removeClass("expanded").addClass("compact");
    $(".card-set-legend").removeClass("expanded").addClass("compact");
    unbindDrawStatistics();
}

function expandedCardsetsView() {
    setCookie('cardsetListLayout', 'expanded', 365);
    $(".expand-cardsets-btn").addClass("hidden");
    $(".compact-cardsets-btn").removeClass("hidden");
    $(".kartensatz-block").removeClass("compact").addClass("expanded");
    $(".card-set-legend").removeClass("compact").addClass("expanded");
    drawStatisticsForEachcardsetBlock();
}
