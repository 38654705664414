/* DELETE MULTIPLE CARDS MODAL */

// open modal btn
function deleteMultipleCardsModal() {
    $("#delete-multiple-cards-modal").modal();
}

function deleteMultipleCardsModalAction() {
    // what are the IDs of the selected cards?
    var boxId = $('#boxId').val();
    var cardIds = getSelectedCardIdsAsArray();
	$('#cardset-content').fadeTo(400, 0.3);
	deleteMultipleCards(boxId,cardIds);
}

function deleteMultipleCards(boxId,cardIds,refreshList,showDeletePopup) {
    refreshList = typeof refreshList !== 'undefined' ? refreshList : true;
    showDeletePopup = typeof showDeletePopup !== 'undefined' ? showDeletePopup : true;
    if (showDeletePopup) {
        flashModal(i18n.gettext('Deleting cards...'));
    }
    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + boxId,
        data: "deleteCards=" + cardIds + "&module=kartensatz_karten",
        success: function (data) {
            var numOfCards = parseInt(data);
            $('#cardset-content').fadeTo(400, 1.0);
            if (refreshList) {
                loadAllCards();
    //            $(".hover-layer").filter(".selected").parent().remove();
    //            $(".kartensatz-selected-cards-quantity").text(0);
                $(".js-cardset-card-quantity").text(parseInt($(".js-cardset-card-quantity").text()) - cardIds.length);
                if (numOfCards == 0) {
                    flashModal(i18n.gettext('No card was moved to trash.'));
                } else if (numOfCards == 1) {
                    flashModal(i18n.gettext('The card was moved to trash.'));
                } else {
                    flashModal(numOfCards + ' ' + i18n.gettext('cards were moved to trash.'));
                }
                refreshActivityBarInit(true);
            } else {
                closeModal();
            }
        }
    });
}

function undeleteMultipleCards() {
    var boxId = $('#boxId').val();
    var cardIds = getSelectedCardIdsAsArray();
    flashModal(i18n.gettext('Restoring cards...'));
    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + boxId,
        data: "undeleteCards=" + cardIds + "&module=kartensatz_karten",
        success: function (data) {
            var numOfCards = parseInt(data);
            $('#cardset-content').fadeTo(400, 1.0);
            loadAllCards();
//            $(".hover-layer").filter(".selected").parent().remove();
//            $(".kartensatz-selected-cards-quantity").text(0);
            $(".js-cardset-card-quantity").text(parseInt($(".js-cardset-card-quantity").text()) - cardIds.length);
            if (numOfCards == 0) {
                flashModal(i18n.gettext('No card was restored.'));
            } else if (numOfCards == 1) {
                flashModal(i18n.gettext('One card was restored.'));
            } else {
                flashModal(numOfCards + ' ' + i18n.gettext('cards were restored.'));
            }
        }
    });
}