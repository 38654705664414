try {
    if (matchMedia) {
        // media query width 768 event handler
        var mqMinWidth768 = window.matchMedia( "(min-width: 768px)" );
        mqMinWidth768.addListener(widthChangeTablet);
        widthChangeTablet(mqMinWidth768);

        // media query width 1024 event handler
        var mqMinWidth1024 = window.matchMedia( "(min-width: 1024px)" );
        mqMinWidth1024.addListener(widthChange1024);
        widthChange1024(mqMinWidth1024);

        // media query width 1340 event handler
        var mqMinWidth1340 = window.matchMedia( "(min-width: 1340px)" );
        mqMinWidth1340.addListener(widthChangeWide);
        widthChangeWide(mqMinWidth1340);
    }
} catch (e) {
    debug("matchMedia does not work in this browser.");
}


// media query width 768px breakpoint change
function widthChangeTablet() {

	if (mqMinWidth768.matches) {
		// debug('width is now 768px or greater!');
		// window width is equal or greater than 768px
		$("html").removeClass("smaller-than-tablet");
	}
	else {
		// debug('width is now less than 768px!');
		// window width is less than 768px
		$("html").addClass("smaller-than-tablet");
	}

}



// media query width 1024px breakpoint change
function widthChange1024() {

	if (mqMinWidth1024.matches) {
		// debug('width is now 1024px or greater!');
		// window width is equal or greater than 768px
		$("html").removeClass("smaller-than-1024px");
	}
	else {
		// debug('width is now less than 1024px!');
		// window width is less than 768px
		$("html").addClass("smaller-than-1024px");
	}

}



// media query width 1340px breakpoint change
function widthChangeWide() {
	if ($(".sidebar").length) {
		if (mqMinWidth1340.matches) {
			// debug('width is now 1340px or greater!');
			// window width is equal or greater than 1340px
			// keep page-container small if activity-bar stays hidden
			if (($(".activity-bar").hasClass("hidden")) && ($(".activity-bar").hasClass("hide-setting"))) {
				// $(".page-container").addClass("wide");
			} else if (!$(".page-container").hasClass("sometimesWide")) {
				$(".page-container").addClass("wide");
				$(".activity-bar").removeClass("hidden");
				$(".activity-bar-toggle").addClass("active");
			}

		}
		else {
			// debug('width is now less than 1340px!');
			// window width is less than 1340px
			// remove style width attribute that has been set by javascript so page-container is resized according to css media queries
			$(".page-container").removeClass("wide");
			if (!$(".activity-bar").hasClass("show-explicit")) {
				$(".activity-bar").addClass("hidden");
				$(".activity-bar-toggle").removeClass("active");
			}
		}
	}

}
