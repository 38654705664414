function cardDiscussionBlockClick(el) {

    // gehoert die Diskussion zu einem Kartensatz, von dem der aktuelle eine Kopie ist?
    var boxId = $("#boxId").val();
    var cardId = $(el).find(".question").data("cardid");

    // welche cardId hat die Karte in Kartensatz boxId, welche Kopie von Karte cardId ist?

    gotoDiscussionOfCard(cardId, boxId);

}

function gotoDiscussionOfCard(cardId, boxId) {
    $.ajax({
        type: "POST",
        url: "cardset-" + boxId,
        data: "getIdOfCopyInBox=" + cardId,
        success: function (cardIdOfCopy) {
            loadPageByAjax("card-" + cardIdOfCopy);
        },
        error: function () {
            flashModal(i18n.gettext("Discussion not found."));
        }
    });
}

// show new discussion input form
function newDiscussionBtnClick() {
    if ($(".create-discussion-form-wrapper").is(':visible')) {
        $(".create-discussion-form-wrapper").hide();
        removeAllTinyMces();
    } else {
        $(".create-discussion-form-wrapper").show();
        $(".cardset-name").focus();
        removeAllTinyMces();
        // init tinymce
        $(".editor-discussion-text").tinymce(editorConfig($(".editor-discussion-text")));
        $(".editor-discussion-text").css("visibility", "visible");
    }
}

function createNewDiscussionBtnClick() {
    createDiscussion();
    $(".create-discussion-form-wrapper").hide();
    removeAllTinyMces();
}

function cancelCreateNewDiscussionBtnClick() {
    $(".create-discussion-form-wrapper").hide();
    removeAllTinyMces();
}

function newDiscussionPost() {
    if ($("html").data("loggedin") != "1") {
        generalPurposeModal(i18n.gettext("You must be logged in to post a comment."), i18n.gettext("Please log in first"));
    } else {
        $(".new-post-btn").hide();
        // removeTinyMcesInside($(".new-discussion-post"));
        removeAllTinyMces(true);
        $(".new-discussion-post").show();
        setTimeout(function () {
            // scroll to input because it doesn't work automatically sometimes for reasons unknown
            $('html, body').animate({
                scrollTop: $(".new-discussion-post").offset().top
            }, 200);
            $(".new-discussion-post").find(".input").tinymce(editorConfig($(".new-discussion-post")));
        }, 0);
    }
}


function cancelNewPost() {
    $(".new-discussion-post").hide();
    $(".new-post-btn").show();
    $(".new-discussion-post").find(".input").tinymce().remove();
    $(".new-discussion-post").find(".input").html('');
}


function createDiscussion() {
    $(".create-new-discussion-btn").prop('disabled', true);

    var discussionTitle = encodeURIComponent($('#discussion-name').val());
    var discussionText = encodeURIComponent($('#discussion-text').html());

    if ($(".discussions-quantity").length > 0) {
        var numOfDiscussions = parseInt($(".discussions-quantity").html());
        numOfDiscussions++;
        $(".discussions-quantity").html(numOfDiscussions);
    }

    var objectId = $("#discussion-objectId").val();
    var objectType = $("#discussion-objectType").val();
    var submitToUrl = "discussion";

    var nonce = getNonce();
    
    flashModal(i18n.gettext("Creating post..."));
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: submitToUrl,
        data: "discussionSubject=" + discussionTitle + "&discussionText=" + discussionText + "&objectType=" + objectType + "&objectId=" + objectId + "&module=discussion-compact-module" + "&nonce=" + nonce,
        context: this,
        success: function (data) {
            flashModal(data);
            var url = $('#pagehash').val();
            loadPageToCenter(url);
//            $(".discussion-modules").prepend(data);
//            // reset form
//            $(".create-new-discussion-btn").removeAttr('disabled');
//            $("#discussion-name").val('');
//            $(".editor-discussion-text").html('');
        }
    });

}

function sendNewPost() {
    var discussionText = encodeURIComponent($('#discussion-text').html());
    var threadId = $("#discussion-id").val();
    
    var objectId = $("#discussion-objectId").val();
    var objectType = $("#discussion-objectType").val();
    var submitToUrl = "discussion";

    if ($("#discussion-objectType").length > 0 && $("#discussion-objectType").val() == 13) {
        var boxId = $("#discussion-objectId").val();
        submitToUrl = "kartensatz-diskussionen-" + boxId;
    } else if ($("#cardId").length > 0) {
        var cardId = $("#cardId").val();
        submitToUrl = "karte-" + cardId;
    } else if ($("#discussion-classId").length > 0) {
        classId = $("#discussion-classId").val();
        submitToUrl = "course-forum-" + classId;
    }

    var nonce = getNonce();
    flashModal(i18n.gettext("Creating post..."));
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: submitToUrl,
        data: "threadId=" + threadId + "&discussionText=" + discussionText + "&objectType=" + objectType + "&objectId=" + objectId + "&module=discussion-post" + "&nonce=" + nonce,
        success: function (data) {
            flashModal(data);
            var url = $('#pagehash').val();
            loadPageToCenter(url);
            
//            $('<div style="display:none;">' + data + '</div>').appendTo($(".discussion-wrapper")).slideDown();
//            if ($(".no-discussion-msg").length > 0) {
//                $(".no-discussion-msg").remove();
//            }
//            var numOfDiscussionMsgs = parseInt($('.num-of-discussion-msgs').html());
//            numOfDiscussionMsgs++;
//            $('.num-of-discussion-msgs').html(numOfDiscussionMsgs);
            //$(".discussion-wrapper").append(data);
        }
    });

    $(".new-discussion-post").hide();
    $(".new-post-btn").show();
    $(".new-discussion-post").find(".input").tinymce().remove();
    $(".new-discussion-post").find(".input").html('');
}

function deletePost(elem) {
    var postId = $(elem).data('postid');
    var r = confirm(i18n.gettext("Really delete this post?"));
    if (r === true) {
        $.ajax({
            type: "POST",
            url: "discussion",
            data: "deletePost=" + postId,
            success: function (data) {
                shortInfo(data);
                refreshPage();
            }
        });
    }
}