// go to create new cardset page
function newCardset() {
    var courseId = null;
    if ($("#courseid").length > 0) {
        courseId = $("#courseid").val();
    }
    
    var folderId = null;
    if ($("#folderSelect").length > 0) {
        folderId = $("#folderSelect").val();
    }
    
    $.ajax({
        type: "GET",
        url: "confsaver",
        data: "getCardboxCreateInfo=1",
        success: function (data) {
//            try {
            var obj = JSON.parse(data.trim());

            if (!obj.proUser && (parseInt(obj.numOfCreatedCardboxes) >= parseInt(obj.numCreateBoxes))) {
                repeticoPromptModal(i18n.gettext('Maximum number of created cardsets reached for FREE-Users.')
                        + ' ' + i18n.gettext("Deleted cardsets are considered as well.")
                        + '<br/><br/>'
                        + sprintf(i18n.gettext('Create an unlimited number of cardsets with %s!'), sprintf('<span style="font-weight:bold;">%s PRO</span>', $("html").data("apptitle"))));
            } else {
                if (courseId != null) {
                    loadPageByAjax('course-new-cardset-' + courseId);
                } else if (typeof folderId !== 'undefined') {
                    debug("folderId is: " + folderId)
                    loadPageByAjax('new-cardset?folderId=' + folderId);
                } else {
                    debug("folderId is undefined!");
                    loadPageByAjax('new-cardset');
                }
                //debug('Kartensatz erstellen erlaubt.');
            }
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        }
    });
}



// NEUER KARTENSATZ KOOPERATIONS-OPTIONEN BTN
function toggleCoopOptions() {
    $(".coop-options").toggle();
}

function createCardsetFromTemplate() {
    var uni = $('#uni').val();
    var studyStage = encodeURIComponent($('#studyStage').val());
    var area = encodeURIComponent($('#topicArea').val());
    var templateId = $('#template-id').val();
    // var year = $('#year').val();
    var transferCopyRight = false;
    var nonce = getNonce();
    //  + "&semester=" + semester
    if ($('#template-id').val() == null) {
        flashModal("Bitte wähle ein Thema aus, um einen Kartensatz zu erstellen.");
    } else {
        $.ajax({
            // retry-sensitive
            type: "POST",
            url: "main",
            data: "template-id=" + templateId + "&uni=" + uni + "&studyStage=" + studyStage + "&area=" + area + "&transferCopyRight=" + transferCopyRight
                    + "&nonce=" + nonce
        }).done(function (data) {
            var obj = $.parseJSON(data);
            flashModal(obj.message);
            if (obj.success) {
                loadPageByAjax("cardset-" + obj.object, function() {
                    createMultipleCards(1);
                });
            }
            removeAllTinyMces();
        }).fail(function (data) {
            flashModal(i18n.gettext("Error while creating the cardset."));
        }).complete(function () {
            $(".new-cardset").fadeTo(1000, 1);
        });
    }
}

function refreshAreaList() {
    var studyStage = encodeURIComponent($("#studyStage").val());
    $('#topicArea').children().remove();
    $.ajax({
        type: "POST",
        url: "neuer-kartensatz",
        data: "areasByStudyStage=" + studyStage
    }).done(function (data) {
        var obj = $.parseJSON(data);
        if (obj.length > 0) {
            for (var index = 0; index < obj.length; index++) {
                $('#topicArea').append('<option value="' + obj[index] + '">' + obj[index] + '</option>');
            }
        }
        refreshTopicList();
    }).fail(function () {
        flashModal("Could not update list of areas.");
    }).complete(function () {

    });
}

function refreshTopicList() {
    var topicArea = encodeURIComponent($("#topicArea").val());
    $('#template-id').children('option:not(:first)').remove();
    $.ajax({
        type: "POST",
        url: "neuer-kartensatz",
        data: "topicsByArea=" + topicArea
    }).done(function (data) {
        var obj = $.parseJSON(data);
        if (obj.length > 0) {
            for (var index = 0; index < obj.length; index++) {
                $('#template-id').append('<option value="' + obj[index]['boxId'] + '">' + obj[index]['boxName'] + '</option>');
            }
        }
    }).fail(function () {
        flashModal("Could not update list of topics.");
    }).complete(function () {

    });
}

function createCardset() {
    if ($("#cardset-name").val() != null && $("#cardset-name").val().trim() !== "") {
        $(".js-create-new-cardset-btn").prop('disabled', true);
        $(".new-cardset").fadeTo(1000, 0.5);

        var boxName = encodeURIComponent($('#cardset-name').val());

        var boxDescription;
        if ($('#cardset-description').length > 0) {
            boxDescription = encodeURIComponent($('#cardset-description').html());
        }

        var tags;
        if ($(".cardset-keywords").length > 0) {
            tags = encodeURIComponent($(".cardset-keywords").val());
        }

        var categories;
        if ($('#cardset-categories').length > 0) {
            categories = encodeURIComponent($('#cardset-categories').val());
        }

        var folderId;
        if ($('#folderId').length > 0) {
            folderId = $('#folderId').val();
        }

        var viewOption;
        var editOption;
        var addOption;
        var applyOption;
        var inviteOption;
        if ($(".coop-options").length > 0) {
            viewOption = $('#viewOption').val();
            editOption = $('#editOption').val();
            addOption = $('#addOption').val();
            applyOption = $('#applyOption').val();
            inviteOption = $('#inviteOption').val();
        }

        var targetDate;
        if ($("#cardset-target-date").length > 0) {
            targetDate = $("#cardset-target-date").val();
        }

        var colearners = [];

        $('#add-studypals-modal').find("input").each(function () {
            colearners.push(encodeURIComponent($(this).val()));
        });

        var colearnerIds = [];
        $('#add-studypals-modal').find(".js-addMe").each(function () {
            colearnerIds.push($(this).data("userid"));
        });

        var questionLanguage = '';
        if ($("#cardset-question-language").length > 0) {
            questionLanguage = $("#cardset-question-language").val();
        }
        var answerLanguage = '';
        if ($("#cardset-answer-language").length > 0) {
            answerLanguage = $("#cardset-answer-language").val();
        }

        var courseId = null;
        if ($("#courseId").length > 0) {
            courseId = $("#courseId").val();
        }

        var nonce = getNonce();
        $.ajax({
            // retry-sensitive
            type: "POST",
            url: "main",
            data: "cardset-name=" + boxName + "&cardset-description=" + boxDescription + "&tags=" + tags
                    + "&cardset-categories=" + categories + "&viewOption=" + viewOption + "&editOption="
                    + editOption + "&addOption=" + addOption + "&applyOption=" + applyOption + "&inviteOption="
                    + inviteOption + "&colearners=" + colearners + "&colearnerIds=" + colearnerIds + "&questionLanguage="
                    + questionLanguage + "&answerLanguage=" + answerLanguage + "&folderId=" + folderId + "&courseId=" + courseId
                    + "&targetDate=" + targetDate
                    + "&nonce=" + nonce
        }).done(function (data) {
            var obj = $.parseJSON(data);
            flashModal(obj.message);
            if (obj.success) {
                loadPageByAjax("cardset-" + obj.object);
            }
            removeAllTinyMces();
        }).fail(function (data) {
            flashModal(i18n.gettext("Error while creating the cardset."));
        }).complete(function () {
            $(".new-cardset").fadeTo(1000, 1);
        });
    } else {
        flashModal(i18n.gettext("Please enter a name for the cardset."));
        $(".cardset-name").focus();
    }
}

function newCardsetKeyPress(e) {
    if (e.keyCode == 13) {
        createCardset();
    }
}

function showPublicContentWarning(id) {
    if (id === undefined)
        id = "viewOption";
    if ($("#" + id).val() == "E") {
        $("#public-box-warning").show();
    } else {
        $("#public-box-warning").hide();
    }
}