// PRINT CARDS
function printCardsModal() {
    $("#print-cards-modal").modal();
}

function printCardsModalAction() {
    var boxId = $('#boxId').val();
    var printOption = "";
    var withoutPictures = $("#print-cards-modal #withoutPictures").is(":checked");
    var aboutEachOther = $("#print-cards-modal #aboutEachOther").is(":checked");
    var avoidPageBreaks = $("#print-cards-modal #avoidPageBreaks").is(":checked");
    var fontSize = $("#print-cards-modal #fontSize").val();
    var enforceFontSize = $("#print-cards-modal #enforceFontSize").is(":checked");
    var selected = $("input[type='radio'][name='print-e']:checked");
    var cellHeight = $("#print-cards-modal #cellHeight").val();
    if (selected.length > 0) {
        printOption = selected.val();
    }
    window.open('export?groupKey=' + boxId + '&e=print&withoutPictures=' + withoutPictures + "&printOption=" + printOption + "&cellHeight=" + cellHeight + "&aboutEachOther=" + aboutEachOther
    + "&avoidPageBreaks=" + avoidPageBreaks + "&fontSize=" + fontSize + "&enforceFontSize=" + enforceFontSize, '_blank');
    return false;
}

function setRadioSelected(name, value) {
    $("input[name='" + name + "'][value='" + value + "']").prop('checked', true);
}