// KARTENSATZ BLOCK settings button + TOP BTN BAR settings button click functions

function learningSettingsBtnClick(el) {
    var buttonId = $(el).attr('id');
    var buttonArr = buttonId.split("-");
    var boxId = buttonArr[1];

    $(el).toggleClass("active");

    // debug("Show " + "#settings-container-" + boxId);

    if ($(".settings-container").is(":visible")) {
        $(".settings-container").hide();
    } else {
        $("#settings-container-" + boxId).show();

        // add function to close container on click outside
        // setTimeout needed or close function receives button click
        setTimeout(function () {
            $(document).on('click.close-settings', function () {
                $(".settings-container").hide();
                $(el).removeClass("active");
                $(document).off('click.close-settings');
            });
        }, 10);
    } // end if settings-container exists

    // have the button NOT focussed (and colored) when clicking it again to close the dropdown
    $(el).blur();
}



function learningSettingsContainerActivationClick(el, event) {
    $(".kartensatz-block .settings-container").hide();
    $(".kartensatz-block .js-settings-btn").removeClass("active");
    $(document).off('click.close-settings');

    event.stopPropagation();
    var mystr = $(el).attr('id');
    var myarr = mystr.split("-");
    var option = myarr[0];
    var boxId = myarr[1];

//    var folderId = 0;
//    if ($(".folder-btn.active")) {
//        var mystr = $(".folder-btn.active").attr('id');
//        if (mystr != null && mystr.length > 0) {
//            var myarr = mystr.split("-");
//            if (myarr.length > 1) {
//                folderId = myarr[1];
//            }
//        }
//    }

    var todo = "";
    if ($(el).hasClass('js-settings-deactivate-btn')) {
        todo = "deactivateBox";
        // debug("FadeOut: " + "#kartensatz-block-" + boxId);
        $("#kartensatz-block-" + boxId).fadeTo("fast", 0.1);
        executeCardsetStateChange(boxId, todo);

    } else if ($(el).hasClass('js-settings-activate-btn')) {
        todo = "activateBox";
        $.ajax({
            type: "POST",
            url: "main",
            data: "activateAllowed=" + boxId,
            success: function (data) {

                var obj = JSON.parse(data.trim());
                if (obj.message.trim() != "") {
                    debug("How long is the message? " + obj.message.trim().length);
                    if (obj.message.trim().length > 80) {
                        generalPurposeModal(obj.message);
                    } else {
                        flashModal(obj.message);
                    }
                }

                if (obj.allowed) {
                    $("#kartensatz-block-" + boxId).fadeTo("fast", 0.1);
                    executeCardsetStateChange(boxId, todo);
                }

            }
        });
    }
}

function executeCardsetStateChange(boxId, todo) {
    $.ajax({
        type: "POST",
        url: "modul_kartensaetze",
        data: todo + "=" + boxId,
        success: function (data) {
            
            shortInfo(data);

            if ($(".numOfActiveCardboxes").length > 0 && $(".numOfInactiveCardboxes").length > 0) {
                var numOfActiveCardboxes = parseInt($(".numOfActiveCardboxes").text());
                var numOfInactiveCardboxes = parseInt($(".numOfInactiveCardboxes").text());
                if (todo == "activateBox") {
                    numOfActiveCardboxes++;
                    numOfInactiveCardboxes--;
                } else {
                    numOfActiveCardboxes--;
                    numOfInactiveCardboxes++;
                }
                $(".numOfInactiveCardboxes").text(numOfInactiveCardboxes);
                $(".numOfActiveCardboxes").text(numOfActiveCardboxes);
            }

            if (!$(".cardsets-container").length) {
                refreshPage();
            } else {
                refreshCardsetsContainer();
            }

            if ($("#lernplan-container").length > 0) {
                $('#lernplan-container').fadeTo(400, 0.3);
                $.ajax({
                    type: "POST",
                    url: "main",
                    data: "module=learnplan",
                    success: function (data) {
                        $('#lernplan-container').html(data);
                        $('#lernplan-container').fadeTo(400, 1.0);
                    }
                });
            }
        }
    });
}

function refreshCardsetsContainer(subset, folderId, filterText, order, offset, limit) {

    var cc = $(".cardsets-container");

    subset = typeof subset !== 'undefined' && subset != null ? subset : cc.data("subset");
    folderId = typeof folderId !== 'undefined' && folderId != null ? folderId : cc.data("folderid");
    filterText = typeof filterText !== 'undefined' && filterText != null ? filterText : $('#cardsetsFilterText').val();
    order = typeof order !== 'undefined' && order != null ? order : cc.data("order");
    offset = typeof offset !== 'undefined' && offset != null ? offset : cc.data("offset");
    limit = typeof limit !== 'undefined' && limit != null ? limit : cc.data("limit");

    if (folderId == -1) {
        loadPageByAjax('manage-folders');
    } else if (folderId == -2) {
        loadPageByAjax('manage_deleted_cardsets');
    } else if (folderId == -3) {
        loadPageByAjax('manage_removed_cardsets');
    } else {
        cc.fadeTo(400, 0.3);
        $.ajax({
            type: "POST",
            url: "modul_kartensaetze",
            data: "module=cardsets-container&folderId=" + folderId + "&filterText=" + filterText + "&subset=" + subset + "&orderOfCardboxes=" + order + "&offset=" + offset + "&limit=" + limit,
            success: function (data) {
                cc.data("subset", subset);
                cc.data("folderid", folderId);
                cc.data("order", order);
                cc.data("offset", offset);
                cc.data("limit", limit);
                cc.data("filtertext", filterText);

                cc.html(data);
                cc.fadeTo(400, 1.0);
                loadStatistics(cc);
                // loadColearners($(refreshContainerClass));
            }
        });
    }
}

//function throttle(f, delay){
//    var timer = null;
//    return function(){
//        var context = this, args = arguments;
//        debug("Clearing timeout...");
//        clearTimeout(timer);
//        timer = window.setTimeout(function(){
//            f.apply(context, args);
//        },
//        delay || 1000);
//    };
//}

function filterCardsetsKeyPress(e) {
    if (e.keyCode == 13) {
        debug("Refreshing because of enter");
        if ($('#cardsetsFilterText').val() != $('.cardsets-container').data("filtertext")) {
            refreshCardsetsContainer(null, null, $('#cardsetsFilterText').val(), null, 0);
        } else {
            debug("We don't refresh, because old and new value are equal: " + $('.cardsets-container').data("filtertext"));
        }
    }
}

function refreshCardsetsOnKeyUp() {
    debug("Refreshing because of key-up");
    if ($('#cardsetsFilterText').val() != $('.cardsets-container').data("filtertext")) {
        refreshCardsetsContainer(null, null, $('#cardsetsFilterText').val(), null, 0);
    } else {
        debug("We don't refresh, because old and new value are equal: " + $('.cardsets-container').data("filtertext"));
    }
}

var filterCardsetsInputTimeout;
function filterCardsetsKeyUp(e) {
    if (e.keyCode != 13) {
        if (filterCardsetsInputTimeout) {
            clearTimeout(filterCardsetsInputTimeout);
            filterCardsetsInputTimeout = null;
        }
        filterCardsetsInputTimeout = setTimeout(refreshCardsetsOnKeyUp, 750);
    }
}