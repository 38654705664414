/* MULTIPLE CARDS SELECT CATEGORIES MODAL */

// open modal btn
function multipleCardsSelectCategoriesModal() {
    $("#multiple-cards-select-categories-modal").modal();
    var boxId = $('#boxId').val();

    // Alle aktuellen Kategorien laden
    showAjaxLoader("#multiple-cards-select-categories-modal .categories");

    $.getJSON("kartensatz-karten-" + boxId + '?categories', function (data) {
        // reset and build categories HTML
        hideAjaxLoader();
        $("#multiple-cards-select-categories-modal .categories").html('');
        var categoryString = '';
        $.each(data, function (index, value) {
            // category key = value.id
            // category name = value.name
            categoryString += ' \
					<div class="input-x w100"> \
						<span class="custom-checkbox"> \
							<input class="category-selector" type="checkbox" id="moveToCategory-' + value.id + '-checkbox"> \
							<!-- <span class="box"> \
								<i class="fa fa-check"></i> \
                                <i class="fa fa-check scattered" onclick="scatteredCategoryClick(this,event)"></i> \
							</span> --> \
						</span> \
						<input type="text" class="w100" onblur="categoryInputBlur(this)" tabindex="20" id="editCategory-' + value.id + '" value="' + value.name + '"';
            if (!value.removeOrEditAllowed) {
                categoryString += ' disabled=\"disabled\"';
            }
            categoryString += '>';
            if (value.removeOrEditAllowed) {
                categoryString += '<button class="remove-btn" onclick="modalRemoveCategory(this)" id="removeCategory-' + value.id + '"> \
                                <i class="fa fa-minus-square-o"></i> \
                    </button>';
            }
            categoryString += '</div>';
        });
        $("#multiple-cards-select-categories-modal .categories").html(categoryString);

        // in welchen Kategorien sind die markierten Karten?

        var items = getSelectedCardIdsAsArray();

        $.ajax({
            type: "POST",
            url: "kartensatz-karten-" + boxId,
            data: "getCategoriesOfCards=" + items,
            success: function (data) {
                // Die Kästchen entsprechend ankreuzen. Kategorien, in denen nur manche sind: "scattered"!
//                try {
                var obj = JSON.parse(data.trim());
                var allOccuringCategories = [];
                $.each(obj, function (index, value) {
                    //debug(value.questionKey + ": " + value.categoryKey);

                    // make array of all occuring categories
                    if (allOccuringCategories.indexOf(value.categoryKey) == -1 && value.categoryKey != 0) {
                        debug("Adding category " + value.categoryKey);
                        allOccuringCategories.push(value.categoryKey);
                    }

                });
                var scatteredCategories = [];
                var fullCategories = [];

                // for each of allOccuringCategories: Are all or some of selected cards in them?
                $.each(allOccuringCategories, function (index, value) {
                    var categoryId = value;
                    var counter = 0;
                    $.each(obj, function (innerIndex, innerValue) {
                        if (innerValue.categoryKey == categoryId) {
                            counter++;
                        }
                    });
                    if (items.length == counter) {
                        fullCategories.push(categoryId);
                        debug("Add " + categoryId + " to full categories");
                    } else {
                        scatteredCategories.push(categoryId);
                        debug("Add " + categoryId + " to scattered categories");
                    }
                });

                // mark all entries of fullCategories as checked
                // checked zum input adden
                $.each(fullCategories, function (index, value) {
                    debug('#category-' + value + '-checkbox' + ' auf checked setzen');
                    $('#moveToCategory-' + value + '-checkbox').prop('checked', true);
                });

                // mark all entries of scatteredCategories as scattered
                // Klasse scattered zur checkbox adden
                $.each(scatteredCategories, function (index, value) {
                    debug('#category-' + value + '-checkbox' + ' auf scattered setzen');
                    $('#moveToCategory-' + value + '-checkbox').addClass('scattered').attr("onclick", "scatteredCategoryClick(this,event)");
                });
//                } catch (e) {
//                    sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                    return;
//                }
            }
        });

    });

    if ($("#multiple-cards-select-categories-modal .keywords-value-multiplecards-modal").length > 0) {
        var enterKeywordsString = i18n.gettext("Enter keywords (separate by enter or comma)");
        $("#multiple-cards-select-categories-modal .keywords-value-multiplecards-modal").tagsInput({
            'autocomplete_url': 'keywords',
            'autocomplete': {appendTo: "#multiple-cards-select-categories-modal .keyword-suggestions-multiplecards-modal"},
            'defaultText': enterKeywordsString,
            'height': '40px',
            'width': '100%'
        });
        $("#keywords-value-multiplecards-modal_tag").on('focusout', function () {
            debug("*** Triggering enter.");
            triggerEnter("#keywords-value-multiplecards-modal_tag");
        });
        $(".ui-autocomplete-input").css("width","340px");
    }

} // end fn multipleCardsSelectCategories



// if some, but not all cards have a certain category: clicking switches between keeping some cards in the category, adding the category to all cards or removing the category from all cards
function scatteredCategoryClick(el, e) {
    var scattered = $(el).siblings(".box").find(".fa-check.scattered");
    if ($(el).attr("data-status") == "keep") {
        $(el).attr("data-status", "add");
        $(el).removeClass('scattered');
        // scattered.hide();
    } else if ($(el).attr("data-status") == "add") {
        $(el).attr("data-status", "remove");
    } else if ($(el).attr("data-status") == "remove") {
        e.preventDefault();
        $(el).attr("data-status", "keep");
        $(el).addClass('scattered');
        // scattered.show();
    } else {
        $(el).attr("data-status", "add");
        $(el).removeClass('scattered');
        // scattered.hide();
    }
}



function multipleCardsSelectCategoriesModalAddCategory(el) {
    $('.modal.current').find('.categories').append(' \
			<div class="input-x input-c w100"> \
				<span class="custom-checkbox"> \
					<input type="checkbox" class="category-selector" id="category-new-checkbox" checked="checked"> \
					<!-- <span class="box"> \
						<i class="fa fa-check"></i> \
						<i class="fa fa-check scattered"></i> \
					</span> --> \
				</span> \
	    		<input type="text" class="w100" tabindex="20" onblur="categoryInputBlur(this)"> \
    			<button class="remove-btn" onclick="modalRemoveCategory(this)"> \
    				<i class="fa fa-minus-square-o"></i> \
    			</button> \
    		</div> \
		');
    $(el).closest(".modal").find("input:last").focus();
    $(el).closest(".modal").find("input[type=checkbox]:last").prop("checked", true);
}


function multipleCardsSelectCategoriesModalAction() {
    flashModal(i18n.gettext('Changing assignment...'));

    var keywords = "";
    if ($("#multiple-cards-select-categories-modal .keywords-value-multiplecards-modal").length > 0) {
        keywords = escape(encodeURIComponent($("#multiple-cards-select-categories-modal .keywords-value-multiplecards-modal").val().trim()));
    }

    setTimeout(function () {
        // debug("Check: Are all categories saved?");
        if (!$('#move-cards-to-category-btn-cards').hasClass('savingCategory')) {

            var boxId = $('#boxId').val();
            var items = getSelectedCardIdsAsArray();

            // which categories?
            var thisModal = $('#multiple-cards-select-categories-modal');
            var categories = [];
            var unselectedCategories = [];
            thisModal.find(".category-selector").each(function (index) {
                // debug( index + ": " +  $( this ).next().find('i').css('display')  );

                // folgendes ändern im Sinne von:
                // $('input:check')[0].checked == true;

                var mystr = $(this).attr('id');
                var myarr = mystr.split("-");
                var categoryId = myarr[1];

                if ($(this).is(":checked")) {
                    categories.push(categoryId);
                } else if (!($(this).hasClass('scattered'))) {
                    unselectedCategories.push(categoryId);
                }
            });

            debug("Selected categories: " + categories);
            debug("Unselected categories: " + unselectedCategories);
            $('#cardset-content').fadeTo(100, 0.5);
            $.ajax({
                type: "POST",
                url: "kartensatz-karten-" + boxId,
                data: "moveToCategories=" + items + "&categories=" + categories + "&unselectedCategories=" + unselectedCategories + "&keywords=" + keywords,
                success: function (data) {
                    $('#cardset-content').fadeTo(50, 1.0);
                    flashModal(data + ' ' + i18n.gettext('card(s) were assigned to the selected categories.'));
                    // reload current view of cards
                    loadCardsContent();
                }
            });

        }

    }, 500);

}
