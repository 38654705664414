/* NOTIFICATIONS */

// THIS SHOULD BE SOLVED BY REQUIRE() !
$(document).ready(function () { // doc ready, because needs var from nicescroll.js file
    // Notifications scrolling like Facebook activity bar
    if (window.jQuery && $.fn.niceScroll !== undefined && $(".notifications-content").length > 0) {
        $(".notifications-content").niceScroll(niceScrollConfig);
    }
});

// NOTIFICATIONS TOGGLE
function notificationsToggleClick() {
    if ($(".notifications").css("display") !== 'block') {
        $(".notifications").show();
        if ($("html").hasClass("smaller-than-tablet")) {
            disablePageScroll();
        }
        // per AJAX neue Inhalte laden

        //			$.get( "notifications?module=notifications&contentOnly=1", function( data ) {
        //				$( ".notifications-content" ).html( data );
        //				alert( "Load was performed." );
        //			});

        $.ajax({
            type: "POST",
            url: "notifications",
            data: "module=notifications-content&updateNumOfUnseenNotifications=1",
            success: function (data) {
                $(".notifications-content").html(data);
                reInitNotificationIndicator();
                /*
                 $.ajax({
                 type: "POST",
                 url: "notifications",
                 data: "markAllRead=1",
                 success: function(data){
                 markNotificationsNormal();
                 }
                 });
                 */

                $(".notifications-content").getNiceScroll().show();
                $(".notifications-content").getNiceScroll().resize();

            }
        });

        // add function to close container on click outside
        // setTimeout needed or close function receives button click

        setTimeout(function () {
            $(document).on('mousedown.close-notifications', function (event) {
                var clicked = event.target;
                $(document).on('click.close-notifications', function () {
                    if ($(clicked).closest(".notifications").length !== 1) {
                        hideNotificationBar();
                    } else if (($(clicked).hasClass('click')) || ($(clicked).is("a"))) {
                        hideNotificationBar();
                    }
                });
            });
        }, 10);

    } // end if notifications not visible
} // end fn notificationsToggleClick



function dismissNotification(el, e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    var notificationId = $(el).closest("div").attr("id");
    $(".notification#" + notificationId).remove();
    reInitNotificationIndicator();
    $.ajax({
        type: "POST",
        url: "notifications",
        data: "markNotificationAsSeen=" + notificationId,
        success: function (data) {
            // on ajax success:
            // flashModal(data);
            if ($(".notification").length == 0) {
                debug("No notification left");
                // hideNotificationBar();
            }
        }
    });
}



function readRecommendation(el, e) {
    debug('clicked on recommendation');
    e.preventDefault();
    var notificationId = $(el).data('id');
    $.ajax({
        type: "GET",
        url: "notifications",
        data: "getRecommendation=" + notificationId,
        success: function (data) {
//            try {
                var obj = JSON.parse(data.trim());
                var userName = obj.userName;
                var boxName = obj.boxName;
                var code = obj.code;
                var titleString = '<p class="modal-hl">'
                        + '<i class="fa fa-thumbs-o-up"></i>'
                        + '<span>'
                        + sprintf(i18n.gettext('%s recommends the following cardset to you:'), userName)
                        + '</span>'
                        + '</p>';
                var contentString = '<i class="fa fa-archive"></i> ';
                contentString += '<b>' + boxName + '</b><br/><br/>';
                contentString += obj.message;
                contentString += '<br/><br/>'
                        + sprintf(i18n.gettext('%sBuy here%s to enable a bonus for %s.'),
                                '<b><a onclick="recognizeRecommendation(\'' + code + '\');" class="bright-link click" href="empfehlung-' + code + '">',
                                '</a></b>',
                                userName);
                $("#general-purpose-modal-content").html(titleString + contentString);
                $("#general-purpose-modal .action-btn").html('<i class="fa fa-times"></i><span>' + i18n.gettext('Later') + '</span>');
                $("#general-purpose-modal").modal();
                $(".notification#" + notificationId).remove();
                reInitNotificationIndicator();
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        }
    });
}



function recognizeRecommendation(code) {
    $.ajax({
        type: "GET",
        url: "empfehlung",
        data: "code=" + code,
        success: function () {
            closeModal();
        }
    });
}



function readMessage(el, e) {
    e.preventDefault();
    var notificationId = $(el).closest("div").attr("id");
    var userId = $(el).data('userid');
    var userName = $(el).data('username');


    $.ajax({
        type: "POST",
        url: "notifications",
        data: "markNotificationAsSeen=" + notificationId,
        success: function (data) {
            // on ajax success:
            // flashModal(data);
            $(".notification#" + notificationId).remove();
            reInitNotificationIndicator();
            if ($(".notification").length == 0) {
                // hideNotificationBar();
            }
        }
    });

    openPrivateMessageModal();
    $("#private-message-modal .message-column #mailToUserName").html("?");

    loadThreads(false);

    $.ajax({
        type: "POST",
        url: "messages",
        data: "module=messages" + "&getMessagesWithUser=" + userId,
        success: function (data) {
            $("#private-message-modal .message-column .messages-container").html(data);
            $('#messageToUserId').val(userId);
            $("#private-message-modal .message-column #mailToUserName").html(userName);
        }
    });

} // end fn readMessage



function acceptBoxInvite(el, e) {
    e.preventDefault();
    var boxId = $(el).attr('id');
    var notificationId = $(el).closest("div").attr("id");
    flashModal(i18n.gettext('Accepting invitation...'));
    $.ajax({
        type: "POST",
        url: "notifications",
        data: "acceptBoxInvite=" + boxId + "&markNotificationAsSeen=" + notificationId,
        success: function (data) {
            // on ajax success:
            flashModal(data);
            $(".notification#" + notificationId).remove();
            reInitNotificationIndicator();
            if ($(".notification").length == 0) {
                // hideNotificationBar();
            }
            loadPageByAjax('cardset-' + boxId);
        }
    });
}



function denyBoxInvite(el, e) {
    e.preventDefault();
    var boxId = $(el).attr('id');
    var notificationId = $(el).closest("div").attr("id");
    flashModal(i18n.gettext('Rejecting invitation...'));
    $.ajax({
        type: "POST",
        url: "notifications",
        data: "denyBoxInvite=" + boxId + "&markNotificationAsSeen=" + notificationId,
        success: function (data) {
            // on ajax success:
            flashModal(data);
            $(".notification#" + notificationId).remove();
            reInitNotificationIndicator();
            if ($(".notification").length == 0) {
                // hideNotificationBar();
            }
        }
    });
}



function acceptBoxApplication(el, e) {
    e.preventDefault();
    var boxId = $(el).attr('id');
    var userid = $(el).data('userid');
    var notificationId = $(el).closest("div").attr("id");
    flashModal(i18n.gettext('Accepting application...'));
    $.ajax({
        type: "POST",
        url: "notifications",
        data: "acceptBoxApplication=" + boxId + "&markNotificationAsSeen=" + notificationId + "&userId=" + userid,
        success: function (data) {
            // on ajax success:
            flashModal(data);
            $(".notification#" + notificationId).remove();
            reInitNotificationIndicator();
            if ($(".notification").length == 0) {
                // hideNotificationBar();
            }
        }
    });
}



function denyBoxApplication(el, e) {
    e.preventDefault();
    var boxId = $(el).attr('id');
    var userid = $(el).data('userid');
    var notificationId = $(el).closest("div").attr("id");
    flashModal(i18n.gettext('Rejecting application...'));
    $.ajax({
        type: "POST",
        url: "notifications",
        data: "denyBoxApplication=" + boxId + "&markNotificationAsSeen=" + notificationId + "&userId=" + userid,
        success: function (data) {
            // on ajax success:
            flashModal(data);
            $(".notification#" + notificationId).remove();
            reInitNotificationIndicator();
            if ($(".notification").length == 0) {
                // hideNotificationBar();
            }
        }
    });
}



function extendProAccount(el, e) {
    e.preventDefault();
    var notificationId = $(el).closest("div").attr("id");
    $.ajax({
        type: "POST",
        url: "notifications",
        data: "markNotificationAsSeen=" + notificationId,
        success: function (data) {
            // on ajax success:
            // flashModal(data);
            $(".notification#" + notificationId).remove();
            reInitNotificationIndicator();
            if ($(".notification").length == 0) {
                // hideNotificationBar();
            }
            loadPageByAjax('upgrade');
        }
    });
}



function configureReminderMail(el, e) {
    e.preventDefault();
    var notificationId = $(el).closest("div").attr("id");
    $.ajax({
        type: "POST",
        url: "notifications",
        data: "markNotificationAsSeen=" + notificationId,
        success: function (data) {
            // on ajax success:
            // flashModal(data);
            $(".notification#" + notificationId).remove();
            reInitNotificationIndicator();
            if ($(".notification").length == 0) {
                // hideNotificationBar();
            }
            loadPageByAjax('settings');
        }
    });
}



function acceptFriendshipRequest(el, e) {
    e.preventDefault();
    var userId = $(el).attr('id');
    var notificationId = $(el).closest("div").attr("id");
    flashModal(i18n.gettext('Accepting friendship...'));
    $.ajax({
        type: "POST",
        url: "notifications",
        data: "acceptFriendship=" + userId + "&markNotificationAsSeen=" + notificationId,
        success: function (data) {
            // on ajax success:
            $(".notification#" + notificationId).remove();
            flashModal(data);
            reInitNotificationIndicator();
            if ($('#pagehash').val() == 'freunde') {
                loadPageToCenter('freunde');
                // reinit notifications from server
                $.ajax({
                    type: "GET",
                    url: "notifications?getJSON=1",
                    success: function (data) {
//                        try {
                            var obj = JSON.parse(data.trim());
                            if (obj.length == 0) {
                                markNotificationsNormal();
                            }
//                        } catch (e) {
//                            sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                            return;
//                        }
                    }
                });
            } else {
                loadPageByAjax('freunde');
            }
            if ($(".notification").length == 0) {
                // hideNotificationBar();
            }
        }
    });
}



function denyFriendshipRequest(el, e) {
    e.preventDefault();
    var userId = $(el).attr('id');
    var notificationId = $(el).closest("div").attr("id");
    flashModal(i18n.gettext('Rejecting friendship...'));
    $.ajax({
        type: "POST",
        url: "notifications",
        data: "denyFriendship=" + userId + "&markNotificationAsSeen=" + notificationId,
        success: function (data) {
            // on ajax success:
            $(".notification#" + notificationId).remove();
            flashModal(data);
            reInitNotificationIndicator();
            loadPageByAjax('freunde');
            if ($(".notification").length == 0) {
                // hideNotificationBar();
            }
        }
    });
}


function reInitNotificationIndicator() {
    var numOfNotifications = $(".notification").length;
    if (numOfNotifications > 0) {
        markNotificationsRed();
    } else {
        markNotificationsNormal();
    }
}

function hideNotificationBar() {
    $(".notifications").slideUp();
    $(".notifications-content").getNiceScroll().hide();
    $(".notifications-content").getNiceScroll().resize();
    $(document).off('click.close-notifications');
    $(document).off('mousedown.close-notifications');
    $(".js-notifications-toggle").removeClass("active");
    if ($("html").hasClass("smaller-than-tablet")) {
        reenablePageScroll();
    }
}
