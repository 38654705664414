function manageStudypalModal(el) {
	$("#manage-studypal-modal").data("studypal-id",$(el).data("studypal-id"));

	// don't allow management of the creator by anyone (creator himself included)
	if ($(el).data("creator") === 1) {
		flashModal("Der Ersteller eines Kartensatzes kann nicht verwaltet werden.", "default", "error");
	} else {
		// don't allow moderators to manage other moderators, only creator may manage moderators
		var creatorId = $("#manage-studypal-modal").data("creator-id");
		if (($(el).data("moderator") === 1) && ($("#userKey").val() != creatorId)) {
			flashModal("Du darfst andere Moderatoren nicht verwalten.", "default", "error");
		} else {
			// if banned user, show unban button, else show ban button
			if ($(el).data("banned") === 1) {
				$(".js-unban-studypal-btn").show();
				$(".js-ban-studypal-btn").hide();
				$(".js-make-studypal-moderator-btn").hide();
				$(".js-demote-studypal-moderator-btn").hide();
			} else {
				$(".js-unban-studypal-btn").hide();
				$(".js-ban-studypal-btn").show();

				// if moderator, show drop mod status button, else show make mod button
				if ($(el).data("moderator") === 1) {
					$(".js-demote-studypal-moderator-btn").show();
					$(".js-make-studypal-moderator-btn").hide();
				} else {
					$(".js-demote-studypal-moderator-btn").hide();
					$(".js-make-studypal-moderator-btn").show();
				}
			}
			$("#manage-studypal-modal").modal();
		}
	}
}

function banStudypalBtnClick() {
	var boxId = $("#boxId").val();
	var studyPalId = $("#manage-studypal-modal").data("studypal-id");
	banCardboxLearner(boxId,studyPalId);
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").data("banned",1);
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").removeData("moderator");
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").removeAttr("data-moderator");
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").find(".rank-badge").remove();
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").prependTo(".js-banned-users .user-display-container");
	// show banned-users container if it was hidden
	if ($(".js-banned-users").hasClass("hidden")) {
		$(".js-banned-users").removeClass("hidden");
	}
	// update studypal counter
	$(".js-studypals-quantity").text(parseInt($(".js-studypals-quantity").text()) - 1);
}

function unbanStudypalBtnClick() {
	var boxId = $("#boxId").val();
	var studyPalId = $("#manage-studypal-modal").data("studypal-id");
	unbanCardboxLearner(boxId,studyPalId);
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").data("banned",0);
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").prependTo(".js-unbanned-users .user-display-container");
	// hide banned-users container if it is now empty
	if (!$(".js-banned-users .js-user-display").length) {
		$(".js-banned-users").addClass("hidden");
	}
	// update studypal counter
	$(".js-studypals-quantity").text(parseInt($(".js-studypals-quantity").text()) + 1);
}

function makeStudypalModeratorBtnClick() {
	var boxId = $("#boxId").val();
	var studyPalId = $("#manage-studypal-modal").data("studypal-id");
	nominateCardboxLeader(boxId,studyPalId);
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").data("moderator",1);
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").find(".userpic").append(' \
		<div class="rank-badge moderator"> \
			' + i18n.gettext("MODERATOR") + ' \
		</div> \
	');
}

function demoteStudypalModeratorBtnClick() {
	var boxId = $("#boxId").val();
	var studyPalId = $("#manage-studypal-modal").data("studypal-id");
	dropCardboxLeader(boxId,studyPalId);
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").removeData("moderator");
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").removeAttr("data-moderator");
	$(".js-user-display[data-studypal-id='" + studyPalId + "']").find(".rank-badge").remove();
}




function nominateCardboxLeader(boxId, studyPalId) {
    $.get( "kartensatz-karten-" + boxId + "?nominateLeader=" + studyPalId, function( data ) {
        flashModal( data );
    });
}

function dropCardboxLeader(boxId, studyPalId) {
    $.get( "kartensatz-karten-" + boxId + "?dropLeader=" + studyPalId, function( data ) {
        flashModal( data );
    });
}

function banCardboxLearner(boxId, studyPalId) {
    $.get( "kartensatz-karten-" + boxId + "?banLearner=" + studyPalId, function( data ) {
        flashModal( data );
    });
}

function unbanCardboxLearner(boxId, studyPalId) {
    $.get( "kartensatz-karten-" + boxId + "?unbanLearner=" + studyPalId, function( data ) {
        flashModal( data );
    });
}
