function copyPackageToUser() {
    var packageId = $("#packageId").val();
    var userId = $('.copy-package-to-user-input').val();
    // var evenIfCopyExists = $("#evenIfCopyExists").is(":checked") ? 1 : 0;
	var nonce = getNonce();
    shortInfo(i18n.gettext('Copying...'));
	$.ajax({
          // retry-sensitive
        type: "POST",
        url: "package-" + packageId,
        data: "assignCopyTo=" + userId + "&nonce=" + nonce,
        success: function(data){
              shortInfo(data);
        }
    });
    
    
}
