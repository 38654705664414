function loadAds() {
    
    /*
    if (($(".ads-bar").length > 0 && $(".ads-bar").is(":visible")) || ($(".ad-banner-top-container").length > 0 && $(".ad-banner-top-container").is(":visible"))) {
        if (typeof ADNPM != 'undefined' && ADNPM !== null) {
            ADNPM.loadAll();
            // registerAdImpression();
        }
    }
    */
   
}

function registerAdImpression() {
    var pagehash = $('#pagehash').val();
    $.ajax({
        type: "POST",
        url: "registerAdImpression",
        data: "pagehash=" + pagehash
    });
}