function createNewPageBtnClick() {
    $("#createPageForm").show();
    $("#createPageBtn").hide();
}

function saveNewPageBtnClick() {
    var url = $("#url").val();
    var nonce = getNonce();
    flashModal(i18n.gettext('Saving...'));
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "manage-pages",
        data: "do=create&url=" + url + "&nonce=" + nonce,
        success: function (data) {
//            try {
                var obj = JSON.parse(data.trim());
                flashModal(obj.answer);
                if (obj.success) {
                    window.location = url;
                }
                $("#createPageBtn").show();
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        }
    });
}

function exportSalesData() {
    var selectedDay = $("#selectedDay").val();
    var selectedMonth = $("#selectedMonth").val();
    var selectedYear = $("#selectedYear").val();
    var selectedState = $("#selectedState").val();
    var selectedMerchant = $("#selectedMerchant").val();
    var searchTerm = $("#searchTerm").val();
    window.open('manage_sales?xls=1&selectedDay=' + selectedDay
            + '&selectedMonth=' + selectedMonth
            + '&selectedYear=' + selectedYear
            + '&selectedState=' + selectedState
            + '&selectedMerchant=' + selectedMerchant
            + '&searchTerm=' + searchTerm);
}

function salesFormInputKeypress(e) {
    e = !e ? event : e;
    debug("Keycode: " + e.keyCode);
    tastenCode = e.keyCode ? e.keyCode : e.which;
    if (tastenCode == 13) {
        e.preventDefault();
        var selectedDay = $("#selectedDay").val();
        var selectedMonth = $("#selectedMonth").val();
        var selectedYear = $("#selectedYear").val();
        var selectedState = $("#selectedState").val();
        var selectedMerchant = $("#selectedMerchant").val();
        var searchTerm = $(".js-search-term").val();
        var num = $(".js-num").val();
        loadPageToCenter('manage_sales?selectedDay=' + selectedDay
                + '&selectedMonth=' + selectedMonth
                + '&selectedYear=' + selectedYear
                + '&selectedState=' + selectedState
                + '&selectedMerchant=' + selectedMerchant
                + '&searchTerm=' + searchTerm
                + '&num=' + num);
    }
}

function manageUsersSearch(e) {
    if (e.keyCode == 13) {
        refreshUserList();
    }
}

function refreshUserList() {
    var selectedYear = $('#selectedYear').val();
    var selectedMonth = $('#selectedMonth').val();
    var selectedDay = $('#selectedDay').val();
    var kindOfUserList = $('#kindOfUserList').val();
    var filter = $("#filter").val();
    var referrer = $('#referrer').val();
    var searchText = encodeURIComponent($('.js-search-friends-input').val());
    debug("We have a search text: " + searchText);
    loadPageByAjax('manage_users?kindOfUserList=' + kindOfUserList + '&year=' + selectedYear + "&month=" + selectedMonth + "&day=" + selectedDay + "&refword=" + referrer + "&filter=" + filter + "&search=" + searchText);
}

function exportUserList() {
    var selectedYear = $('#selectedYear').val();
    var selectedMonth = $('#selectedMonth').val();
    var selectedDay = $('#selectedDay').val();
    var kindOfUserList = $('#kindOfUserList').val();
    var filter = $("#filter").val();
    var referrer = $('#referrer').val();
    window.open('manage_users?kindOfUserList=' + kindOfUserList + '&year=' + selectedYear + "&month=" + selectedMonth + "&day=" + selectedDay + "&refword=" + referrer + "&filter=" + filter + "&export=xlsx");
}

function showPlaceholdersOrdersBills() {
    var infoString =    "<b>%%website%%</b> : " + i18n.gettext("URL of application") + "<br>" +
                        "<b>%%date%%</b> : " + i18n.gettext("Current date") +
                        "<br>" +
                        "<b>%%userid%%</b> : " + i18n.gettext("Numeric User ID") + "<br>" +
                        "<b>%%usermail%%</b> : " + i18n.gettext("Mail address of user") + "<br>" +
                        "<b>%%salutation%%</b> : " + i18n.gettext("Salutation (like Mr or Mrs)") + "<br>" +
                        "<b>%%userfirstname%%</b> : " + i18n.gettext("First name of user") + "<br>" +
                        "<b>%%userlastname%%</b> : " + i18n.gettext("Last name of user") + "<br>" +
                        "<b>%%usercompanydata%%</b> : " + i18n.gettext("Name, Tax-No. and Address of the company of the user") + "<br>" +
                        "<br>" +
                        "<b>%%orderno%%</b> : " + i18n.gettext("Order no.") + "<br>" +
                        "<b>%%orderdate%%</b> : " + i18n.gettext("Date of order") + "<br>" +
                        "<br>" +
                        "<b>%%protext%%</b> : " + i18n.gettext("Informational text for customers who ordered PRO version of the application") + "<br>" +
                        "<b>%%boughtcoupontext%%</b> : " + i18n.gettext("Informational text for orders which are delivered as a coupon code (usable for instance as present)") + "<br>" +
                        "<br>" +
                        "<b>%%productstable%%</b> : " + i18n.gettext("Table of ordered products, including pricing") + "<br>" +
                        "<b>%%intermediatesum%%</b> : " + i18n.gettext("Sum of all prices") + "<br>" +
                        "<b>%%nettosum%%</b> : " + i18n.gettext("Sum without taxes") + "<br>" +
                        "<b>%%salestax%%</b> : " + i18n.gettext("Tax amount") + "<br>" +
                        "<b>%%taxsum%%</b> : " + i18n.gettext("Sum of taxes") + "<br>" +
                        "<b>%%ordersum%%</b> : " + i18n.gettext("Final sum of order") + "<br>" +
                        "<br>" +
                        "<b>%%paypalbutton%%</b> : " + i18n.gettext("Button for paying by Paypal") + "<br>" +
                        "<b>%%creditcardlink%%</b> : " + i18n.gettext("Link to payment by credit card") + "<br>" + 
                        "<b>%%dotpaylink%%</b> : " + i18n.gettext("Link to payment by Dotpay");
    generalPurposeModal(infoString);
}

function createInfoMail(el) {
    $('#createEditMailBlock').show();
    $(el).hide();
    $("#content").tinymce(editorConfig($("#static-content")));
    $("#mailId").val("");
}

function cancelSaveEditInfoMail() {
    $('#createEditMailBlock').hide();
    $('#showCreateEditMailForm').show();
    removeAllTinyMces();
}

function editInfoMail(el) {
    $('#createEditMailBlock').hide();
    $('#showCreateEditMailForm').show();
    removeAllTinyMces();
    $('#createEditMailBlock').show();
    $('#showCreateEditMailForm').hide();
    var id = $(el).data("id");
    $.ajax({
        type: "GET",
        url: "manage_info_mails",
        data: "getInfoMail=" + id,
        context: this,
        success: function (data) {
//            try {
                var obj = JSON.parse(data.trim());
                $("#subject").val(obj.subject);
                $("#content").html(obj.content);
                $('#sendDaysAfterRegistration').val(obj.sendDaysAfterRegistration);
                $("#content").tinymce(editorConfig($("#static-content")));
                $("#content").css("visibility", "visible");
                $("#mailId").val(id);
                $("#infoMailLocale").val(obj.locale);
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        }
    });
}

function deleteInfoMail(el) {
    if (confirm(i18n.gettext("Do you really want to delete the mail?"))) {
        var id = $(el).data("id");
        $.ajax({
            type: "GET",
            url: "manage_info_mails",
            data: "deleteInfoMail=" + id,
            context: this,
            success: function (data) {
                flashModal(data);
            }
        });
    }
}

function saveInfoMail() {
    var subject = encodeURIComponent($('#subject').val());
    var content = encodeURIComponent($('#content').html());
    var sendDaysAfterRegistration = $('#sendDaysAfterRegistration').val();
    var locale = $('#infoMailLocale').val();
    var id = $("#mailId").val();
    var nonce = getNonce();
    flashModal(i18n.gettext('Saving...'));
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "manage_info_mails",
        data: "id=" + id + "&subject=" + subject + "&content=" + content + "&sendDaysAfterRegistration=" + sendDaysAfterRegistration + "&locale=" + locale + "&nonce=" + nonce,
        success: function () {
            flashModal(i18n.gettext('Saved!'));
            removeAllTinyMces();
            loadPageToCenter('manage_info_mails');
        }
    });
}

function showSalesMailForm() {
    $("#salesMailForm").show();
    removeAllTinyMces();
    $("#salesMailText").tinymce(editorConfig($("#salesMailText")));
}

function sendSalesMail(test) {
    test = (typeof test === "undefined") ? 0 : test;
    var selectedDay = $("#selectedDay").val();
    var selectedMonth = $("#selectedMonth").val();
    var selectedYear = $("#selectedYear").val();
    var selectedMerchant = $("#selectedMerchant").val();
    var selectedState = $("#selectedState").val();
    var searchTerm = $("#searchTerm").val();
    var subject = $("#salesMailSubject").val();
    var text = encodeURIComponent($("#salesMailText").val());

    flashModal(i18n.gettext("Beginning to send. Next popup will appear at the end of sending."),4000);

    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "admin",
        data: "selectedDay=" + selectedDay + "&selectedMonth=" + selectedMonth + "&selectedYear=" + selectedYear + "&selectedMerchant=" + selectedMerchant + "&selectedState" + selectedState
                + "&searchTerm=" + searchTerm + "&salesMailSubject=" + subject + "&salesMailText=" + text + "&test=" + test,
        retryLimit: 0,
        timeout: (60 * 1000),
        success: function (data) {
            
            if (!test) {
                $("#salesMailForm").hide();
            }
            flashModal(data, 4000);
        }
    });

}

function sendSalesMailTest() {
    sendSalesMail(1);
}

function setKpiRange() {
    var start = $('#start').val();
    var end = $('#end').val();
    var scale = $('#scale').val();
    loadPageToCenter('kpi?start=' + start + '&end=' + end + '&scale=' + scale);
}

function undeleteCardset(boxId, offset, limit) {
    $.ajax({
        type: "POST",
        url: "manage_deleted_cardsets?offset=" + offset + "&limit=" + limit,
        data: "undelete=" + boxId,
        success: function (data) {
            refreshPage();
            window.open('cardset-' + boxId, '_blank');
            flashModal(i18n.gettext("Cardset restored."));
        }
    });
}