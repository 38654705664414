/* NEW DISCUSSION POSTS */

$(document).ready(function () { // doc ready, because needs var from nicescroll.js file
    // New discussion posts scrolling like Facebook activity bar
    if (window.jQuery && $.fn.niceScroll !== undefined && $(".new-discussion-posts-content").length > 0) {
        $(".new-discussion-posts-content").niceScroll(niceScrollConfig);
    }
});

// NEW DISCUSSION POSTS TOGGLE
function newDiscussionPostsToggleClick() {
    if ($(".new-discussion-posts").css("display") !== 'block') {
        
        $(".new-discussion-posts").show();
        
			if ($("html").hasClass("smaller-than-tablet")) {
				disablePageScroll();
			}
        // per AJAX neue Inhalte laden

//			$.get( "new-discussion-posts?module=new-discussion-posts&contentOnly=1", function( data ) {
//				$( ".new-discussion-posts-content" ).html( data );
//				alert( "Load was performed." );
//			});

        $.ajax({
            type: "POST",
            url: "new-discussion-posts",
            data: "module=new-discussion-posts-content",
            success: function (data) {
					$(".new-discussion-posts-content").html(data);
					$(".new-discussion-posts-content").getNiceScroll().show();
					$(".new-discussion-posts-content").getNiceScroll().resize();
            }
        });

        // add function to close container on click outside
        // setTimeout needed or close function receives button click
       setTimeout(function () {
           $(document).on('mousedown.close-new-discussion-posts', function (event) {
               var clicked = event.target;
               $(document).on('click.close-new-discussion-posts', function () {
                   if ($(clicked).closest(".new-discussion-posts").length !== 1) {
                       hideNewDiscussionPostsBar();
                   } else if (($(clicked).hasClass('click')) || ($(clicked).is("a"))) {
                   		hideNewDiscussionPostsBar();
                   }
               });
           });
       }, 10);
    }
}



function closeNewDiscussionPosts() {
    hideNewDiscussionPostsBar();
}



function hideNewDiscussionPostsBar() {
    $(".new-discussion-posts").slideUp();
    $(".new-discussion-posts-content").getNiceScroll().hide();
    $(".new-discussion-posts-content").getNiceScroll().resize();
    $(document).off('click.close-new-discussion-posts');
    $(document).off('mousedown.close-new-discussion-posts');
    $(".js-new-discussion-posts-toggle").removeClass("active");
	$(".js-new-discussion-posts-toggle").removeClass("new");
	$(".js-new-discussion-posts-toggle").find(".new").removeClass("new");
	if ($("html").hasClass("smaller-than-tablet")) {
		reenablePageScroll();
	}
}
