function createTestFromBoxModal() {
	$("#create-test-modal").modal();
}

function createTestFromBoxModalAction() {
	var boxId = $('#boxId').val();
	var layoutOption = "";
	var selected = $("input[type='radio'][name='layout']:checked");
	if (selected.length > 0) {
	    layoutOption = selected.val();
	}
	var max = $("#create-test-max-questions").val();
	var swap = $("#create-test-swap").is(":checked");
	var selection = 1;
	window.open('doc?groupKey=' + boxId + '&layout=' + layoutOption + "&max=" + max + "&swap=" + swap + "&selection=" + selection, 'window name', 'window settings');
	return false;
}
