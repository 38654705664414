// MAIN-MENU-MOBILE
	function openMainMenuMobile() {
		if (!$(".js-main-menu-mobile").hasClass("active")) {
			$(".js-main-menu-mobile").addClass("active");
			$(".js-main-menu-mobile-toggle").addClass("active");
			$(".js-menu-dark-bg").addClass("active");
			disablePageScroll();
			// add function to close container on click outside
			// setTimeout needed or close function receives button click
			setTimeout(function() {
				$(document).on('click.close-mainmenumobile', function(){
				    $(".js-main-menu-mobile").removeClass("active");
				    $(".js-main-menu-mobile-toggle").removeClass("active");
				    $(".js-menu-dark-bg").removeClass("active");
				    $(document).off('click.close-mainmenumobile');
				    reenablePageScroll();
				});
			},10);
		}
		$(".js-main-menu-mobile-toggle").blur();
	}



// MAIN-MENU CLICK ACTIVE CLASS
function mainMenuMobileUpdateActiveLink(event) {
	$(".js-main-menu-mobile").find("a").removeClass("active");
	$(event.target).addClass("active");
}

function mainMenuUpdateActiveLink(event) {
	$(".js-main-menu").find("a").removeClass("active");
	$(event.target).addClass("active");
}

function topbarLogoLinkClick() {
	$(".js-topbar").find("a").removeClass("active");
	$("a[href='" + $("html").data("approot") + "']").addClass("active");
}



// SECONDARY MENU ACTIVE CLASS
function secondaryMenuUpdateActiveButton(el) {
	$(el).toggleClass("active");
}



// REGULAR NAV CLICK ACTIVE CLASS
function navBtnActive(el) {
	$(el).siblings("button").removeClass("active");
	$(el).addClass("active");
}

touchFriendlyHover($(".secondary-menu").find("button"));
touchFriendlyHover($(".main-menu-mobile-toggle"));

// TOUCH FRIENDLY HOVER EFFECTS (no hover effect, .hover class is added on first tap and instantly removed again)
// provide only a rootNode in cases where no elements are added dynamically
// provide a rootNode and a childNode in cases where elements are added dynamically. rootNode has to be persistent.
function touchFriendlyHover(rootNode, childNodeString) {
	var childNodeString = (childNodeString) ? childNodeString : "";
	$(document).ready(function () {
		rootNode.on("mouseenter", childNodeString, function() {
			$(this).addClass("hover");
		});
		rootNode.on("mouseleave", childNodeString, function() {
			$(this).removeClass("hover");
		});
		rootNode.on("click", childNodeString, function() {
			$(this).removeClass("hover");
		});
	});
}

function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function changeToIframeView() {
    $("#iframeLinkToBox").show();
    $(".topbar").hide();
    $(".topbar").css("top","40px");
    $(".learning-top-bar").css("top","40px");
    
    var cardBoxName;
    if ($("#cardBoxName").length > 0) {
        cardBoxName = $("#cardBoxName").val();
    }
    if (cardBoxName == "undefined") {
        cardBoxName = i18n.gettext(" this cardset ");
    }
    
    // $("#iFrameCardboxLinkName").html(i18n.gettext("Zu") + " <b>" + cardBoxName + "</b> " + i18n.gettext("at") + " " + $("html").data('apptitle'));
    $("#iFrameCardboxLinkName").html("<b>" + cardBoxName + "</b> " + i18n.gettext("at") + " " + $("html").data('apptitle'));
    
}

if (inIframe()) {
    // changeToIframeView();
}