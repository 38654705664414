/* MANAGE CATEGORIES MODAL */

// open manage categories modal button
function manageCategoriesModal() {
    $("#manage-categories-modal").modal();
    showAjaxLoader("#manage-categories-modal .categories");
    var boxId = $("#boxId").val();
    $.getJSON("kartensatz-karten-" + boxId + '?categories', function (data) {
        // reset and build categories HTML
        hideAjaxLoader();
        $("#manage-categories-modal .categories").html('');
        var categoryString = '';
        $.each(data, function (index, value) {
            // category key = value.id
            // category name = value.name
            categoryString += ' \
					<div id="manageCategory-' + value.id + '" class="input-x w100 manageCategory-' + value.id + '"> \
						<input type="text" class="w100"  onblur="categoryInputBlur(this)" tabindex="20" id="editCategory-' + value.id + '" value="' + value.name + '"';
            if (!value.removeOrEditAllowed) {
                categoryString += ' disabled=\"disabled\" readonly=\"readonly\"';
            }
            categoryString += '>';
            if (value.removeOrEditAllowed) {
                categoryString += '<button class="remove-btn" onclick="modalRemoveCategory(this)" id="removeCategory-' + value.id + '"> \
                                <i class="fa fa-minus-square-o"></i> \
                    </button>';
            }
            categoryString += '</div>';
        }); // end each
        $("#manage-categories-modal .categories").html(categoryString);
    });
}

function manageCategoriesModalAddCategory(el) {
    // $(el).parent().before
    $('.modal.current').find('.categories').append(' \
			<div class="input-x w100"> \
	    		<input type="text" class="w100" tabindex="20" onblur="categoryInputBlur(this)"> \
    			<button class="remove-btn" onclick="modalRemoveCategory(this)"> \
    				<i class="fa fa-minus-square-o"></i> \
    			</button> \
    		</div> \
		');
    $(el).closest(".modal").find("input:last").focus();
}

// close manage categories modal
function manageCategoriesModalAction() {
    // remove empty inputs (delayed, because modal fades out)
    setTimeout(function () {
        $('#manage-categories-modal').find("input").filter(function () {
            return this.value == '';
        }).parent().remove();
    }, 200);

    //TODO Karteikarten-Liste refreshen, damit auch Pulldown-Menue fuer Kategorien aktuell ist

    var boxId = $("#boxId").val();

    flashModal(i18n.gettext('Categories were changed.'));
}
