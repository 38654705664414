function saveSetting(name, val) {
    if (name == 'sendReminderMail' && val == 1 && $("html").data("userlevel") == "FREE") {
        shortInfo(i18n.gettext("This option is available only to PRO users."));
        $("#sendReminderMail").prop("checked", false);
    } else if (name != 'oldpassword' && name != 'password' && name != 'passwordRepetition') {
        shortInfo(i18n.gettext("Saving..."));
        $.ajax({
            type: "POST",
            url: "settings",
            data: "option=" + name + "&value=" + val,
            success: function (data) {
//                try {
                var obj = JSON.parse(data.trim());
                if (name == 'locale' || name == 114) {
                    window.location.href = ('settings');
                } else if (name == 67) {
                    window.location.href = ('settings?gotoTab=1');
                } else {
                    debug("No need to refresh the whole site.");
                    shortInfo(obj.message, 2000);
                }
//                } catch (e) {
//                    sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                    return;
//                }
            },
            error: function () {
                shortInfo(i18n.gettext("Error while saving the setting."));
            }
        }); // end ajax
    }
}



function saveMail() {
    shortInfo(i18n.gettext("Saving..."));
    var val = encodeURIComponent($("#userMail").val());
    $.ajax({
        type: "POST",
        url: "settings",
        data: "save-mail=" + val,
        success: function (data) {
//            try {
            var obj = JSON.parse(data.trim());
            shortInfo(obj.message, 4000);
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        },
        error: function () {
            shortInfo(i18n.gettext("Error while saving the setting."));
        }
    }); // end ajax
}

function saveAdditions() {
    shortInfo(i18n.gettext("Saving..."));

    var page = $('#pagehash').val();

    var firstAddition = $('#firstAddition').val();
    var secondAddition = $('#secondAddition').val();
    var thirdAddition = $('#thirdAddition').val();
    var fourthAddition = $('#fourthAddition').val();
    var fifthAddition = $('#fifthAddition').val();
    var sixthAddition = $('#sixthAddition').val();
    var seventhAddition = $('#seventhAddition').val();
    var eighthAddition = $('#eighthAddition').val();
    var ninthAddition = $('#ninthAddition').val();
    var tenthAddition = $('#tenthAddition').val();
    var boxId = null;
    if ($('#boxId').length > 0) {
        boxId = $('#boxId').val();
    }
    var url = "settings";
    var enableCustomSchedule = false;
    if (boxId != null && boxId != '' && boxId != 0) {
        url = "kartensatz-einstellungen-" + boxId;
        enableCustomSchedule = $("#enableCustomSchedule").is(":checked");
    }
    var timestamp=0;
    if ($('#timestamp').length > 0) {
        timestamp = $('#timestamp').val();
    }
    if ($('.calendar').length > 0) {
        $('.calendar').fadeTo(200, 0.75, 'swing');
    }
    $.ajax({
        type: "POST",
        url: url,
        data: "timestamp=" + timestamp + "&enableCustomSchedule=" + enableCustomSchedule + "&save-additions=1" + "&firstAddition=" + firstAddition + "&secondAddition=" + secondAddition
                + "&thirdAddition=" + thirdAddition + "&fourthAddition=" + fourthAddition
                + "&fifthAddition=" + fifthAddition + "&sixthAddition=" + sixthAddition
                + "&seventhAddition=" + seventhAddition + "&eighthAddition=" + eighthAddition
                + "&ninthAddition=" + ninthAddition + "&tenthAddition=" + tenthAddition,
        success: function (data) {
            var obj = JSON.parse(data.trim());
            if (page.startsWith("calendar")) {
                $.ajax({
                    type: "POST",
                    url: "calendar",
                    data: "module=calendar&boxId=" + boxId + "&timestamp=" + timestamp,
                    success: function(data) {
                        $('.calendar').html(data);
                        $('.calendar').fadeTo(100, 1.0, 'swing');
                    }
                });
            } else if (page.startsWith("cardset")) {
                refreshPage();
            }
            shortInfo(obj.message, 4000);
        },
        error: function () {
            shortInfo(i18n.gettext("Error while saving the intervals."));
        }
    });
}

function saveAutomatisms() {
    // var notKnownLimit = $('#notKnownLimit').val();
    // "notKnownLimit=" + notKnownLimit + 
    var questionsPerDay = $('#questionsPerDay').val();
    var newQuestionsPerDay = $('#newQuestionsPerDay').val();
    
    let pauseDays = []
    if ($("#learnplan-limits-block").length > 0) {
        $("#learnplan-limits-block input:checkbox:checked").each(function () {
            var id = $(this).attr("id");
            if (id.startsWith("pause_")) {
                var day = id.substring(6);
                pauseDays.push(day);
            }
        });
    }
    let pauseDaysString = pauseDays.join(",");
    
    if (pauseDays.length > 0 && questionsPerDay == 0) {
        $("#questionsPerDayBlock").css("border", "3px solid red");
        generalPurposeModal(i18n.gettext("You must set an upper limit of cards per day if you want to select at least one pause day."));
    } else {
        shortInfo(i18n.gettext("Saving..."));
        $("#questionsPerDayBlock").css("border", "0px");
        var url = $('#pagehash').val();
        $.ajax({
            type: "POST",
            url: url,
            data: "questionsPerDay=" + questionsPerDay + "&newQuestionsPerDay=" + newQuestionsPerDay + "&pauseDays=" + pauseDaysString,
            success: function (data) {
                var obj = JSON.parse(data.trim());
                shortInfo(obj.message, 2000);
            },
            error: function () {
                shortInfo(i18n.gettext("Error while saving the study schedule settings."));
            }

        }); // end ajax
    }
}



function moveSchedule() {
    shortInfo(i18n.gettext("Moving..."));
    var pushPlanBy = $('#pushPlanBy').val();
    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "settings",
        data: "pushPlanBy=" + pushPlanBy + "&nonce=" + nonce,
        success: function (data) {
//            try {
            var obj = JSON.parse(data.trim());
            shortInfo(obj.message, 2000);
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        },
        error: function () {
            shortInfo(i18n.gettext("Error while adding delay to your study schedule."));
        }
    }); // end ajax
}



function doChangePassword() {
    shortInfo(i18n.gettext("Saving..."));
    debug("*** Executing doChangePassword. ***");
    var oldpassword = encodeURIComponent($('#oldpassword').val());
    var password = encodeURIComponent($('#password').val());
    var passwordRepetition = encodeURIComponent($('#passwordRepetition').val());
    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "settings",
        data: "oldpassword=" + oldpassword + "&changePassword=" + password + "&changePasswordRepetition=" + passwordRepetition + "&nonce=" + nonce,
        success: function (data) {
//            try {
            var obj = JSON.parse(data.trim());
            shortInfo(obj.message);
//                shortInfo(obj.message, 2000);
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        },
        error: function () {
            shortInfo(i18n.gettext("Error while changing the password."));
        }
    }); // end ajax
}



function changeUsername() {
    var userName = encodeURIComponent($('#userName').val());
    $.ajax({
        type: "POST",
        url: "settings",
        data: "userName=" + userName,
        success: function (data) {
//            try {
            var obj = JSON.parse(data.trim());
            shortInfo(obj.message, 2000);
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        },
        error: function () {
            shortInfo(i18n.gettext("Error while changing the username."));
        }
    }); // end ajax
}



function resetAllDelays() {
    shortInfo(i18n.gettext("Resetting..."));
    $.ajax({
        type: "POST",
        url: "settings",
        data: "resetAllDelays=1",
        success: function (data) {
//            try {
            var obj = JSON.parse(data.trim());
            shortInfo(obj.message, 2000);
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        },
        error: function () {
            shortInfo(i18n.gettext("Error while resetting the study schedule delays."));
        }
    }); // end ajax
}



function resetStats(userId) {
    var additionalParameter = '';
    userId = userId || 0;
    if (!isNaN(userId) && userId > 0) {
        additionalParameter = "&userId=" + userId;
    }
    var r = confirm(i18n.gettext("Reset really?"));
    if (r === true) {
        shortInfo(i18n.gettext("Resetting..."));
        $.ajax({
            type: "POST",
            url: "settings",
            data: "resetstats=1" + additionalParameter,
            success: function (data) {
                var obj = JSON.parse(data.trim());
                shortInfo(obj.message, 2000);
            },
            error: function () {
                shortInfo(i18n.gettext("Error while resetting the learning statistics."));
            }
        }); // end ajax
    }
}



function deleteMe() {
    var r = confirm(i18n.gettext("Really delete the account?"));
    if (r === true) {
        shortInfo(i18n.gettext("Deleting..."));
        $.ajax({
            type: "POST",
            url: "settings",
            data: "deleteme=1",
            success: function (data) {
//                try {
                var obj = JSON.parse(data.trim());
                shortInfo(obj.message, 2000);
                // Google Sign out - yes or no?
                try {
                    googleSignOut();
                } catch (e) {
                    window.location.href = 'logout';
                }
//                } catch (e) {
//                    sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                    return;
//                }
            },
            error: function () {
                shortInfo(i18n.gettext("Error while deleting the account."));
            }
        }); // end ajax
    }
}



function bindSettingsHandlers() {

    // unbind first, so event handlers aren't duplicated!

    $("#settings select").off('settingsHandlers');
    $("#settings select").on('change.settingsHandlers', function () {
        if (!$(this).hasClass("noSaveOnChange")) {
            var name = $(this).attr('name');
            var val = $(this).val();
            saveSetting(name, val);
        }
    });

    $('#settings input[type="radio"]').off('settingsHandlers');
    $('#settings input[type="radio"]').on('click.settingsHandlers', function () {
        var name = $(this).attr('name');
        var val = $(this).val();
        saveSetting(name, val);
    });

    $('#settings input[type="text"]').off('settingsHandlers');
    $('#settings input[type="text"]').on('blur.settingsHandlers', function () {
        var name = $(this).attr('name');
        var val = $(this).val();
        // saveSetting(name, val);
    });

    $('#settings input[type="checkbox"]').off('settingsHandlers');
    $('#settings input[type="checkbox"]').on('click.settingsHandlers', function () {
        var name = $(this).attr('name');
        var val = $(this).is(":checked");
        // alert ('val is: ' + val);
        if (val == 'on' || val == 'true' || val == true) {
            val = 1;
        } else if (val == 'off' || val == 'false' || val == false) {
            val = 0;
        }
        if (typeof name != "undefined") {
            debug("Saving option: " + name);
            saveSetting(name, val);
        }
    });

}

function setUserAttribute(option, value) {
    var values = "option=" + option + "&value=" + value;
    $.ajax({
        type: "POST",
        url: "settings",
        data: values
    }).done(function (data) {

    }).fail(function () {

    });
}

function exportAllCardsets() {
    if ($("html").data("prouser") == "1") {
        
        flashModal(i18n.gettext('Creating download-archive - please wait...'));
        document.location = 'export';
    } else {
        repeticoPromptModal(i18n.gettext('The complete export is available only to PRO users.'));
    }
}

function deactivateAllCustomStudyScheduleSettings() {
    var r = confirm(i18n.gettext("Really deactivate all individual study schedule settings?"));
    if (r === true) {
        $.ajax({
            type: "POST",
            url: "settings",
            data: "deactivateAllCustomStudyScheduleSettings=1"
        }).done(function (data) {
            // if (data == 1) {
            shortInfo(i18n.gettext("Done!"));
            // gotoSettingsTab(4);
            $("#customStudySettingsBlock").hide();
            // }
        }).fail(function () {

        });
    }
}