/* INVITE FRIENDS MODAL */

// open invite friends modal button
function openInviteModal(el, e) {
    // check if link inside usermenu was clicked. If it was, call close usermenu function here and stop click bubbling, so pageScroll doesn't get reenabled!
    if ($(el).closest(".user-menu").length) {
        e.stopPropagation();
        closeUserMenu();
    }
    $("#invite-friends-modal").modal();
    setTimeout(function () {
        $("#invite-friends-modal").find("input").focus();
    }, 0);
}

function addFriendBtnClick() {
    $("#add-person-btn-div").before(' \
			<div class="input-x w100"> \
	    		<input type="text" class="friend-email w100" tabindex="20" onkeypress="inviteFriendKeyPress(event);"> \
    			<button class="remove-btn" type="button" onclick="removeInputX(this)"> \
    				<i class="fa fa-minus-square-o"></i> \
    			</button> \
    		</div> \
		');
    $(".modal").find("input:last").focus();
}

function inviteFriendKeyPress(e) {
    if (e.keyCode == 13) {
        addFriendBtnClick();
    }
}

function inviteModalAction() {
    var mails = [];

    $('#invite-friends-modal').find("input").each(function () {
        mails.push(encodeURIComponent($(this).val()));
    });

    flashModal(i18n.gettext('Sending invitations...'));

    $.ajax({
        type: "POST",
        url: "inviteafriend",
        data: "mails=" + mails,
        success: function (data) {
            setTimeout(function () {
                $('#invite-friends-modal').find("input").val('');
                $("#invite-friends-modal").find(".input-x:gt(0)").remove();
            }, 200);

            flashModal(data);

        }
    });
}
