/* ACTIVITY BAR */

$(document).ready(function () { // doc ready, because needs var from nicescroll.js file
	// Activity bar scrolling like Facebook activity bar
	if (window.jQuery && $.fn.niceScroll !== undefined && $(".activity-bar-content").length > 0) {
		$(".activity-bar-content").niceScroll(niceScrollConfig);
	}
});

if ($(".activity-bar").is(":visible")) {
	$(".activity-bar-toggle").addClass("active");
}

// for small layout, hide activity-bar on every page-load
if (!mqMinWidth1340.matches) {
	$(".activity-bar").addClass("hidden");
	// prevent FOUC, have activity-bar display: none, before hidden class is assigned
	$(".activity-bar").css("display", "block");
}

// ACTIVITY BAR TOGGLE
function activityBarToggleClick(el) {
	$(".activity-bar").toggleClass("hidden");
	if ($(".activity-bar").hasClass("hidden")) {
		$(".activity-bar").addClass("hide-setting");
		$(".activity-bar").removeClass("show-setting");
		$(".activity-bar").removeClass("show-explicit");
        $(".activity-bar-content").html('');
        $(".activity-bar").data("offset", 0);
        $(".activity-bar").data("loaded-from-offset", -1);
		debug("Set showActivityBar to false");
        setCookie("showActivityBar", false, 9999);
	} else {
		/* needed for the first time a hidden activity-bar is activated on small layout */
		$(".activity-bar-content").getNiceScroll().show();
		$(".activity-bar-content").getNiceScroll().resize();

		$(".activity-bar").removeClass("hide-setting");
		$(".activity-bar").addClass("show-setting");
		$(".activity-bar").addClass("show-explicit");
		debug("Set showActivityBar to true");
        setCookie("showActivityBar", true, 9999);
		refreshActivityBarInit();
	}
	// switch page layout between wide and small if browser size is wide
	if (mqMinWidth1340.matches) {
		// window width is equal or greater than 1340px
		if ($(".activity-bar").hasClass("hidden")) {
			$(".page-container").removeClass("wide");
		} else {
			$(".page-container").addClass("wide");
		}
	}
	$(".notifications").toggleClass("content-overlay");
	$(".new-discussion-posts").toggleClass("content-overlay");
}



function activityBarToggleInactive() {
	$(".activity-bar-toggle").removeClass("active");
}



function activityLikeBtnClick(el) {
	var likes = parseInt($(el).find(".activity-likes").text());
	if ($(el).hasClass("liked")) {
		$(el).find(".activity-likes").text(--likes);
		$(el).removeClass("liked");
	} else {
		$(el).find(".activity-likes").text(++likes);
		$(el).addClass("liked");
	}
}



function activityCommentBtnClick(el) {
	$(el).closest(".activity").find(".comments").toggle();
	autosize($(el).closest(".activity").find(".js-comment-textarea"));
}



function getTime() {
	// var time = $.now();
	var now = new Date();
	var date = now.getDate();
	if (date < 10)
		date = '0' + date;
	var month = now.getMonth();
	if (month < 10)
		month = '0' + month;
	var year = now.getFullYear();
	var displayTime = date + '.' + month + '.' + year + ' ' + now.getHours() + ':' + now.getMinutes();
	return displayTime;
}



function addActivityComment(el,e) {
	if (e.charCode == 13 && !e.shiftKey) {
		// Add comment to comment list
		$(el).after(' \
                <div class="comment"> \
                    <a href="profile-' + $('html').data('userid') + '"> \
                        <div class="userpic" style="width:30px; height:30px; background-image:url(' + $("html").data("userpiclink") + '); background-position: center; background-size:cover"></div> \
                    </a> \
                    <p class="activity-text"> \
                        <a href="profile-' + $("html").data("userid") + '" class="username bright-link">' + $("html").data("username") + '</a> \
                        ' + $(el).val() + ' \
                    </p> \
                    <p class="activity-sub-info"> \
                        <span class="activity-timeago">' + getTime() + '</span> \
                    </p> \
                </div> \
			');
		$(el).closest(".activity").find(".comment-btn").addClass("commented");
		var comments = parseInt($(el).closest(".activity").find(".activity-comments").text());
		$(el).closest(".activity").find(".activity-comments").text(++comments);
		// Send comment to server!
		/*
		 *
		 *
		 *
		 */
		e.preventDefault();
		$(".js-comment-textarea").val("").css("height", 20).blur();
		$("#activity-modal").find(".js-comment-textarea").css("height", 38); // activity modal comment input is higher than activity bar comment input!
	}
}



function refreshActivityBar(param, val, update) {
    
    if (typeof update === 'undefined') { update = false; }
    
    if (update === true) {
        $(".activity-bar").data("offset",0);
        $(".js-behind-activity-bar-content").off('inview');
    }

    debug("Refreshing activity bar");

	var data = "module=activity-bar-content";
    if (param != "undefined" && val != "undefined") {
        data += "&" + param + "=" + val;
    }
    
    var limit = $(".activity-bar").data("limit");
    var offset = $(".activity-bar").data("offset");
    
    var numOfVisibleActivities;
    var numOfActivities;
    
    data += "&limit=" + limit + "&offset=" + offset;
    
    if ($(".activity-bar").data("loaded-from-offset") != $(".activity-bar").data("offset") || update) {
        $(".activity-bar").data("loaded-from-offset",offset);
        // showAjaxLoader("activity-bar-content");
        $.ajax({
            type: "POST",
            url: "activity-bar",
            data: data,
            success: function (data) {

                hideAjaxLoader();
                if (offset == 0) {
                    $(".activity-bar-content").html(data);
                } else {
                    $(".js-behind-activity-bar-content").before(data);
                }

                $(".activity-bar-content").fadeIn();

                if (data.trim() == '') {
                    debug("Finally removing behind-activity-bar-content!");
                    $(".js-behind-activity-bar-content").remove();
                } else if (offset == 0) {
                    debug("Binding behind-activity-bar-content!");
                    if ($(".js-behind-activity-bar-content").length > 0) {
                        $(".js-behind-activity-bar-content").on('inview', function(event, isInView, visiblePartX, visiblePartY) {

                                debug("*** Ende der Liste in Sicht: Lade weitere Aktivitaeten! ***");
                                if (isInView) {
                                    refreshActivityBar(param, val);
                                }

                        });
                    }
                }

                offset += limit;
                $(".activity-bar").data("offset", offset);

            }
        });
    }

}

function refreshActivityBarInit(update) {
    
    // update === true means that the whole part of the activity list is refreshed - not appended below, so you can see the newest activities.
    
    if (typeof update === 'undefined') { update = false; }
    
	if ($(".activity-bar-toggle").length > 0) {
		if (!$(".activity-bar").hasClass("hidden")) {
//				if ($("#cardId").length > 0 && $("#cardId").val() > 0) {
//					refreshActivityBar("cardId", $("#cardId").val())
//				} else 
            if ($("#boxId").length > 0 && $("#boxId").val() > 0) {
                refreshActivityBar("boxId", $("#boxId").val(), update);
            } else if ($("#courseid").length > 0 && $("#courseid").val() > 0) {
                refreshActivityBar("courseId", $("#courseid").val(), update);
            } else if ($("#academyId").length > 0 && $("#academyId").val() > 0) {
                refreshActivityBar("academyId", $("#academyId").val(), update);
            } else if ($(".profile-user-container").find("#userId").length > 0) {
                var userId = $(".profile-user-container").find("#userId").val();
                refreshActivityBar("userId", userId, update);
            } else {
                refreshActivityBar();
            }
		}
	}
}
