/* CHANGE CARD POSITION MODAL */

	// open change-card-positon-modal button
	function changeCardPositionModal() {
		$("#change-card-position-modal").modal();
		setTimeout(function() {
			$("#change-card-position-modal").find("select").focus();
		},0);
	}

	// change card position
	function changeCardPositionModalAction() {
		closeModal();
		var cardId = $("#cardId").val();
		var boxId = $("#boxId").val();
		var newCardPos = $('#change-card-position-modal').find("select").val();
		debug("New position of card " + cardId + " should be " + newCardPos);
		$("#cardPos").html(newCardPos);
		flashModal(i18n.gettext('Changing position...'));
		$.ajax({
			type: "POST",
			url: "kartensatz-" + boxId,
			data: "cardId=" + cardId + "&moveToOrderIndex=" + newCardPos,
			success: function(d) {
				flashModal(i18n.gettext('Position of card was changed.'));
			}
		});
	}
