function exportCardsModal() {
	$("#export-cards-modal").modal();
}

function exportCardsModalAction() {
	var boxId = $('#boxId').val();
	var exportOption = "";
	var selected = $("input[type='radio'][name='export-e']:checked");
    var fieldSeparator = $("#fieldSeparator").val();
    var textSeparator = $("#textSeparator").val();
	if (selected.length > 0) {
	    exportOption = selected.val();
	}
    
	window.location.assign('export?groupKey=' + boxId + '&e=' + exportOption + "&fieldSeparator=" + fieldSeparator + "&textSeparator=" + textSeparator, 'window name', 'window settings');
    
	return false;
}
