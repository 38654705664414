// LEARN BUTTON
function startLearning() {
//    var buttonId = $("#learn-options-modal .action-btn").data('id');
//	var buttonArr = buttonId.split("-");

    var boxId = $('#learn-options-modal-cardset-selector').val();
    var isExam = $("#learn-options-modal #isExam").val();

    if (!isNaN(parseFloat(boxId)) && isFinite(boxId) && boxId > 0) {

        $.get("lernen-info?learnmodeInfo=1&boxId=" + boxId, function (data) {

            closeModal();

            var obj = JSON.parse(data.trim());
            var boxActive = obj.boxActive;
            var startToLearn = true;

            if ($("html").data("loggedin") == "1" && !boxActive) {
                // try to activate ...
                $.ajax({
                    type: "POST",
                    url: "main",
                    data: "activateAllowed=1",
                    async: false,
                    success: function (data) {

                        var obj = JSON.parse(data.trim());
                        if (obj.message.trim() != "") {
                            flashModal(obj.message);
                        }

                        var allowed = (obj.allowed);
                        if (allowed) {
                            $.ajax({
                                type: "POST",
                                url: "modul_kartensaetze",
                                data: "activateBox=" + boxId,
                                async: false,
                                success: function (data) {
                                    startToLearn = true;
                                }
                            });
                        } else {
                            freeInfoModal();
                            setProPurchaseTrigger('activate-cardbox-learning');
                            startToLearn = false;
                        }

                    }
                });

            }

            if (startToLearn) {
                if ((obj.numOfQuestions > 0 && obj.daysToWait == 0) || ($("html").data("loggedin") != "1")) {
                    loadPageByAjax("study-" + boxId + "?createNewTest=1" + "&isExam=" + isExam)
                } else {
                    // Wenn es der Leitner-Modus war, dann checken, ob wenigstens im Modus learnmode_notyetplayed
                    // etwas zu lernen ist
                    if (obj.kindoftest == "leitner") {
                        $.get("lernen-info?learnmodeInfo=1&kindOfTest=notyetplayed&boxId=" + boxId, function (data) {
                            obj = JSON.parse(data.trim());
                            if (obj.numOfQuestions > 0) {
                                loadPageByAjax("study-" + boxId + "?createNewTest=1" + "&isExam=" + isExam);
                            } else {
                                if (obj.categoriesPreselected) {
                                    flashModal(i18n.gettext('No cards to be learned in the schedule with the selected categories.'), 3500);
                                } else {
                                    flashModal(i18n.gettext('All categories are unselected.'));
                                }
                            }
                        });
                    } else {
                        if (obj.categoriesPreselected) {
                            flashModal(i18n.gettext('No cards to be studied in this study mode with the selected categories.'), 3500);
                        } else {
                            flashModal(i18n.gettext('All categories are unselected.'));
                        }
                    }
                }
            }
        });
    } else {
        $.get("lernen-info?learnmodeInfo=1", function (data) {
            setTimeout(function () {
                closeModal();
            }, 150);

//            try {
            var obj = JSON.parse(data.trim());
            if (obj.numOfQuestions > 0 && obj.daysToWait == 0) {
                loadPageByAjax("study?createNewTest=1" + "&isExam=" + isExam);
                // checkHash();
            } else {
                // Wenn es der Leitner-Modus war, dann checken, ob wenigstens im Modus learnmode_notyetplayed
                // etwas zu lernen ist
                if (obj.kindoftest == "leitner") {
                    $.get("lernen-info?learnmodeInfo=1&kindOfTest=notyetplayed", function (data) {
//                            try {
                        obj = JSON.parse(data.trim());
                        if (obj.numOfQuestions > 0) {
                            loadPageByAjax("study?createNewTest=1" + "&isExam=" + isExam);
                            // checkHash();
                        } else {
                            flashModal(i18n.gettext('No cards to be studied in your study schedule.'));
                        }
//                            } catch (e) {
//                                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                                return;
//                            }
                    });
                } else {
                    flashModal(i18n.gettext('No cards to be studied.'));
                }
            }
//            } catch (e) {
//                sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                return;
//            }
        });
    }
} // end fn startLearning()

function knownInAudition(known) {
    var reinit = doReinit();

    debug("known: " + known);
    $('.question-container').fadeTo(300, 0.75);

    $("#knew-it-btn").attr("disabled", "disabled");
    $("#half-known-btn").attr("disabled", "disabled");
    $("#didnt-knew-it-btn").attr("disabled", "disabled");

    var cardId = $('#cardId').val();
    $('#previousCardId').val(cardId);
    var cardBoxId = $('#learningBoxId').val();

    loadNextQuestion(cardId, cardBoxId, known, reinit)

}

function auditionSpeakQuestion() {
    debug("Executing auditionSpeakQuestion - so the multiple choice answers should be spoken as well.");
    if ($('#112').length > 0 && $('#112').is(':checked') && $(".card-tts-button.js-question").length > 0) {
        if ($(".choice-container").length > 0) {
            //TODO Wait until speaking the question is finished! ...
            speak('questionPureText', 'questionLanguage', true, 'allMchoiceAnswersPureText', 'answerLanguage');
        } else {
            speak('questionPureText', 'questionLanguage', true);
        }
    }
}

function auditionSpeakAnswer() {
    if ($('#113').length > 0 && $('#113').is(':checked') && $(".card-tts-button.js-answer").length > 0) {
        speak('answerPureText', 'answerLanguage', true);
    }
}

function loadNextQuestion(cardId, cardBoxId, known, reinit) {
    debug("Loading next card ...");

    if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
    }

    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "lernen",
        data: "passed=" + known + "&cardId=" + cardId + "&module=" + "card-learning" + "&cardBoxId=" + cardBoxId + "&nonce=" + nonce + "&reinit=" + reinit,
        success: function (data) {
            $('.question-container').html(data);
            incrementProgressBar();

            auditionSpeakQuestion();

            $(".question-container").removeClass("deactivated");
            $('.question-container').fadeTo(300, 1.0);
            $(document).scrollTop(0);
            if ($(".my-answer").length > 0) {
                autosize($('textarea'));
                if (getCookie('focusMyAnswerText') != 'false') {
                    $(".my-answer").focus();
                }
            }
            if ($(".question-text").length > 0 && $(".question-text").hasClass('disableCopying')) {
                enableDocumentSelection(false);
            } else {
                enableDocumentSelection(true);
            }
            
            $('.card img').click(function () {
                var loc = $(this).attr("src");
                debug("Opening location in new tab or window: " + loc);
                window.open(loc, '_blank');
            });
            
            $('.correct-answer img').click(function () {
                var loc = $(this).attr("src");
                debug("Opening location in new tab or window: " + loc);
                window.open(loc, '_blank');
            });
        },
        error: function (xhr, status, text) {
            if (xhr != null) {
                if (status == 'timeout' || status == 'error') {
                    this.tryCount++;
                    if (this.tryCount <= this.retryLimit) {
                        $.ajax(this);
                    } else {
                        $('.question-container').fadeTo(300, 1);
                        $("#knew-it-btn").removeAttr("disabled");
                        $("#half-known-btn").removeAttr("disabled");
                        $("#didnt-knew-it-btn").removeAttr("disabled");
                        shortInfo(i18n.gettext('No answer from server received - please try again'), 3000);
                    }
                    return;
                }
            }
        }
    });
}

function doReinit() {
    var reinit = false;
    if ($(".numOfCurrentCard").text() == $(".cardstodo").text() || $(".js-percentage-value").text() == '100') {
        reinit = true;
    }
    return reinit;
}

function skipCard(reinit) {

    if ($("#exam").val() == 1 && $("#negativeScores").val() == 0) {
        $(".js-next-question-keep-result-btn").attr('id', 0);
        nextQuestionKeepResult();
        return;
    }

    if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
    }
    
    //TODO Is there already a card AFTER the current one in the exam table? If yes, use gotoCard to go there.
    
    if (typeof previousCard == 'undefined') {
        var reinit = doReinit();
    }

    $('.question-container').fadeTo(300, 0.75);

    $("#knew-it-btn").attr("disabled", "disabled");
    $("#half-known-btn").attr("disabled", "disabled");
    $("#didnt-knew-it-btn").attr("disabled", "disabled");

    var cardId = $('#cardId').val();
    $('#previousCardId').val(cardId);
    var cardBoxId = $('#learningBoxId').val();

    var nonce = getNonce();

    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "lernen",
        data: "skipCard=" + 1 + "&cardId=" + cardId + "&module=" + "card-learning" + "&cardBoxId=" + cardBoxId + "&nonce=" + nonce + "&reinit=" + reinit,
        success: function (data) {
            $('.question-container').html(data);
            incrementProgressBar();
            $(".question-container").removeClass("deactivated");
            $('.question-container').fadeTo(300, 1.0);
            $(document).scrollTop(0);
            if ($(".my-answer").length > 0) {
                autosize($('textarea'));
                if (getCookie('focusMyAnswerText') != 'false') {
                    $(".my-answer").focus();
                }
            }
            if ($(".question-text").length > 0 && $(".question-text").hasClass('disableCopying')) {
                enableDocumentSelection(false);
            } else {
                enableDocumentSelection(true);
            }
//            if (typeof ga == 'function') {
//                debug("Google Analytics ist aktiv.");
//                $(document).ajaxSend(function (event, xhr, settings) {
//                    ga('send', 'pageview', 'lernen');
//                });
//            }
        },
        error: function (xhr, status, text) {
            if (xhr != null) {
                if (status == 'timeout' || status == 'error') {
                    this.tryCount++;
                    if (this.tryCount <= this.retryLimit) {
                        $.ajax(this);
                    } else {
                        $('.question-container').fadeTo(300, 1);
                        $("#knew-it-btn").removeAttr("disabled");
                        $("#half-known-btn").removeAttr("disabled");
                        $("#didnt-knew-it-btn").removeAttr("disabled");
                        shortInfo(i18n.gettext('No answer from server received - please try again'), 3000);
                    }
                    return;
                }
            }
        }
    });
}

function nextQuestionChangeRight() {
    $(".js-next-question-changeright-btn").attr("disabled", "disabled");
    $(".js-next-question-partially-btn").attr("disabled", "disabled");
    $(".js-next-question-keep-result-btn").attr("disabled", "disabled");
    var cardId = $('#cardId').val();
    $('#previousCardId').val(cardId);
    var cardBoxId = $('#learningBoxId').val();
    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "lernen",
        data: "passed=" + 1 + "&cardId=" + cardId + "&die=" + "true" + "&cardBoxId=" + cardBoxId + "&nonce=" + nonce,
        success: function (data) {
            if (data.trim() != '') {
                shortInfo(data);
            }
            nextQuestion();
        },
        error: function (xhr, status, text) {
            if (xhr != null) {
                if (status == 'timeout' || status == 'error') {
                    this.tryCount++;
                    if (this.tryCount <= this.retryLimit) {
                        $.ajax(this);
                    } else {
                        $('.question-container').fadeTo(300, 1);
                        $(".js-next-question-changeright-btn").removeAttr("disabled");
                        $(".js-next-question-partially-btn").removeAttr("disabled");
                        $(".js-next-question-keep-result-btn").removeAttr("disabled");
                        shortInfo(i18n.gettext('No answer from server received - please try again'), 3000);
                    }
                    return;
                }
            }
        }
    });
}

function nextQuestionChangePartially() {
    $(".js-next-question-changeright-btn").attr("disabled", "disabled");
    $(".js-next-question-partially-btn").attr("disabled", "disabled");
    $(".js-next-question-keep-result-btn").attr("disabled", "disabled");
    var cardId = $('#cardId').val();
    $('#previousCardId').val(cardId);
    var cardBoxId = $('#learningBoxId').val();
    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "lernen",
        data: "passed=" + 2 + "&cardId=" + cardId + "&die=" + "true" + "&cardBoxId=" + cardBoxId + "&nonce=" + nonce,
        success: function (data) {
            if (data.trim() != '') {
                shortInfo(data);
            }
            nextQuestion();
        },
        error: function (xhr, status, text) {
            if (xhr != null) {
                if (status == 'timeout' || status == 'error') {
                    this.tryCount++;
                    if (this.tryCount <= this.retryLimit) {
                        $.ajax(this);
                    } else {
                        $('.question-container').fadeTo(300, 1);
                        $(".js-next-question-changeright-btn").removeAttr("disabled");
                        $(".js-next-question-partially-btn").removeAttr("disabled");
                        $(".js-next-question-keep-result-btn").removeAttr("disabled");
                        shortInfo(i18n.gettext('No answer from server received - please try again'), 3000);
                    }
                    return;
                }
            }
        }
    });
}

function nextQuestionKeepResult() {
    $(".js-next-question-changeright-btn").attr("disabled", "disabled");
    $(".js-next-question-partially-btn").attr("disabled", "disabled");
    $(".js-next-question-keep-result-btn").attr("disabled", "disabled");
    var cardId = $('#cardId').val();
    $('#previousCardId').val(cardId);
    var cardBoxId = $('#learningBoxId').val();
    var passed = $(".js-next-question-keep-result-btn").attr('id');
    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "lernen",
        data: "passed=" + passed + "&cardId=" + cardId + "&die=" + "true" + "&cardBoxId=" + cardBoxId + "&nonce=" + nonce,
        success: function (data) {
            if (data.trim() != '') {
                shortInfo(data);
            }
            nextQuestion();
        },
        error: function (xhr, status, text) {
            if (xhr != null) {
                if (status == 'timeout' || status == 'error') {
                    this.tryCount++;
                    if (this.tryCount <= this.retryLimit) {
                        $.ajax(this);
                    } else {
                        $('.question-container').fadeTo(300, 1);
                        $(".js-next-question-changeright-btn").removeAttr("disabled");
                        $(".js-next-question-partially-btn").removeAttr("disabled");
                        $(".js-next-question-keep-result-btn").removeAttr("disabled");
                        shortInfo(i18n.gettext('No answer from server received - please try again'), 3000);
                    }
                    return;
                }
            }
        }
    });

}

function nextQuestion(previousCard, forward) {

    if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
    }

    if (typeof previousCard == 'undefined') {
        previousCard = null;
    }
    
    if (typeof forward == 'undefined') {
        forward = false;
    }
    
    $(".js-next-question-btn").attr("disabled", "disabled");
    $(".js-next-question-btn").addClass("hidden");
    $(".js-next-question-changeright-btn").addClass("hidden");
    $(".js-next-question-partially-btn").addClass("hidden");
    $(".js-next-question-keep-result-btn").addClass("hidden");
    var cardBoxId;
    if ($('#learningBoxId').length > 0) {
        cardBoxId = $('#learningBoxId').val();
    } else {
        cardBoxId = 0;
    }
    $('.question-container').fadeTo(300, 0.75);
    debug("Setting previous card id to: " + $('#cardId').val());
    $('#previousCardId').val($('#cardId').val());
    $.ajax({
        type: "POST",
        url: "lernen",
        data: "module=" + "card-learning" + "&cardBoxId=" + cardBoxId + "&gotoCard=" + previousCard,
        success: function (data) {
            $(".skip-card-btn").removeClass("hidden");
            $('.question-container').html(data);
            if (previousCard == null || forward) {
                incrementProgressBar();
            } else {
                decreaseProgressBar();
            }
            $(".question-container").removeClass("deactivated");
            $('.question-container').fadeTo(300, 1.0);

            auditionSpeakQuestion();

            $(document).scrollTop(0);
            if ($(".my-answer").length > 0) {
                autosize($('textarea'));
                if (getCookie('focusMyAnswerText') != 'false'
                        || ($("#learningCompareAutomatically").length > 0 && $("#learningCompareAutomatically").val() == 1)) {
                    $(".my-answer").focus();
                }
            }
            if ($(".question-text").length > 0 && $(".question-text").hasClass('disableCopying')) {
                enableDocumentSelection(false);
            } else {
                enableDocumentSelection(true);
            }
//            if (typeof ga == 'function') {
//                debug("Google Analytics ist aktiv.");
//                $(document).ajaxSend(function (event, xhr, settings) {
//                    ga('send', 'pageview', 'lernen');
//                });
//            }
        },
        error: function (xhr, status, text) {
            if (xhr != null) {
                if (status == 'timeout' || status == 'error') {
                    this.tryCount++;
                    if (this.tryCount <= this.retryLimit) {
                        $.ajax(this);
                    } else {
                        $('.question-container').fadeTo(300, 1);
                        $(".js-next-question-btn").removeAttr("disabled");
                        $(".js-next-question-btn").removeClass("hidden");
                        $(".js-next-question-changeright-btn").removeClass("hidden");
                        $(".js-next-question-partially-btn").removeClass("hidden");
                        $(".js-next-question-keep-result-btn").removeClass("hidden");
                        shortInfo(i18n.gettext('No answer from server received - please try again'), 3000);
                    }
                    return;
                }
            }
        }
    });
}

function incrementProgressBar() {
    //debug("inkrementiere!");
    var cardsLeft = $('.cards-left').html();
    var numOfCurrentCard = $('.numOfCurrentCard').html();
    var cardsTodo = $('.cardstodo').html();
    numOfCurrentCard++;
    cardsLeft--;
    cardsLeft = Math.max(0, cardsLeft);
    debug(numOfCurrentCard + " von " + cardsTodo);
    var percentage = Math.round(((numOfCurrentCard - 1) / cardsTodo) * 100);
    debug(percentage + " prozent");

    if ($("#exam").val() != 1) {
        $(".goto-card-btn").show();
    }

    if (numOfCurrentCard <= cardsTodo) {
        $(".numOfCurrentCard").html(numOfCurrentCard);
    }
    $(".cards-left").html(cardsLeft);
    $(".js-percentage-value").html(percentage);
    $(".js-progress-bar").data("progress", percentage);
    $(".js-progress-bar").css("width", percentage + "%");

    if (numOfCurrentCard % 5 == 0) {
        loadAds();
    }
}

function decreaseProgressBar() {
    var cardsLeft = $('.cards-left').html();
    var numOfCurrentCard = $('.numOfCurrentCard').html();
    var cardsTodo = $('.cardstodo').html();
    numOfCurrentCard--;
    cardsLeft++;
    cardsLeft = Math.max(0, cardsLeft);
    debug(numOfCurrentCard + " von " + cardsTodo);
    var percentage = Math.round(((numOfCurrentCard - 1) / cardsTodo) * 100);
    debug(percentage + " prozent");

    // $(".goto-card-btn").hide();

    if (numOfCurrentCard <= cardsTodo) {
        $(".numOfCurrentCard").html(numOfCurrentCard);
    }
    $(".cards-left").html(cardsLeft);
    $(".js-percentage-value").html(percentage);
    $(".js-progress-bar").data("progress", percentage);
    $(".js-progress-bar").css("width", percentage + "%");
}

function showAnswer() {

    // debug("show answer");

    if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
    }

    if ($("html").hasClass("smaller-than-tablet")) {
        $(".card").hide();
    }

    if ($("#exam").val() != 1) {
        $(".correct-answer").show();
        $("#show-answer-btn").hide();
        $(".js-show-question-btn").removeClass("hidden");
        auditionSpeakAnswer();
    }

    var choosedCorrectly = false;

    if ($("#learningCompareAutomatically").val() == 1 || $("html").data("theme").startsWith("beck")) {
        if (!$("html").hasClass("smaller-than-tablet") || $("html").data("theme").startsWith("beck")) { //TODO Do we really need this? Automatic comparison should also be available in small-screen-mode.
            // if not smaller than tablet:
            // automatisch vergleichen und bewertung absenden
            var myAnswerText = encodeURI($("#myAnswerText").val().trim());
            var defaultAnswerText = encodeURI($("#defaultAnswer").text().trim());

            myAnswerText = myAnswerText.replaceAll('%C2%A0', '%20');
            defaultAnswerText = defaultAnswerText.replaceAll('%C2%A0', '%20');

            debug("######## My answer: " + myAnswerText);
            debug("### Default answer: " + defaultAnswerText);

            //TODO Is it enough that one of the answers is correct? Yes, if theme is BECK.
            if ($("html").data("theme").startsWith("beck")) {

                // If myAnswerText is empty, then only show correct answer and "next"-button.
                myAnswerText = myAnswerText.replaceAll('%20', ' ');
                debug("Automatic comparison: One of the lines is sufficient.");
                defaultAnswerText = $("#defaultAnswerForComparison").text();
                debug("### Default answer for exam: " + defaultAnswerText);
                var possibleAnswers = defaultAnswerText.trim().split("|");
                possibleAnswers.forEach(function (currentAnswer, index, arr) {
                    debug("### Comparing " + myAnswerText.toLowerCase().trim() + " to " + currentAnswer.toLowerCase().trim());
                    if ((myAnswerText.toLowerCase().trim() != "") && (myAnswerText.toLowerCase().trim() == currentAnswer.toLowerCase().trim())) {
                        choosedCorrectly = true;

                    }
                });
                $(".js-next-question-keep-result-btn").attr("id", choosedCorrectly ? 1 : 0);

                if ($("#exam").val() != 1) {
                    if (choosedCorrectly) {
                        $(".card .answer").addClass("correct");
                        // shortInfo(i18n.gettext("Correct."), 1000);
                    } else {
                        $(".card .answer").addClass("wrong");
                        // shortInfo(i18n.gettext("Wrong."), 1000);
                    }
                }

                if ($("#exam").val() == 1) {

                    nextQuestionKeepResult();

                } else {

                    if (myAnswerText.trim() != "") {
                        $(".skip-card-btn").hide();
                        $(".js-next-question-keep-result-btn").removeAttr("disabled");
                        $(".js-next-question-keep-result-btn").removeClass("hidden").focus();
                        if (choosedCorrectly) {
                            $(".js-next-question-keep-result-btn").html('<span><i class="fa fa-arrow-right"></i> ' + i18n.gettext("Correct! Next question") + '</span>');
                            $('.js-next-question-keep-result-btn').css("cssText", "background: #08b474 !important")
                        } else {
                            $('.js-next-question-keep-result-btn').css("cssText", "background: #E95757 !important")
                        }

                    } else {
                        $('.skip-card-btn').removeClass('grey-light');
                        $('.skip-card-btn').addClass('primary-color-light');
                        $('.skip-card-btn').html('<span><i class="fa fa-arrow-right"></i> ' + i18n.gettext("Go") + '</span>');
                    }
                }

            } else {
                if (myAnswerText.toLowerCase().trim() === defaultAnswerText.toLowerCase().trim()) {
                    choosedCorrectly = true;
                    $(".js-next-question-keep-result-btn").attr("id", choosedCorrectly ? 1 : 0);
                    $(".card .answer").addClass("correct");
                    // Hier direkt zur n&auml;chsten Frage h&uuml;pfen und Erfolgsmeldung aufflashen
                    shortInfo(i18n.gettext("Correct."), 1000);
                    nextQuestionKeepResult();
                } else {
                    choosedCorrectly = false;
                    $(".js-next-question-keep-result-btn").attr("id", choosedCorrectly ? 1 : 0);
                    $(".card .answer").addClass("wrong");
                    $(".js-next-question-keep-result-btn").removeClass("hidden").focus();
                    $('.js-next-question-keep-result-btn').css("background-color", "#E95757");
                    $('.js-next-question-keep-result-btn:hover').css("background-color", "#E95757");
                    $('.js-next-question-keep-result-btn:focus').css("background-color", "#E95757");
                }

                $(".js-next-question-keep-result-btn").removeAttr("disabled");
                if (!choosedCorrectly) {
                    $(".js-next-question-changeright-btn").removeAttr("disabled");
                    $(".js-next-question-changeright-btn").removeClass("hidden");
                    $(".js-next-question-partially-btn").removeAttr("disabled");
                    $(".js-next-question-partially-btn").removeClass("hidden");
                }

            }


        } // end if not smaller-than-tablet

    } else {
        $(".knew-it-btn").removeAttr("disabled");
        $(".knew-it-btn").addClass("flex");
        $(".half-known-btn").removeAttr("disabled");
        $(".half-known-btn").addClass("flex");
        $(".didnt-knew-it-btn").removeAttr("disabled");
        $(".didnt-knew-it-btn").addClass("flex");

        // executeTour("study");

        // If user-attribute "hidePartiallyButton" is not yet configured, display partially-known-modal
        if (typeof $("html").data('hidepartiallybutton') !== 'undefined') {
            if ($("html").data("hidepartiallybutton") === "") {
                $("#partially-known-modal").modal();
            }
        }

    }

}



function showQuestion() {
    $(".card").show();
    $(".correct-answer").hide();
    $("#show-answer-btn").show();
    $(".js-show-question-btn").addClass("hidden");
}



function checkAnswer(showResult) {

//    if (typeof learningCountdownInterval !== 'undefined') {
//        clearInterval(learningCountdownInterval);
//    }

    if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
    }

    if (typeof showResult === 'undefined') {
        showResult = true;
    }

    if (!showResult) {
        debug("Don't show result.");
    } else {
        debug("Show result.");
    }

    $(".answer").removeClass("active");

    $(".js-check-answer-btn").attr("disabled", "disabled");

    $(".js-check-answer-btn").addClass("hidden");
    $(".skip-card-btn").addClass("hidden");
    $(".goto-card-btn").addClass("hidden");

    var answerText = $.trim($(".answer-text").html());
    if (answerText !== '<div>&nbsp;</div>' && showResult) {
        $(".correct-answer").show();
    }

    var cardId = $('#cardId').val();
    var cardBoxId = $('#learningBoxId').val();

    auditionSpeakAnswer();

//    $.getJSON("mchoice_evaluator?id=" + $('#cardId').val(), function (data) {

    var choosedCorrectly = true;
//        $.each(data, function (key, val) {

    $(".question-container .card .answer .mc-option-list .choice-container").each(function (i, obj) {
        debug("Checking answer " + i + ": " + $(obj).hasClass('js-shouldbechecked'));

        if (showResult) {
            if ($(obj).hasClass('js-shouldbechecked')) {
                $(obj).addClass("correct");
            } else {
                $(obj).addClass("wrong");
            }
        }

        if ($(obj).hasClass('js-shouldbechecked') && !($(obj).find('input').is(":checked"))) {
            choosedCorrectly = false;
            debug("Chose NOT correctly because of answer: " + i);
//                $(".learn-card .choice-container.choice-container-" + key).addClass("wrong");
        }
        if (!($(obj).hasClass('js-shouldbechecked')) && $(obj).find('input').is(":checked")) {
            choosedCorrectly = false;
            debug("Chose NOT correctly because of answer: " + i);
//                $(".learn-card .choice-container.choice-container-" + key).addClass("wrong");
        }

    });

    var passed = choosedCorrectly ? 1 : 0;
    var nonce = getNonce();
    $.ajax({
        // retry-sensitive
        type: "POST",
        url: "lernen",
        data: "passed=" + passed + "&cardId=" + cardId + "&die=" + "true" + "&cardBoxId=" + cardBoxId + "&nonce=" + nonce,
    }).done(function (data) {
        if (data.trim() != '') {
            if (showResult) {
                shortInfo(data);
            }
        }
    }).fail(function (xhr, status) {
        //TODO Save statistics locally to maybe submit later for completeness of final statistics.

    }).complete(function () {
        if (!showResult) {
            nextQuestion();
        } else {
            if (!choosedCorrectly && $(".learn-card").data("card-id") == cardId) {
                $(".js-next-question-btn").addClass("red");
            }
            $(".js-next-question-btn").removeClass("hidden").focus();
        }
    });



//    });

    /*
     // TEMPORARY BLANKS QUESTION STUFF
     var blanksAnswers = [
     'Deutschland',
     'Brasilien',
     'Mario Götze',
     'vier',
     'Russland'
     ];
     
     if ($(".question-container").hasClass("blanks-input")) {
     
     blanks.each(function (i) {
     // debug('each');
     if ($(this).text() == blanksAnswers[i]) {
     $(this).addClass("correct");
     } else {
     $(this).addClass("wrong");
     $(this).after('<span class="blank correction">' + blanksAnswers[i] + '</span>');
     }
     });
     
     }
     
     if ($(".question-container").hasClass("blanks-select")) {
     
     blanks.each(function (i) {
     // debug('each');
     if ($(this).find("select").val() == blanksAnswers[i]) {
     $(this).addClass("correct");
     } else {
     $(this).addClass("wrong");
     $(this).after('<span class="blank correction">' + blanksAnswers[i] + '</span>');
     }
     });
     }
     */


} // end fn check answer



function stopLearning(event) {
    if (event != null) {
        event.preventDefault();
    }
    loadPageByAjax("study-statistics");
    // window.location.href = "study-statistics";
}

function keyPressActionLearning(e) {
    // debug("charcode: " + e.charCode + ", which: " + e.which);
    if (!$("#myAnswerText").is(":focus") && !($(".edit-mode").length == 1)) {
        // multiple choice oder normale Karte?

        //TODO Other codes for other languages!!

        if ($("html").attr("lang") == "de_DE") {

            if ($(".answer").hasClass("mc")) {
                if (e.which == 65) { // A
                    checkAnswer();
                }
                if (e.which == 75) { // K
                    nextQuestion();
                }
                if (e.which == 66) { // B
                    stopLearning();
                }
            } else {
                if (e.which == 71) { // G
                    knownInAudition(1);
                }
                if (e.which == 78) { // N
                    knownInAudition(0);
                }
                if (e.which == 84) { // T
                    if ($(".next-question-partially-btn").is(":visible")) {
                        nextQuestionChangePartially();
                    } else {
                        knownInAudition(2);
                    }
                }
                if (e.which == 65) { // A
                    showAnswer();
                }
                if (e.which == 75) { // K
                    if ($("#learningCompareAutomatically").val() == 1) {
                        nextQuestionKeepResult();
                    } else {
                        nextQuestion();
                    }
                }
                if (e.which == 82) { // R
                    nextQuestionChangeRight();
                }
                if (e.which == 66) { // B
                    stopLearning();
                }
            }

        } else if ($("html").attr("lang") == "en_GB") {

            if ($(".answer").hasClass("mc")) {
                if (e.which == 67) { // C
                    checkAnswer();
                }
                if (e.which == 70) { // F
                    nextQuestion();
                }
                if (e.which == 69) { // B
                    stopLearning();
                }
            } else {
                if (e.which == 75) { // K
                    knownInAudition(1);
                }
                if (e.which == 78) { // N
                    knownInAudition(0);
                }
                if (e.which == 80) { // P
                    if ($(".next-question-partially-btn").is(":visible")) {
                        nextQuestionChangePartially();
                    } else {
                        knownInAudition(2);
                    }
                }
                if (e.which == 65) { // A
                    showAnswer();
                }
                if (e.which == 67) { // C
                    if ($("#learningCompareAutomatically").val() == 1) {
                        nextQuestionKeepResult();
                    } else {
                        nextQuestion();
                    }
                }
                if (e.which == 82) { // R
                    nextQuestionChangeRight();
                }
                if (e.which == 69) { // E
                    stopLearning();
                }
            }


        }

    } else if ($("#myAnswerText").is(":focus") && (e.which == 13) && ($("#learningCompareAutomatically").val() == 1)) {
        showAnswer();
    }
    e.preventDefault();
    e.stopImmediatePropagation();
}

$(document).ready(function () {

    if ($(".my-answer").length > 0 && (getCookie('focusMyAnswerText') != 'false'
            || ($("#learningCompareAutomatically").length > 0 && $("#learningCompareAutomatically").val() == 1))) {
        $(".my-answer").focus();
    }


    // TEMPORARY BLANKS QUESTION STUFF
    var blanks = $(".question-text").find(".blank");

    // disable enter key in blank
    blanks.keydown(function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
        }
    });

    blanks.eq(0).focus();



    // normal question: put focus in answer field right away so user can start writing immediately
    if (getCookie('focusMyAnswerText') != 'false') {
        $(".my-answer").focus();
    }

}); // end document ready


// trigger shortcuts, on learning page, only!
$(document).on('keyup.learn-shortcuts', '.page-lernen', function (e) {
    return keyPressActionLearning(e);
});

// trigger shortcuts, on learning page, only!
$(document).on('keydown', function (e) {
    return keyPressAction(e);
});

function myAnswerTextBlur() {
    // if this is not a click on show-answer-btn, knew-it-btn, didnt-knew-it-btn, next-question-btn, stop-learning-btn
    setTimeout(function () {
        var idOfClickedElement = $(':focus').attr('id'); // This is the element that has focus
        buttonsArray = ["show-answer-btn", "knew-it-btn", "half-known-btn", "didnt-knew-it-btn", "next-question-btn", "stop-learning-btn"];
        if ($.inArray(idOfClickedElement, buttonsArray) == -1) {
            setCookie("focusMyAnswerText", false, 9999);
        }
    });
}

function myAnswerTextFocus() {
    setCookie("focusMyAnswerText", true, 9999);
}

function cardTopRowSettingsBtnClick(el) {
    $(el).toggleClass("active");

    if ($(".card-top-row .settings-container").is(":visible")) {
        $(".card-top-row .settings-container").hide();
    } else {
        $(el).siblings(".settings-container").show();
        // $("#settings-container-" + boxId).show();

        // add function to close container on click outside
        // setTimeout needed or close function receives button click
        setTimeout(function () {
            $(document).on('click.close-settings', function () {
                $(".card-top-row .settings-container").hide();
                $(".card-top-row .settings-btn").removeClass("active");
                $(document).off('click.close-settings');
            });
        }, 10);

    } // end if settings-container exists

    // have the button NOT focussed (and colored) when clicking it again to close the dropdown
    $(el).blur();

} // end .card-top-row .settings-btn click



function createCardForStatsList(cardId, question) {

    var cardString = '<div class="card one-line w100 clearfix">'
            + '<a href="' + "card-" + cardId + '" class="bg-link-a w100 h100" onclick="return false;" id="card-' + cardId + '"></a>'
            + '<div class="question w100" id="question-' + cardId + '">'
            + '<div class="bin bin-">'
            + '</div>'
            + '<p class="question-text w100 ellipsis">' + question + '</p>'
            + '</div>'
            + '</div>';

    return cardString;
}

/* LEARNSTATS CORRECT & WRONG CARDS BUTTONS */

function loadMoreCorrectCards() {
    var numVisible = $(".js-correct-cards-container .card").length;
    var numKnown = parseInt($(".cards-correct-quantity").text());
    var offset = numVisible;
    var number = Math.min((numKnown - numVisible), 100);
    if (number > 0) {
        showAjaxLoader(".js-correct-cards-container");
        $.ajax({
            type: "GET",
            url: "study-statistics",
            data: "getKnownCards=1?offset=" + offset + "&number=" + number,
            context: this,
            success: function (data) {
//                try {
                hideAjaxLoader();
                var cards = JSON.parse(data.trim());
                $.each(cards, function (index, card) {
                    $(".js-correct-cards-container").append(createCardForStatsList(card.questionKey, card.questionText));
                });
                numVisible = $(".js-correct-cards-container .card").length;
                if (numVisible < numKnown) {
                    $(".js-load-more-correct-cards-btn").show();
                } else {
                    $(".js-load-more-correct-cards-btn").hide();
                }
//                } catch (e) {
//                    sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                    return;
//                }
            }
        });
    }
}

function loadMorePartiallyCorrectCards() {
    var numVisible = $(".js-partially-correct-cards-container .card").length;
    var numNotKnown = parseInt($(".cards-partially-correct-quantity").text());
    var offset = numVisible;
    var number = Math.min((numNotKnown - numVisible), 100);
    if (number > 0) {
        showAjaxLoader(".js-partially-correct-cards-container");
        $.ajax({
            type: "GET",
            url: "study-statistics",
            data: "getPartiallyKnownCards=1?offset=" + offset + "&number=" + number,
            context: this,
            success: function (data) {
                hideAjaxLoader();
                var cards = JSON.parse(data.trim());
                $.each(cards, function (index, card) {
                    $(".js-partially-correct-cards-container").append(createCardForStatsList(card.questionKey, card.questionText));
                });
                numVisible = $(".js-partially-correct-cards-container .card").length;
                if (numVisible < numNotKnown) {
                    $(".js-load-more-partially-correct-cards-btn").show();
                } else {
                    $(".js-load-more-partially-correct-cards-btn").hide();
                }
            }
        });
    }
}

function loadMoreWrongCards() {
    var numVisible = $(".js-wrong-cards-container .card").length;
    var numNotKnown = parseInt($(".cards-wrong-quantity").text());
    var offset = numVisible;
    var number = Math.min((numNotKnown - numVisible), 100);
    if (number > 0) {
        showAjaxLoader(".js-wrong-cards-container");
        $.ajax({
            type: "GET",
            url: "study-statistics",
            data: "getNotKnownCards=1?offset=" + offset + "&number=" + number,
            context: this,
            success: function (data) {
//                try {
                hideAjaxLoader();
                var cards = JSON.parse(data.trim());
                $.each(cards, function (index, card) {
                    $(".js-wrong-cards-container").append(createCardForStatsList(card.questionKey, card.questionText));
                });
                numVisible = $(".js-wrong-cards-container .card").length;
                if (numVisible < numNotKnown) {
                    $(".js-load-more-wrong-cards-btn").show();
                } else {
                    $(".js-load-more-wrong-cards-btn").hide();
                }
//                } catch (e) {
//                    sendError(e.message + " | " + this.url + "?" + this.data + " | " + data);
//                    return;
//                }
            }
        });
    }
}

function toggleCorrectCards() {
    var container = $(".js-correct-cards-container");
    if (container.is(":visible")) {
        container.slideUp();
        $(".js-correct-cards-btn").find(".fa").removeClass("fa-caret-up").addClass("fa-caret-down");
    } else {
        container.slideDown();
        $(".js-correct-cards-btn").find(".fa").removeClass("fa-caret-down").addClass("fa-caret-up");
        loadMoreCorrectCards();
    }
}

function togglePartiallyCorrectCards() {
    var container = $(".js-partially-correct-cards-container");
    if (container.is(":visible")) {
        container.slideUp();
        $(".js-partially-correct-cards-btn").find(".fa").removeClass("fa-caret-up").addClass("fa-caret-down");
    } else {
        container.slideDown();
        $(".js-partially-correct-cards-btn").find(".fa").removeClass("fa-caret-down").addClass("fa-caret-up");
        loadMorePartiallyCorrectCards();
    }
}

function toggleWrongCards() {
    var container = $(".js-wrong-cards-container");
    if (container.is(":visible")) {
        container.slideUp();
        $(".js-wrong-cards-btn").find(".fa").removeClass("fa-caret-up").addClass("fa-caret-down");
    } else {
        container.slideDown();
        $(".js-wrong-cards-btn").find(".fa").removeClass("fa-caret-down").addClass("fa-caret-up");
        loadMoreWrongCards();
    }
}

// DEMO VARS

/*
 //  normal
 var lp = 0;
 var level = 1;
 var lpPerDay = 10;
 var daysLearned = 0;
 */

/*
 // learnstreak
 var lp = 150;
 var level = 1;
 var lpPerDay = 11;
 var daysLearned = 11;
 */

/*
 // levelup
 var lp = 110;
 var level = 1;
 var lpPerDay = 10;
 var daysLearned = 0;
 */

// learnstreak + levelup
var lp = 49990;
var level = 49;
var lpPerDay = 14;
var daysLearned = 29;



var daysLearnedTiers = [6, 12, 18, 24, 30];

var earnedLpString = '<p class="earned-lp-text">\n\
' + sprintf(i18n.gettext('You earned %s study points.'), '<span class="bold">' + lpPerDay + '</span>') + '\n\
' + sprintf(i18n.gettext('Now you have %s study points.'), '<span class="bold">' + (lp + lpPerDay) + '</span>') + '\n\
</p>';

var daysLearnedString = '<p class="days-learned-text">\n\
' + sprintf(i18n.gettext('You studied %s days in a row!'), '<span class="days-learned-number">' + (daysLearned + 1) + '</span>') + '\n\
' + i18n.gettext('Great!') + '\n\
</p> \
' + sprintf(i18n.gettext('For every additional day you will get %s study points!'), '<span class="bold">' + (lpPerDay + 1) + '</span>') + '';

var newLevelString = '<p class="new-level-text">\n\
' + sprintf(i18n.gettext('You are now at level %s.'), '<span class="new-level-number">' + (level + 1) + '</span>') + '\n\
' + i18n.gettext('Congrats! Way to go!') + '\n\
</p>';



function checkLvlUp() {
    /*
     level points formula:
     
     Math.round(6x² + 114.16x)
     
     LVL 1 = 0 - 120 LP
     LVL 50 =  20000+ LP
     
     */
    var newLp = lp + lpPerDay;
    var newLevel = level + 1;
    return (newLp >= Math.round(6 * newLevel * newLevel + 114.16 * newLevel)) ? true : false;
}



function getLp() {
    var learnStreak = ($.inArray(daysLearned + 1, daysLearnedTiers) == -1) ? false : true;
    var levelUp = checkLvlUp();

    if (learnStreak && !levelUp) {

        var titleString = '<p class="modal-hl">'
                + '<i class="fa fa-thumbs-up"></i>'
                + '<span>' + i18n.gettext('Working hard!') + '</span>'
                + '</p>';

        var contentString = earnedLpString + daysLearnedString;

        $("#general-purpose-modal-content").html(titleString + contentString);
        $("#general-purpose-modal").modal();

    } else if (!learnStreak && levelUp) {

        var titleString = '<p class="modal-hl">'
                + '<i class="fa fa-arrow-circle-up"></i>'
                + '<span>' + i18n.gettext('Level up!') + '</span>'
                + '</p>';

        var contentString = earnedLpString + newLevelString;

        $("#general-purpose-modal-content").html(titleString + contentString);
        $("#general-purpose-modal").modal();

    } else if (learnStreak && levelUp) {

        var titleString = '<p class="modal-hl">'
                + '<i class="fa fa-arrow-circle-up"></i>'
                + '<span>' + i18n.gettext('Level up!') + '</span>'
                + '</p>';

        var contentString = earnedLpString + newLevelString + daysLearnedString;

        $("#general-purpose-modal-content").html(titleString + contentString);
        $("#general-purpose-modal").modal();

    } else {
        shortInfo(earnedLpString, 6000);
    }

    // update lp now

} // end fn getLp

function joinLearning(el) {
    var boxId = $(el).data('id');
    var url = $('#pagehash').val();
    $('#center').fadeTo(300, 0.75, 'swing');
    $.ajax({
        type: "POST",
        url: "kartensatz-karten-" + boxId,
        data: "join=1",
        success: function (data) {
            if (data.length > 80) {
                generalPurposeModal(data);
            } else {
                flashModal(data, 3500);
            }
            loadPageToCenter(url);
        }, complete: function () {
            $('#center').fadeTo(100, 1, 'swing');
        }
    });
}

function dropCardbox(el) {
    if (confirm(i18n.gettext('Really remove the cardset from your list of cardsets? The cardset will not be deleted by this, but it will not be in your list any more.'))) {
        var boxId = $(el).data('id');
        var url = $('#pagehash').val();
        $('#center').fadeTo(300, 0.75, 'swing');
        $.ajax({
            type: "POST",
            url: "kartensatz-karten-" + boxId,
            data: "drop=1",
            success: function (data) {
                flashModal(data);
                loadPageToCenter(url);
            }, complete: function () {
                $('#center').fadeTo(100, 1, 'swing');
            }
        });
    }
}

function gotoPreviousCard() {
    //TODO Get question id from exam and go there, instead of the #previousCardId field.
    var cardId = $("#cardId").val();
    $.ajax({
        type: "POST",
        url: "lernen",
        data: "getPreviousCardOf=" + cardId,
        success: function (data) {
            var obj = JSON.parse(data.trim());
            var previousCardId = obj.previousCardId;
            if (previousCardId !== null && !isNaN(previousCardId)) {
                nextQuestion(previousCardId);
            } else {
                flashModal(i18n.gettext("No previous card yet."));
            }
        }
    });

//    var previousCardId = $('#previousCardId').val();
//    if (!isNaN(previousCardId)) {
//        nextQuestion(previousCardId);
//    } else {
//        flashModal(i18n.gettext("No previous card yet."));
//    }
}

function gotoNextCard() {
    var cardId = $("#cardId").val();
    $.ajax({
        type: "POST",
        url: "lernen",
        data: "getNextCardOf=" + cardId,
        success: function (data) {
            var obj = JSON.parse(data.trim());
            var nextCardId = obj.nextCardId;
            if (nextCardId !== null && !isNaN(nextCardId)) {
                debug("We go to card: " + nextCardId);
                nextQuestion(nextCardId, true);
            } else {
                debug("We have to skip the card.");
                skipCard(false);
                // flashModal(i18n.gettext("No next card yet."));
            }
        }
    });
}

function hidePartiallyButton(hide) {

    $("html").data("hidepartiallybutton", hide);
    if (hide == 1) {
        $(".half-known-btn").removeClass("flex");
        $(".audition-btn").css("max-width", "50%");
        $(".didnt-knew-it-btn").css("left", "50%");
    }
    $.post("settings", "option=106&value=" + hide, null);

}